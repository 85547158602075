.gmap {
    width: 100%;
    height: calc(100vh - #{map-get($nav-options, mobile-height)});

    * {
        border-top-width: 0 !important;
    }
}

.gmap-container {
    position: relative;
    display: flex;
    flex-direction: column-reverse;

    @include mq(md) {
        display: block;
    }

    .form--dealers {
        width: 100%;
        top: 0;
        left: 0;
        margin: 0;

        &.is-hidden {
            display: none;
        }

        @include mq(md) {
            position: absolute;
            padding: $root-vgrid*2 $grid-gutter-width/2 0;
            transition: opacity $transition-fast ease-in-out;
            width: auto;
            left: 50px;
            top: 22px;
        }

        button {
            height: 2.875em;
            cursor: pointer;
        }
    }

    .form--dealers .gr-form__field--inline {
        margin: 0;
    }

    &.is-opened .form--dealers {
        opacity: 0;
    }
}

.l-find-dealer .form--dealers {

    .field-checkbox {
        border: 1px solid color(greys, light);
        background-color: white;
    }

    .gr-form__field--inline {

        @include mq(md, 1) {
            input[type="text"] {
                margin-right: 0;
            }
        }

        input[type="text"] {
            border: 0;

            @include mq(sm, 1) {
                font-size: em(13px);
            }
        }

        button {
            background-color: white;
            margin-right: 1rem;
            .icon-search {
                position: relative;
                top: 2px;
            }
        }

        .parsley-errors-list {
            position: absolute;
            left: 0;
            bottom: -25px;
        }

    }

    .gr-form__field--inline:not(.field-checkbox) {
        label {
            color: currentColor;
            font-size: rem(12px);

            @include mq(md) {
                font-size: rem(16px);
            }
        }
    }
}

.gmap-container {

    .gr-form__field--inline {
        @include mq(md) {
            min-width: 460px;
        }
    }
    .gr-form__field--inline:not(.field-checkbox) {
        display: flex;
        background-color: $white;
        padding: .5rem;
        border: 1px solid color(greys, light);
        border-top: 0;
        min-width: auto;

        @include mq(md) {
            margin-left: 1rem;
            border-top: 1px solid color(greys, light);
        }
    }

    .form-fieldset {
        @include mq(md) {
            display: flex;

            .gr-form__field--inline {
                flex-grow: 0;
                max-width: none;
            }
        }
    }
}


.gmap-overlay {
    .gr-form__field--inline {
        max-width: none;
    }

    .gr-form__field--inline:not(.field-checkbox) {
        color: $white;
        justify-content: center;
        background-color: transparent;
        padding: 0;
        border: 0;
        margin: 0;
        margin-top: $root-vgrid;
    }
}


.gmap-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;
    background: rgba(0, 0, 0, .7);

    @include mq(md) {
        background: linear-gradient(180deg, #7f7f7f 0%, rgba(255, 255, 255, 0) 100%);
    }

    .h1 {
        color: white;
    }

    .form--dealers {
        text-align: center;
        position: relative;
        left: auto;
        top: auto;
        padding: $grid-gutter-width/2;

        @include mq(md) {
            padding: $root-vgrid*2;
            background: rgba(0, 0, 0, .7);
        }

        label {
            text-align: left;
            margin-right: 0;
            padding-right: 0;

        }
    }
}


.l-find-dealer .form--dealers__results {
    @include mq(md) {
        width: calc(100% - 46px);
        height: calc(100% - 200px);
        max-height: none;
        padding-bottom: 0;
        position: absolute;
        top: 0;
        margin-top: 190px;
    }
}

.gmap-results {
    width: 100%;
    height: 62px;
    overflow: hidden;
    background-color: white;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 50;
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;
    transform: translateY(calc(100% - 62px));

    &.is-hidden {
        display: none;
    }

    address {
        cursor: pointer;
        border-bottom: 1px solid color(greys, light);
    }

    @include mq(md) {
        position: absolute;
        width: 40%;
        height: 100%;
        transform: translateX(-100%);
        top: 0;
        left: 0;
        padding: 0;
        overflow: visible;
        padding-left: $grid-gutter-width/2;
        padding-right: $grid-gutter-width;
        transition: transform 240ms ease-in-out;
    }

    .form--dealers,
    .gmap-results__title {
        display: none;

        @include mq(md) {
            display: block;
        }
    }
}

.gmap-results__trigger {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 62px;
    font-weight: 600;
    border-top: 1px solid color(greys, light);
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
    margin-left: -#{$grid-gutter-width/2};
    margin-right: -#{$grid-gutter-width/2};
    box-shadow: 0 10px 23px 0 rgba(0, 0, 0, .05);
    transform: translate3d(0, 0, 0);

    @include mq(md) {
        width: 40px;
        height: 72px;
        position: absolute;
        top: 70px;
        right: -56px;
        padding: 0;
        margin: 0;
        justify-content: center;
        background-color: white;
        transition: right $transition-fast ease-in-out;
        border: 1px  solid color(greys, light);

        .gmap-results__trigger-label {
            display: none;
        }
    }

    .icon-angle-up {
        transform-origin: 50%;
        transform: rotate(180deg);
        position: relative;
        top: -2px;
        color: color(brand, primary);

        @include mq(md) {
            top: 2px;
            left: -2px;
            transform: rotate(90deg);
        }
    }
}


.gmap-results.is-opened {
    height: 100%;
    top: 0;
    overflow: auto;
    transform: translateY(0);

    @include mq(md) {
        transform: translate(0, 0);
        overflow: visible;
        border-right: 1px solid color(greys, light);
    }

    .gmap-results__trigger {
        border-top: 0;
        border-bottom: 1px solid color(greys, light);

        @include mq(md) {
            border-top: 1px solid color(greys, light);
            right: -40px;
        }

        .icon-angle-up {
            transform: rotate(0deg);
            top: 2px;

            @include mq(md) {
                left: 2px;
                transform: rotate(-90deg);
            }
        }
    }
}


.gm-style .gm-style-iw {
    padding: em(20px) em(15px) em(10px);
    font-size: rem(16px);
    font-weight: 400;
    line-height: 1.5;

    .title {
        margin-bottom: rem(5px);
        font-weight: 600;
    }
    a {
        color: $root-color;
        &[href*='mailto'],
        &[target='_blank'] {
            color: color(greys, base);
        }

        &:hover {
            text-decoration: underline;
        }
    }
}


.section--dealers {
    display: block;
}
