.brand {
    display: inline-block;

    img.svg,
    svg {
        max-width: 100%;
    }

    svg path {
        fill: $white !important;
    }
}
