// ==========================================================================
// Spacing helpers
// ==========================================================================

@include helper-vspacing(4, 'padding');
@include helper-vspacing(4, 'margin');


.h-vspacing-mt-1-5 {
    margin-top: $root-vgrid*1.5;
}

.h-vspacing-mb-1-5 {
    margin-bottom: $root-vgrid*1.5;
}

.h-vspacing-pt-1-5 {
    padding-top: $root-vgrid*1.5;
}

.h-vspacing-mt-1-5 {
    margin-top: $root-vgrid*1.5;
}

.h-vspacing-pb-1-5 {
    padding-bottom: $root-vgrid*1.5;
}

.h-vspacing-mt-2 {
    margin-top: $root-vgrid;

    @include mq(md) {
        margin-top: $root-vgrid*2;
    }
}

.h-vspacing-mb-2 {
    margin-bottom: $root-vgrid;

    @include mq(md) {
        margin-bottom: $root-vgrid*2;
    }
}

.h-vspacing-m-3 {
    margin-top: $root-vgrid*1.5;
    margin-bottom: $root-vgrid*1.5;

    @include mq(md) {
        margin-top: $root-vgrid*3;
        margin-bottom: $root-vgrid*3;
    }
}

.h-vspacing-m-4 {
    margin-top: $root-vgrid*2;
    margin-bottom: $root-vgrid*2;

    @include mq(md) {
        margin-top: $root-vgrid*4;
        margin-bottom: $root-vgrid*4;
    }
}

.h-layout-max-width {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
}

.h-layout-zm-max-width {
    max-width: 875px;
    margin-left: auto;
    margin-right: auto;
}

.h-layout-zm-max-width--large {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.h-layout-zm-max-width--xlarge {
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
}


.h-layout-max-width--small {
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
}

.h-image--centered {
    margin-left: auto;
    margin-right: auto;
}
