// Custom Checkbox
// --------------------------------------------------
// @example html - Markup
// <div class='form-item | form-item--inline'>
//      <input type='checkbox' name='' id=''>
//      <label for=''> ... </label>
// </div>

@mixin form-checkbox($checkbox-size: ($form-element-height / 2), $checkbox-color: color(brand, secondary)) {

    input[type='checkbox'] {
        display: block;
        opacity: 0;
        position: absolute;
        top: $checkbox-size / 2;
        left: $checkbox-size / 2;
        transform: translate(-50%, -50%);
        width: auto;
        height: auto;

        & + label {
            position: relative;
            display: inline-block;
            min-height: $checkbox-size;
            line-height: $checkbox-size;
            padding-left: $checkbox-size * 1.25;
            cursor: pointer;
            color: $root-color;

            &::before,
            &::after {
                position: absolute;
                content: '';
            }

            &::before {
                top: 0;
                left: 0;
                display: inline-block;
                width: $checkbox-size;
                height: $checkbox-size;
                background-color: $form-element-background-color;
                border: 1px solid $form-element-border-color;
                transition: border-color $transition-fast ease-in-out;
            }

            &::after {
                top: $checkbox-size / 5;
                left: $checkbox-size / 2.75;
                display: block;
                width: $checkbox-size / 3.5;
                height: $checkbox-size / 2;
                border-right: 3px solid $checkbox-color;
                border-bottom: 3px solid $checkbox-color;
                opacity: 0;
                transform: rotate(40deg) scale(0);
                transition: $transition-fast ease-in-out;
            }

            &:hover::before {
                border-color: $checkbox-color;
            }
        }

        &:focus + label:before {
            outline: 1px dashed color(greys, base);
        }

        &:checked + label {
            &::after {
                opacity: 1;
                transform: rotate(40deg) scale(1);
            }
        }

        &:indeterminate + label {
            &::after {
                top: $checkbox-size / 4;
                left: $checkbox-size / 2.125;
                width: 3px;
                opacity: 1;
                transform: rotate(90deg) scale(1);
            }
        }
    }
}
