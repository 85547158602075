// Form normalize
// --------------------------------------------------

@mixin form-normalize($bgColor: '#fff', $hubspot: false) {

    [gr-form-normalize] {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #{$bgColor};
            z-index: 10;
            transition: opacity $transition-normal;
        }
    }

    [gr-form-normalize='is-ready'] {
        &::before {
            opacity: 0;
            visibility: hidden;
        }
    }

    @if $hubspot {
        .hs-form ul {
            list-style: none;
        }
    }
}
