.zm-download-table {
    margin-top: $root-vgrid*1.5;
    list-style: none;

    li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        padding: $grid-gutter-width/2;
        color: color(greys, dark);
        border-bottom: 1px solid color(member-zone, grey-dark);
        transition: background-color $transition-fast ease-in-out;

        @include mq(sm, 1) {
           > * + * {
                margin-top: 1rem;
            }
        }

        @include mq(sm) {
            padding: $grid-gutter-width/1.5 $grid-gutter-width;
            font-size: rem(18px);
            flex-direction: row;
        }

        @include mq(md) {
            &:hover {
                background-color: transparentize(color(greys, xlight), .5);
            }
        }
    }
}

.zm-download-table__extension {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    color: color(brand, primary);
    border: 1px solid color(brand, primary);
    border-radius: 50%;
    font-size: 10px;

    .svg path {
        fill: color(brand, primary);
    }

    @include mq(sm) {
        margin-right: $grid-gutter-width/2;
    }
}

.zm-download-table__title {
    line-height: 1.6;
    color: #4c4c4c;
    text-align: center;

    @include mq(sm) {
        margin-right: $grid-gutter-width/2;
        text-align: left;
    }

    &.is-featured {
        color: color(member-zone, red);
        position: relative;
        &::before {
            background-color: color(member-zone, red);
            border-radius: 50%;
            content: '';
            display: inline-block;
            margin-right: 8px;
            position: absolute;
            right: 100%;
            top: 7px;
            width: 12px;
            height: 12px;
        }
    }
}

.zm-download-table__notice {
    display: block;
    color: transparentize(black, .7);
    text-align: center;

    @include mq(sm) {
        display: inline;
        text-align: left;
    }
}

.zm-download-table__date {
    flex-grow: 1;
    text-align: right;
    font-size: rem(12px);

    @include mq(sm) {
        padding-right: 4rem;
    }
}

.zm-download-table__icon {
    @include mq(sm) {
        margin-left: $grid-gutter-width/2;
    }
}

.zm-download-table__icon-email {
    @include mq(sm) {
        transform: translateY(2px);
    }
}
