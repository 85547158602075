.zm-header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;
    background-color: white;

    @include mq(md) {
        position: relative;
        justify-content: flex-end;
        height: 100px;
        z-index: auto;

    }
}

.zm-header__left,
.zm-header__right {
    display: flex;
    align-items: center;

    svg {
        path,
        use {
            transition: all $transition-fast ease-in-out;
        }
    }

    @include mq(md) {
        > a:hover {
            svg {
                path,
                use {
                    fill: transparentize(color(brand, primary), .5);
                }
            }
        }
    }
}

.zm-header__left {
    @include mq(md) {
        display: none;
    }
}

.zm-notice {
    flex: 1 0 100%;
    background-color: $color-secondary;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: rem(14px);
    padding-top: 60px;

    @include mq(md) {
        padding-top: 0;
    }

    .icon-warning {
        margin-right: rem(10px);
        font-size: rem(20px);
        width: 100%;
        max-width: rem(40px);
        height: rem(40px);
        border-radius: 50%;
        border: $white 1px solid;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: $root-vgrid / 2;
        flex: 0 0 rem(40px);

        @include mq(sm) {
            margin-bottom: 0;
        }

        &::before {
            top: -2px;
            position: relative;
        }
    }

    .icon-close {
        color: #fff;
    }

    &__close {
        position: absolute;
        top: rem(10px);
        right: rem(10px);
    }

    &__body {
        display: flex;
        padding: $root-vgrid / 2;
        align-items: center;
        flex-direction: column;

        @include mq(sm) {
            flex-direction: row;
        }

        > div {
            flex-grow: 1;
        }
    }


    @include mq(md) {
        padding-left: 145px;
        height: 100px;
    }

    @include mq(lg) {
        padding-left: 210px;
    }
}

@media screen and (max-width: 1024px) {
    .zm-notice {
        &__close {
            position: absolute;
            top: auto;
            right: rem(20px);
        }    
    }
}

.zm-header__right {

    > * {
        display: flex;
        align-items: center;
    }

    img {
        display: inline-block;
    }

    > * + * {
        border-left: 1px solid color(greys, light);
        margin-left: $grid-gutter-width/2;
        padding-left: $grid-gutter-width/2;

        @include mq(sm) {
            margin-left: $grid-gutter-width;
            padding-left: $grid-gutter-width;
        }
    }
}

.zm-header__ico-cart {
    width: 21px;
    height: 21px;
}

.zm-header__ico-user {
    width: 28px;
    height: 28px;

    @include mq(md) {
        width: 31px;
        height: 31px;
    }
}

.zm-header__link {
    display: none;
    color: color(brand, primary);
    font-weight: 600;
    line-height: 1.75;
    padding-left: .6rem;
    transition: color $transition-fast ease-in-out;

    @include mq(md) {
        display: inline-block;

        &:hover {
            color: transparentize(color(brand, primary), .5);
        }
    }

    small {
        display: block;
        font-weight: 400;
        font-size: rem(14px);
        line-height: 1;
    }
}

.zm-header-dropdown {
    position: relative;
    height: 40px;
    cursor: pointer;

    @include mq(md) {

        &:hover {
            .zm-header__link {
                color: transparentize(color(brand, primary), .5);
            }

            > .svg {
                path,
                use {
                    fill: transparentize(color(brand, primary), .5);
                }
            }
        }
    }

    &:hover {
        .zm-header-dropdown__menu {
            display: block;
        }
    }
}

.zm-header-dropdown__trigger {
    padding: 1rem;


    @include mq(md) {
        margin-left: .5rem;
    }
}

.zm-header-dropdown__menu {
    display: none;
    padding-top: $root-vgrid/1.5;
    position: absolute;
    z-index: 10;
    right: 0;
    bottom: 0;
    width: 200px;
    transform: translateY(100%);

    &::before {
        content: "";
        position: absolute;
        top: $root-vgrid/1.5;
        right: 1rem;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 10px 15px;
        border-color: transparent transparent white;
        transform: translateY(-100%);
    }

    @include mq(sm) {
        width: 320px;
    }
}

.zm-header-dropdown__menu-inner {
    background-color: white;
    box-shadow: 0 4px 15px rgba(0, 0, 0, .15);
}

.zm-header-dropdown__link {
    padding: $grid-gutter-width/4 $grid-gutter-width/2;
    color: transparentize(color(member-zone, grey-dark), .5);
    font-weight: 600;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: color $transition-fast ease-in-out;

    @include mq(sm) {
        padding: $grid-gutter-width/2 $grid-gutter-width;
    }

    @include mq(md) {
        &:hover {
            color: transparentize(color(member-zone, grey-dark), .2);
        }
    }

    & + .zm-header-dropdown__link {
        border-top: 1px solid color(greys, light);
    }

    svg path {
        fill: color(brand, primary);
    }
}

.banner--zm .header--landing__inner.header--home__inner {
    .typography {
        display: block;

        @include mq(sm, 1) {
            margin-top: 0;
        }

        p {
            display: none;
            @include mq(sm) {
                display: block;
            }
        }
    }
}

