// Styleguide: Typography
// --------------------------------------------------------------------------
.h-hero {
    font-size: 1.8em;
    line-height: 1.3;
}

.h1 {
    line-height: 1.3;

    @include mq(sm, 1) {
        font-size: 1.5em;
    }
}

.h2 {
    line-height: 1.4;

    @include mq(sm, 1) {
        font-size: 1.3em;
    }
}

.hsmall {
    font-size: 1em;
    font-weight: 600;
}

.hxsmall {
    color: #8F897A;
    font-size: 0.8em;
}

@include typography();

.typography {

    text-transform: none;

    h1,
    .h1 {
        line-height: 1.3;

        @include mq(sm, 1) {
            font-size: 1.5em;
        }
    }

    h2,
    .h2 {
        line-height: 1.4;

        @include mq(sm, 1) {
            font-size: 1.3em;
        }
    }


    [class^='btn--'] {
        margin-bottom: $root-vgrid;
    }

    // a:not([class]) {
    //     box-shadow: inset 0 0 0 0 $color-primary;
    //     transition: $transition-slow ease-in-out;
    //
    //     &:hover {
    //         box-shadow: inset 300px 0 0 0 $color-primary;
    //     }
    // }

    ol > li::before {
        content: counters(item, '.') '. ';
        color: inherit;
        font-weight: inherit;
    }

    ul li {
        margin-bottom: 1em;
    }

    img {
        max-width: 100%;
    }

    blockquote {

        font-size: 1em;
        margin-left: auto;
        margin-right: auto;
        max-width: 80%;
        position: relative;
        padding-top: 4.5rem;
        padding-bottom: 4.5rem;
        text-align: center;
        max-width: 610px;

        &::before,
        &::after {
            display: inline-block;
            content: $icon-quote-left;
            font-family: $font-icon;
            position: absolute;
            font-size: rem(36px);
            font-style: normal;
            left: 0;
            right: 0;
            color: color(greys, xlight);
        }

        &::before {
            top: 0;
        }

        &::after {
            transform: rotate(180deg);
            bottom: 0;
        }

        cite {
            font-style: normal;
            font-weight: 700;
            display: block;
            // text-align: right;
            margin-top: $root-vgrid/2;
        }
    }

    @for $i from 1 through 5 {
        h#{$i} {
            font-family: $font-primary;
            //font-weight: 700;
            //text-transform: uppercase;
        }
    }
}

.section--blog article.typography {
    @for $i from 1 through 5 {
        h#{$i} {
            font-weight: 600;
        }
    }
}


.typography--product {
    max-width: 1470px;
    margin-left: auto;
    margin-right: auto;

    ul {
        margin-bottom: 0;
    }

    p {
        margin-bottom: 0;
    }
}

.typography--product:not(.modularWalls-list) {

    counter-reset: productlist;

    ul {

        > li {
            margin-bottom: 1.5rem;
            min-height: 40px;
            display: flex;
            align-items: center;

            @include mq(sm) {
                padding-right: 60px;
            }

            @include mq(md) {
                margin-bottom: 3rem;
            }


        }
    }

    ul > li::before {
        position: relative;
        counter-increment: productlist;
        content: counters(productlist, '.') '';
        font-weight: inherit;
        color: $white;
        width: 40px;
        height: 40px;
        background-color: color(member-zone, red);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 0;
        top: 0;
        font-weight: 600;
        align-self: flex-start;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 40px;
        margin-right: 1rem;
        font-family: $font-primary;
    }
}

.modularWalls-list {
    ul li {
        &::before {
            display: none;
        }

        min-height: 100px;
        display: flex;
        align-items: center;
        padding-left: 0;
        margin-bottom: $root-vgrid;

        .img-wrapper {
            background-color: color(greys, xdark);
            border-radius: 50%;
            width: 60px;
            height: 60px;
            flex-basis: 60px;
            flex-grow: 0;
            flex-shrink: 0;
            margin-right: $grid-gutter-width/2;

            @include mq(md) {
                margin-right: $grid-gutter-width;
            }

            @include keep-ratio('1/1');

            img {
                margin: 0;
                max-width: 100% !important;
            }
        }
    }
}

.typography {
    .text-right {
        text-align: right;
    }

    .text-center {
        text-align: center;
    }
}

.typography table,
.table {
    thead {
        text-align: left;
        //text-transform: uppercase;

        th {
            // padding-left: 0;
        }
    }

    tbody td {
        border-top: .5em solid $white;
        background-color: $table-bg-accent;
    }
}

.section__table {
    @include mq(sm) {

        tbody td:last-child {
            text-align: right;
            padding: 0;
            vertical-align: top;
            width: 1px;
            white-space: nowrap;
            border-left: .5em solid $white;
            background-color: $white;
        }
    }
}

address {
    font-style: normal;
    margin-top: $root-vgrid;

    .title {
        margin-bottom: 1em;
    }

    & + .btn--primary {
        margin-top: 1em;
    }
}


hr {
    margin: $root-vgrid 0;
    height: 1px;
    background: transparentize($black, .8);

    @include mq(sm) {
        margin: (1.5 * $root-vgrid) 0;
    }
}

dl {
    overflow: hidden;
    padding-top: $root-vgrid;
    padding-bottom: $root-vgrid;

    dd,
    dt {
        float: left;
    }

    dt {
        //text-transform: uppercase;
        font-weight: 700;
        clear: left;

        &::after {
            content: " : ";
            margin-right: .5em;
        }
    }
}
