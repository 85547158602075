[gr-video-youtube] {
    position: relative;
    background: transparent;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    &[gr-video-youtube~=done] img {
        opacity: 0;
    }

    [gr-video-wrapper] {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
        overflow:hidden;

        & > iframe {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate3d(-50%, -50%, 0);
            min-width: 100%;
            min-height: 100%;
        }
    }
}
