// ==========================================================================
// Pop
// ==========================================================================

$pop-font-size: 13px !default;
$pop-font-color: #fff !default;
$pop-bg-color: rgba(17, 17, 17, .8) !default;
$pop-length-small: 80px !default;
$pop-length-medium: 150px !default;
$pop-length-large: 260px !default;

[gr-pop] {

@include mq(md){

    position: relative;
    &:after {
        position: absolute;
        font-size: $pop-font-size;
        border-radius: .4rem;
        content: attr(gr-pop);
        padding: .8rem;
        background-color: $pop-bg-color;
        box-shadow: 0 0 14px rgba(0, 0, 0, 0.1);
        color: $pop-font-color;
        line-height: 1.25rem;
        text-align: left;
        z-index: 1;
        pointer-events: none;
        display: block;
        opacity: 0;
        visibility: hidden;
        transition: all .3s;
        bottom: 100%;
        left: 50%;
        transform: translate(-50%, 10px);
        white-space: pre;
        width: auto;
    }
    &:after {
        opacity: 0;
    }
    &:hover {
        overflow: visible;
        &:after {
            display: block;
            opacity: 1;
            visibility: visible;
            transform: translate(-50%, -10px);
        }
    }

    &.pop--bottom, &[gr-pop-pos="bottom"] {
        &:after {
            bottom: auto;
            top: 100%;
            left: 50%;
            transform: translate(-50%, -10px);
        }
        &:hover {
            &:after {
                transform: translate(-50%, 10px);
            }
        }
    }

    &.pop--left, &[gr-pop-pos="left"] {
        &:after {
            bottom: auto;
            left: auto;
            top: 50%;
            right: 100%;
            transform: translate(10px, -50%);
        }
        &:hover {
            &:after {
                transform: translate(-10px, -50%);
            }
        }
    }

    &.pop--right, &[gr-pop-pos="right"] {
        &:after {
            bottom: auto;
            top: 50%;
            left: 100%;
            transform: translate(-10px, -50%);
        }
        &:hover {
            &:after {
                transform: translate(10px, -50%);
            }
        }
    }

    &[gr-pop-length="small"]:after, &.pop-length--small:after {
        white-space: normal;
        width: $pop-length-small;
    }
    &[gr-pop-length="medium"]:after, &.pop-length--medium:after {
        white-space: normal;
        width: $pop-length-medium;
    }
    &[gr-pop-length="large"]:after, &.pop-length--large:after {
        white-space: normal;
        width: $pop-length-large;
    }
    &[gr-pop-length="fit"]:after, &.pop-length--fit:after {
        white-space: normal;
        width: 100%;
    }

    &[gr-pop-align="right"]:after, &.pop-align--right:after {
        text-align: right;
    }
    &[gr-pop-align="center"]:after, &.pop-align--center:after {
        text-align: center;
    }
}

}
