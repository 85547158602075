// ==========================================================================
// Table
// ==========================================================================

@mixin table($table-class: '.gr-table', $table-mbreakpoint: sm) {

    // Baseline styles
    // ==========================================================================

    #{$table-class} {
        width: 100%;
        max-width: 100%;
        background-color: $table-bg;

        tr {
            transition: all .3s ease;
        }

        th,
        td {
            text-align: left;
            padding: $table-cell-padding;
            vertical-align: top;
            border-top: 0;
            transition: all .3s ease;
        }

        > thead > tr > th {
            background-color: $table-header-bg-color;
            font-weight: $table-header-font-weight;
            text-transform: $table-header-font-uppercase;
            vertical-align: bottom;
            border-bottom: 1px solid $table-border-color;
            color: $table-header-font-color;
        }

        > caption + thead,
        > colgroup + thead,
        > thead:first-child {
            > tr:first-child {
                > th,
                > td {
                    border-top: 0;
                }
            }
        }

        > tbody + tbody {
            border-top: 1px solid $table-border-color;
        }

        // Nesting
        .table {
            background-color: $table-bg;
        }

        // Remove border
        .no-border {
            border: 0;
        }
    }

    // Condensed table w/ half padding
    // ==========================================================================

    [gr-table*='condensed'] {
        th,
        td {
            padding: $table-condensed-cell-padding;
        }
    }

    // Bordered version
    //
    // Add horizontal borders between columns
    // ==========================================================================

    [gr-table*='bordered'] {
        border-color: $table-border-color;
        border-width: 0 1px;

        th,
        td {
            border-bottom: 1px solid;
        }

        thead {
            th,
            td {
                border-bottom-width: 2px;
            }
        }
    }

    // Zebra-striping
    //
    // Default zebra-stripe styles (alternating gray and transparent backgrounds)
    // ==========================================================================

    [gr-table*='striped'] tbody tr:nth-child(odd) {
        background-color: $table-bg-accent;
    }

    // Hover effect
    // ==========================================================================

    [gr-table*='hovered'] tbody tr:hover {
        td,
        th {
            background-color: $table-bg-hover;
        }
    }

    // Responsive tables (vertical)
    //
    // Wrap your tables in `$table-class-responsive-vertical` and we'll make them mobile friendly
    // by vertical table-cell display. Only applies <sm. Everything above that will display normally.
    // For correct display you must add 'data-title' to each 'td'
    // ==========================================================================

    [gr-table*="responsive"] {

        @include mq(#{$table-mbreakpoint}, 1) {
            margin-bottom: 0;
            background-color: transparent;

            thead,
            tfoot {
                display: none;
            }

            tbody {
                display: block;

                tr {
                    display: block;
                    border: 1px solid $table-border-color;
                    border-radius: 2px;
                    margin-bottom: $table-cell-padding;
                    background-color: $table-bg;

                    td {
                        background-color: $table-bg;
                        display: block;
                        text-align: right;

                        &[data-title]::before {
                            content: attr(data-title);
                            float: left;
                            font-size: inherit;
                            font-weight: $table-header-font-weight;
                            color: $table-header-font-color-mobile;
                        }
                    }
                }
            }

            // Special overrides for the bordered tables
            &[gr-table*='bordered'] {
                border: 0;

                // Nuke the appropriate borders so that the parent can handle them
                td,
                th {
                    &:last-child {
                        border-bottom-width: 0;
                    }
                }
            }

            // Special overrides for the striped tables
            &[gr-table*='striped'] td:nth-child(odd) {
                background-color: $table-bg-accent;
            }

            // Special overrides for hover tables
            &[gr-table*='hovered'] {
                tr:hover,
                tr:hover td {
                    background-color: $table-bg;
                }

                tr > td:hover {
                    background-color: $table-bg-hover;
                }
            }

        }
    }
}
