// ==========================================================================
// Root typography
// ==========================================================================

%root-typography {
    color: if(variable-exists(root-color), $root-color, #222);
    font-size: if(variable-exists(root-font-size), $root-font-size, 16px);
    line-height: if(variable-exists(root-line-height), $root-line-height, 1.5);
    font-family: if(variable-exists(root-font-family), $root-font-family, $font-primary);
    text-rendering: if(variable-exists(root-text-rendering), $root-text-rendering, optimizeLegibility);
}


@if map-has-key($fonts, 'hosted') {
    @each $type, $fontmap in $fonts {
        @if (type-of($fontmap) == map and $type=='hosted') {
            @each $fontalias, $fontname in $fontmap {
                @include font-face($fontname, #{$font-path}#{$fontname}, normal, normal);
            }
        }
    }
}
