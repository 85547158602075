// ==========================================================================
// Typography
// ==========================================================================

$typo-heading-max: if(variable-exists(typo-heading-max), $typo-heading-max, 3em);
$modular-scale: sqrt(sqrt(strip-unit($typo-heading-max)));

//Checking if $font-icon has been generated by fonts:icons task
$typo-list-bullet-font: if(variable-exists(font-icon), $font-icon, $root-font-family);


@for $i from 1 through 5 {
    %h#{$i} {
        font-size: pow($modular-scale, (5 - $i)) * 1em;
        @if ($root-font-family != map-get($typo-headings-fonts,h#{$i})) {
            font-family: map-get($typo-headings-fonts,h#{$i});
        }
        @if ($root-color != map-get($typo-headings-colors,h#{$i})) {
            color: map-get($typo-headings-colors,h#{$i});
        }
    }
}

%link {
    color: $typo-link-color;
    text-decoration: $typo-link-decoration;
    cursor: pointer;

    &:hover {
        color: $typo-link-hover-color;
        text-decoration: $typo-link-hover-decoration;
    }
}

%unordered-list,
%ordered-list {
    list-style: none;

    & > li {
        position: relative;
    }

    ul,
    ol {
        margin: 0;
    }
}

%unordered-list {
    & > li {
        padding-left: $root-padding;
    }

    & > li::before {
        content: $typo-list-bullet;
        font-family: $typo-list-bullet-font;
        color: $typo-list-bullet-color;
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
    }
}

%ordered-list {
    counter-reset: item;

    & > li::before {
        content: counters(item, '.') ' - ';
        counter-increment: item;
        color: $typo-list-bullet-color;
    }

    ol {
        counter-reset: item;
        padding-left: $root-padding;
    }
}

%address {
    font-style: normal;
}

%blockquote {
    font-style: italic;

    p {
        margin: 0;
    }

    q {
        &::after {
            margin-left: .5em;
        }

        &::before {
            margin-right: .5em;
        }
    }

    footer {
        font-style: normal;
        font-size: small;
    }
}


@mixin typography($class: '.typography') {

    @for $i from 1 through 5 {
        .h#{$i} {
            @extend %h#{$i};
        }
    }

    #{$class} {
        @extend %clearfix;
        line-height: $root-line-height;

        @for $i from 1 through 5 {
            h#{$i} {
                @extend %h#{$i};
                margin-bottom: $root-vgrid/2;
                line-height: (floor(pow($modular-scale, (5 - $i))) * $root-line-height) / pow($modular-scale, (5 - $i));
            }
        }

        a:not([class]) {
            @extend %link;
        }

        ul {
            @extend %unordered-list;
        }

        ol {
            @extend %ordered-list;
        }

        address {
            @extend %address;
        }

        blockquote {
            @extend %blockquote;
        }

        p,
        ul,
        ol,
        address,
        blockquote,
        table {
            margin-bottom: $root-vgrid;
        }
    }
}
