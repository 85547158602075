// ==========================================================================
// Auto-generate SCSS file for icon font from SVG icons
// ==========================================================================

@function stop-cache($ressource) {
    $cachebuster: random(9999);
    @return $ressource +"?v="+ $cachebuster;
}

$font-icon: "artopex-iconfont";
$font-path: "../fonts/" !default;

@font-face {
    font-family: $font-icon;
    src: url(stop-cache('#{$font-path}#{$font-icon}.woff')) format('woff'), url(stop-cache('#{$font-path}#{$font-icon}.ttf')) format('truetype');
}

[class*='icon-']::before {
    font-family: $font-icon;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    display: inline-block;
}

$icon-angle-down: "\E001";
$icon-angle-left: "\E002";
$icon-angle-right: "\E003";
$icon-angle-up: "\E004";
$icon-arrow-down: "\E005";
$icon-arrow: "\E006";
$icon-arrows-h: "\E007";
$icon-camera: "\E008";
$icon-close: "\E009";
$icon-download: "\E00A";
$icon-eye: "\E00B";
$icon-file-o: "\E00C";
$icon-file-pdf-o: "\E00D";
$icon-image: "\E00E";
$icon-li-dot: "\E00F";
$icon-lock: "\E010";
$icon-moins: "\E011";
$icon-mouse: "\E012";
$icon-off: "\E013";
$icon-pencil: "\E014";
$icon-play-button: "\E015";
$icon-play-circle: "\E016";
$icon-plus: "\E017";
$icon-quote-left: "\E018";
$icon-quote-right: "\E019";
$icon-quote: "\E01A";
$icon-refresh: "\E01B";
$icon-search-plus: "\E01C";
$icon-search: "\E01D";
$icon-user-circle: "\E01E";
$icon-warning: "\E01F";

.icon-angle-down::before {
    content: $icon-angle-down;
}

.icon-angle-left::before {
    content: $icon-angle-left;
}

.icon-angle-right::before {
    content: $icon-angle-right;
}

.icon-angle-up::before {
    content: $icon-angle-up;
}

.icon-arrow-down::before {
    content: $icon-arrow-down;
}

.icon-arrow::before {
    content: $icon-arrow;
}

.icon-arrows-h::before {
    content: $icon-arrows-h;
}

.icon-camera::before {
    content: $icon-camera;
}

.icon-close::before {
    content: $icon-close;
}

.icon-download::before {
    content: $icon-download;
}

.icon-eye::before {
    content: $icon-eye;
}

.icon-file-o::before {
    content: $icon-file-o;
}

.icon-file-pdf-o::before {
    content: $icon-file-pdf-o;
}

.icon-image::before {
    content: $icon-image;
}

.icon-li-dot::before {
    content: $icon-li-dot;
}

.icon-lock::before {
    content: $icon-lock;
}

.icon-moins::before {
    content: $icon-moins;
}

.icon-mouse::before {
    content: $icon-mouse;
}

.icon-off::before {
    content: $icon-off;
}

.icon-pencil::before {
    content: $icon-pencil;
}

.icon-play-button::before {
    content: $icon-play-button;
}

.icon-play-circle::before {
    content: $icon-play-circle;
}

.icon-plus::before {
    content: $icon-plus;
}

.icon-quote-left::before {
    content: $icon-quote-left;
}

.icon-quote-right::before {
    content: $icon-quote-right;
}

.icon-quote::before {
    content: $icon-quote;
}

.icon-refresh::before {
    content: $icon-refresh;
}

.icon-search-plus::before {
    content: $icon-search-plus;
}

.icon-search::before {
    content: $icon-search;
}

.icon-user-circle::before {
    content: $icon-user-circle;
}

.icon-warning::before {
    content: $icon-warning;
}
