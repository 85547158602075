.typography .text-block {
    border: 1px solid color(member-zone, grey-base);
    padding: 1rem;
    line-height: 1.7;
    margin-top: $root-vgrid*1.5;

    a {
        text-decoration: none;
        color: color(brand, primary);
    }

    @include mq(sm) {
        padding: 1.5rem;
    }

    @include mq(md) {
        padding: 2.5rem;
    }

    @include mq(lg) {
        padding: 3rem;
    }

    *:last-child {
        margin-bottom: 0;
    }
}

.zm-site-container {
    @include mq(sm) {
        .main {
            min-height: calc(100vh - 124px);
        }
    }

    @include mq(md) {
        .main {
            min-height: calc(100vh - 184px);
        }
    }

    hr {
        background-color: color(member-zone, grey-light);
    }

    .typography {
        color: color(greys, dark);
    }
}

.typography--zm-offset {
    @include mq(lg) {
        margin-left: 3.5rem;
    }
}

.zm-title {
    font-size: rem(28px);
    line-height: 1.16;
    color: color(greys, dark);

    @include mq(md) {
        font-size: rem(36px);
    }

    + hr {
        margin-top: $root-vgrid;
        margin-bottom: $root-vgrid;
    }
}


.mfp-audio {
    position: relative;
    max-width: 400px;
    padding-top: 2.5rem;
    margin-left: auto;
    margin-right: auto;
    background-color: white;

    audio {
        width: 100%;
        border-top: 1px solid color(greys, dark);
    }
}
