// ==========================================================================
// Grid rythm
// ==========================================================================

$root-hgrid: $grid-gutter-width;
$root-vgrid: $root-line-height * 1rem;

@if ($root-font-size * $root-line-height < $root-hgrid) {
    // Set the minimum vertical rythm at the gutter-width
    $root-vgrid: ($root-hgrid/ $root-font-size) * 1rem;
}
