// ==========================================================================
// Navigation Icon (responsive CTA) - SVG version - buggy on IE / Firefox
// ==========================================================================

// <button class="$nav-icon-class" gr-nav-trigger><img src="" class="svg"></button>

@mixin nav-icon-svg($nav-icon-class: '.nav-icon') {

    #{$nav-icon-class} {
        position: relative;
        display: block;
        cursor: pointer;
        color: inherit;
        background: none;

        svg {
            position: relative;
            width: auto;
            height: auto;
        }

        // #top:       g:nth-of-type(1)
        // #middle:    g:nth-of-type(2)
        // #bottom:    g:nth-of-type(3)

        g {
            transition: transform 150ms ease-in-out;
            transform: translate(25px, 15px) rotate(0);
        }

        rect {
            transition: transform 150ms ease-in-out 200ms;
            transform: translate(-25px, -15px);
            fill: currentColor;
        }

        g:nth-of-type(2) {
            rect {
                opacity: 1;
                transition: opacity 250ms ease-in-out 50ms;
            }
        }
    }

    .has-nav-open [gr-nav-trigger=squeeze] {
        rect {
            transition: transform 150ms ease-in-out 250ms;
        }

        g:nth-of-type(1),
        g:nth-of-type(3) {
            transition: transform 150ms ease-in-out;
        }

        g:nth-of-type(1) {
            transform: translate(20px, 15px) rotate(45deg);

            rect {
                transform: translate(-25px, -5px);
            }
        }

        g:nth-of-type(2) {
            rect {
                opacity: 0;
            }
        }

        g:nth-of-type(3) {
            transform: translate(20px, 15px) rotate(-45deg);

            rect {
                transform: translate(-25px, -25px);
            }
        }
    }

    .has-nav-open [gr-nav-trigger=collapse] {

        g:nth-of-type(1),
        g:nth-of-type(2) {
            transition: transform 150ms ease-in-out;
        }

        g:nth-of-type(1) {
            transform: translateY(20px);
        }

        g:nth-of-type(2) {
            transform: translateY(10px);
        }
    }
}

// ==========================================================================
// Navigation Icon (responsive CTA) - CSS version
// ==========================================================================

// <button class="$nav-icon-class" gr-nav-trigger><span></span></button>

@mixin animated-icon( $type: 'hamburger', $variante: 'default', $color: #fff, $bar: 2px, $radius: 0 ) {
     @if not index(hamburger plus arrow search, $type){
        @error "Type must be either `hamburger`, `plus`, `arrow` or `search`.";
    }

    position: relative;
    cursor: pointer;
    background: transparent;

    &:after,
    &:before {
        content: '';
    }

    &:before,
    &:after,
    span {
        position: absolute;
        display: block;
        background-color: $color;
        border-radius: em($radius);
        transition: all .2s;
    }

    // Mixin / Hamburger
    // --------------------------------------------------------------------------

    @if $type == hamburger {
         @if not index(default cross, $variante){
            @error "Variant for hamburger icon must be either `default` or `cross`.";
        }

        width: 1em * 1.5;
        height: 1em;

        &:before,
        &:after,
        span {
            left: 0;
            transform: translate3d(0, 0, 0);
            width: 100%;
            height: $bar;
        }

        &:before {
            top: 0;
        }

        span {
            top: 50%;
            transform: translate3d(0, -50%, 0);
        }

        &:after {
            bottom: 0;
        }

        &:hover::before {
            top: -3px;
        }

        &:hover::after {
            bottom: -3px;
        }

        @if $variante == default {
            &[gr-nav-trigger~='active'] {
                &:before {
                    top: 50%;
                    transform: translate3d(0, -50%, 0);
                }

                &:after {
                    bottom: 50%;
                    transform: translate3d(0, 50%, 0);
                }
            }
        }
        @if $variante == cross {
            &:after,
            &:before {
                transform-origin: center;
            }

            &[gr-nav-trigger~='active'] {
                &:before {
                    top: 50%;
                    transform: translate3d(0, -50%, 0) rotate(45deg);
                }

                span {
                    opacity: 0;
                }

                &:after {
                    bottom: 50%;
                    transform: translate3d(0, 50%, 0) rotate(-45deg);
                }
            }
        }
    }

    // Mixin / Plus
    // --------------------------------------------------------------------------

    @if $type == plus {
         @if not index(default cross, $variante){
            @error "Variant for plus icon must be either `default` or `cross`.";
        }

        width: 1em;
        height: 1em;

        &:before {
            top: 50%;
            left: 0;
            transform: translate3d(0, -50%, 0) rotate(0);
            width: 100%;
            height: $bar;
        }

        &:after {
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0) rotate(0);
            width: $bar;
            height: 100%;
        }
        @if $variante == default {
            [gr-nav*='open'] & {
                &:before {
                    transform: translate3d(0, -50%, 0) rotate(180deg);
                }

                &:after {
                    transform: translate3d(-50%, -50%, 0) rotate(90deg);
                }
            }
        }
        @if $variante == cross {
            &:after,
            &:before {
                transform-origin: center;
            }

            [gr-nav*='open'] & {
                &:before {
                    transform: translate3d(0, -50%, 0) rotate(135deg);
                }

                &:after {
                    transform: translate3d(-50%, -50%, 0) rotate(135deg);
                }
            }
        }
    }

    // Mixin / Arrow
    // --------------------------------------------------------------------------

    @if $type == arrow {
         @if not index(default cross, $variante){
            @error "Variant for arrow icon must be either `default` or `cross`.";
        }

        width: 1em;
        height: 1em;

        &:after,
        &:before {
            top: 50%;
            width: 80%;
            height: $bar;
        }

        &:before {
            left: 10%;
            transform: translate3D(-30%, -50%, 0) rotate(45deg);
        }

        &:after {
            right: 10%;
            transform: translate3D(30%, -50%, 0) rotate(-45deg);
        }
        @if $variante == default {
            &:after,
            &:before {
                transition: all .15s;
            }

            [gr-nav*='open'] & {
                &:before {
                    width: 80%;
                    transform: translate3d(-30%, -50%, 0) rotate(-45deg);
                }

                &:after {
                    width: 80%;
                    transform: translate3d(30%, -50%, 0) rotate(45deg);
                }
            }
        }
        @if $variante == cross {
            [gr-nav*='open'] & {
                &:before {
                    width: 100%;
                    transform: translate3d(-10%, -50%, 0) rotate(45deg);
                }

                &:after {
                    width: 100%;
                    transform: translate3d(10%, -50%, 0) rotate(-45deg);
                }
            }
        }
    }

    // Mixin / Search
    // --------------------------------------------------------------------------

    @if $type == search {
        width: 1em;
        height: 1em;
        transition: transform .2s;

        &:after,
        &:before {
            bottom: 0;
            right: .1em;
            width: .5em;
            height: $bar;
            transform: rotate(45deg);
            transform-origin: right bottom;
        }

        span {
            display: block;
            top: 0;
            left: 0;
            width: .75em;
            height: .75em;
            box-sizing: border-box;
            border: $bar solid $color;
            border-radius: 100%;
            background-color: transparent;
            transform: scale(1);
        }

        .gr-active &,
        [gr-search="active"] & {
            transform: rotate(90deg);

            &:after,
            &:before {
                bottom: 50%;
                right: 0;
                width: 100%;
                transform-origin: center;
            }

            &:before {
                transform: translate3d(0, 50%, 0) rotate(45deg);
            }

            &:after {
                transform: translate3d(0, 50%, 0) rotate(135deg);
            }

            span {
                transform: scale(0);
            }
        }
    }
}
