// ==========================================================================
// Pagination
// ==========================================================================

// <nav class="pagination">
//     <a href="#" class="pagination__btn--prev pagination__btn--disabled"></a>
//     <span class="pagination__link--current">1</span>
//     <a href="#" class="pagination__link">2</a>
//     <a href="#" class="pagination__link">3</a>
//     <span class="pagination__link--disabled">...</span>
//     <a href="#" class="pagination__link">20</a>
//     <a href="#" class="pagination__btn--next"></a>
// </nav>

@mixin pagination( $pagination-class: '.pagination') {
    #{$pagination-class} {
        &__btn--disabled,
        &__link--disabled {
            cursor: not-allowed;
            pointer-events: none;
            color: color(greys, light);
        }
    }
}
