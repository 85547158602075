:root {
	--fui-font: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
	--fui-font-size: 16px;
	--fui-color: #374151;
	--fui-primary-color: #516fea;
	--fui-primary-color-hover: #2349e5;
	--fui-error: #f56565;
	--fui-success: #00ae2a;
	--fui-gray-100: #f3f4f6;
	--fui-gray-200: #e5e7eb;
	--fui-gray-300: #d1d5db;
	--fui-gray-400: #9ca3af;
	--fui-gray-500: #6b7280;
	--fui-gray-600: #4b5563;
	--fui-gray-700: #374151;
	--fui-gray-800: #1f2937;
	--fui-gray-900: #111827;
	--fui-border: 0 solid #d1d5db;
	--fui-border-radius: 0.25rem;
	--fui-border-color: #d1d5db;
	--fui-focus-border-color: #a4cafe;
	--fui-focus-shadow: 0 0 0 3px rgba(164,202,254,.45);
	--fui-row-gutter: 1.25rem;
	--fui-row-gutter-top: 1.5rem;
	--fui-field-gutter: 0.5rem;
	--fui-loading-min-height: 1rem;
	--fui-loading-height: 1rem;
	--fui-loading-width: 1rem;
	--fui-loading-margin-top: -0.5rem;
	--fui-loading-margin-left: -0.5rem;
	--fui-loading-border-width: 2px;
	--fui-loading-animation: loading 0.5s infinite linear;
	--fui-loading-left: 50%;
	--fui-loading-top: calc(50% - 1px);
	--fui-loading-z-index: 1;
	--fui-alert-padding: 1rem;
	--fui-alert-line-height: 1.25rem;
	--fui-alert-font-size: 0.875rem;
	--fui-alert-font-weight: 500;
	--fui-alert-margin-bottom: 1rem;
	--fui-alert-error-bg-color: #fdf2f2;
	--fui-alert-error-color: #771d1d;
	--fui-alert-success-bg-color: #f3faf7;
	--fui-alert-success-color: #03543f;
	--fui-btn-font-size: 0.875rem;
	--fui-btn-line-height: 1.5;
	--fui-btn-display: inline-block;
	--fui-btn-text-align: center;
	--fui-btn-white-space: nowrap;
	--fui-btn-vertical-align: middle;
	--fui-btn-border-style: none;
	--fui-btn-text-decoration: none;
	--fui-btn-padding: 0.5rem 1rem;
	--fui-btn-border: 1px solid transparent;
	--fui-btn-font-weight: 500;
	--fui-btn-transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform;
	--fui-btn-transition-timing-function: cubic-bezier(0.4,0,0.2,1);
	--fui-btn-transition-duration: 0.15s;
	--fui-btn-opacity-disabled: 0.65;
	--fui-btn-container-padding: 1rem 0 0 0;
	--fui-btn-container-margin: 0 -0.5rem 0 -0.5rem;
	--fui-btn-margin: 0 0.5rem 0 0.5rem;
	--fui-link-transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform;
	--fui-link-transition-timing-function: cubic-bezier(0.4,0,0.2,1);
	--fui-link-transition-duration: 0.15s;
	--fui-link-text-decoration: underline;
	--fui-submit-btn-color: #fff;
	--fui-submit-btn-color-hover: #fff;
	--fui-submit-btn-spinner-color: #fff;
	--fui-title-margin: 0 0 1rem;
	--fui-title-font-size: 1.4rem;
	--fui-title-font-weight: 700;
	--fui-page-title-margin: 0 0 1rem;
	--fui-page-title-font-size: 1.125rem;
	--fui-page-title-font-weight: 600;
	--fui-tabs-margin-bottom: 1rem;
	--fui-tabs-font-size: 0.875rem;
	--fui-tab-padding: 0.5rem 1rem;
	--fui-tab-margin-bottom: -1px;
	--fui-tab-border: 1px solid transparent;
	--fui-tab-active-font-weight: 500;
	--fui-tab-active-bg-color: #fff;
	--fui-tab-active-border-bottom-color: transparent;
	--fui-progress-height: 1.2rem;
	--fui-progress-font-size: 0.8rem;
	--fui-progress-font-weight: 500;
	--fui-progress-color: #fff;
	--fui-progress-bar-transition: width 0.3s ease;
	--fui-error-font-size: 0.875rem;
	--fui-error-margin-top: 0.5rem;
	--fui-label-font-size: 0.875rem;
	--fui-label-line-height: 1.25;
	--fui-label-font-weight: 500;
	--fui-label-margin: 0.5rem;
	--fui-label-error-color: #771d1d;
	--fui-label-error-border-color: #f8b4b4;
	--fui-instructions-font-size: 0.875rem;
	--fui-instructions-line-height: 1.25;
	--fui-instructions-margin: 0.5rem;
	--fui-check-font-size: 0.875rem;
	--fui-check-line-height: 1.5;
	--fui-check-margin-bottom: 0.25rem;
	--fui-check-margin-right: 1rem;
	--fui-check-bg-color: #f0f1f4;
	--fui-check-label-padding-left: 1.8rem;
	--fui-check-label-line-height: 1.5rem;
	--fui-check-label-top: 5px;
	--fui-check-label-width: 1rem;
	--fui-check-label-height: 1rem;
	--fui-check-label-bg-color: #fff;
	--fui-check-label-transition: all 0.15s cubic-bezier(0.4,0,0.2,1);
	--fui-check-check-border-radius: 2px;
	--fui-check-check-bg-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
	--fui-check-check-bg-size: 8px auto;
	--fui-check-radio-border-radius: 50%;
	--fui-check-radio-bg-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
	--fui-check-radio-bg-size: 8px auto;
	--fui-group-padding: 1rem;
	--fui-input-font-size: 0.875rem;
	--fui-input-line-height: 1.5;
	--fui-input-width: 100%;
	--fui-input-padding: 0.5rem 0.75rem;
	--fui-input-background-color: #fff;
	--fui-input-transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform;
	--fui-input-transition-timing-function: cubic-bezier(0.4,0,0.2,1);
	--fui-input-transition-duration: 0.15s;
	--fui-input-error-color: #771d1d;
	--fui-input-error-border-color: #f8b4b4;
	--fui-input-error-box-shadow-focus: 0 0 0 3px hsla(0,83%,84%,.45);
	--fui-repeater-row-padding: 1rem;
	--fui-repeater-row-margin-bottom: 1rem;
	--fui-repeater-add-btn-padding-left: 2rem;
	--fui-repeater-add-btn-top: 0.75rem;
	--fui-repeater-add-btn-left: 0.75rem;
	--fui-repeater-add-btn-width: 14px;
	--fui-repeater-add-btn-height: 14px;
	--fui-repeater-add-btn-bg-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512'%3E%3Cpath fill='currentColor' d='M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z'/%3E%3C/svg%3E");
	--fui-repeater-remove-btn-top: 0;
	--fui-repeater-remove-btn-right: -14px;
	--fui-repeater-remove-btn-transform: translateY(-50%);
	--fui-repeater-remove-btn-border-radius: 50%;
	--fui-repeater-remove-btn-height: 0;
	--fui-repeater-remove-btn-width: 0;
	--fui-repeater-remove-btn-padding: 13px;
	--fui-repeater-remove-btn-text-indent: -9999px;
	--fui-repeater-remove-btn-icon-top: 50%;
	--fui-repeater-remove-btn-icon-left: 50%;
	--fui-repeater-remove-btn-icon-width: 9px;
	--fui-repeater-remove-btn-icon-height: 14px;
	--fui-repeater-remove-btn-icon-transform: translate(-50%,-50%);
	--fui-repeater-remove-btn-icon-bg-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='currentColor' d='m207.6 256 107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'/%3E%3C/svg%3E");
	--fui-select-option-padding: 0.1rem 0.4rem;
	--fui-select-padding-right: 2rem;
	--fui-select-bg-position: right 0.25rem center;
	--fui-select-bg-size: 1.5em 1.5em;
	--fui-select-bg-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='m7 7 3-3 3 3m0 6-3 3-3-3' stroke='%239fa6b2' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
	--fui-table-width: 100%;
	--fui-table-margin-bottom: 1rem;
	--fui-table-border-collapse: collapse;
	--fui-table-row-padding: 0.2rem;
	--fui-table-th-text-align: inherit;
	--fui-table-th-font-size: 0.75rem;
	--fui-table-th-font-weight: 600;
	--fui-table-add-btn-padding-left: 2rem;
	--fui-table-add-btn-top: 0.75rem;
	--fui-table-add-btn-left: 0.75rem;
	--fui-table-add-btn-width: 14px;
	--fui-table-add-btn-height: 14px;
	--fui-table-add-btn-bg-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512'%3E%3Cpath fill='currentColor' d='M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z'/%3E%3C/svg%3E");
	--fui-table-remove-btn-border-radius: 50%;
	--fui-table-remove-btn-padding: 13px;
	--fui-table-remove-btn-text-indent: -9999px;
	--fui-table-remove-btn-top: 50%;
	--fui-table-remove-btn-left: 50%;
	--fui-table-remove-btn-width: 9px;
	--fui-table-remove-btn-height: 14px;
	--fui-table-remove-btn-transform: translate(-50%,-50%);
	--fui-table-remove-btn-bg-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='currentColor' d='m207.6 256 107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'/%3E%3C/svg%3E");
	--fui-signature-width: 100%;
	--fui-signature-height: 8rem;
	--fui-signature-bg: #f9fafb;
	--fui-signature-remove-btn-top: 0;
	--fui-signature-remove-btn-right: -14px;
	--fui-signature-remove-btn-transform: translateY(-50%);
	--fui-signature-remove-btn-border-radius: 50%;
	--fui-signature-remove-btn-height: 0;
	--fui-signature-remove-btn-width: 0;
	--fui-signature-remove-btn-padding: 13px;
	--fui-signature-remove-btn-text-indent: -9999px;
	--fui-signature-remove-btn-icon-top: 50%;
	--fui-signature-remove-btn-icon-left: 50%;
	--fui-signature-remove-btn-icon-width: 9px;
	--fui-signature-remove-btn-icon-height: 14px;
	--fui-signature-remove-btn-icon-transform: translate(-50%,-50%);
	--fui-signature-remove-btn-icon-bg-image: url("data:image/svg+xml;charset=utf-8,%3Csvg aria-hidden='true' data-prefix='far' data-icon='times' class='svg-inline--fa fa-times fa-w-10' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='currentColor' d='m207.6 256 107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'/%3E%3C/svg%3E");
	--fui-summary-padding: 1rem
}

.fui-hidden {
	display: none !important;
}


.fui-sr-only,[data-fui-sr-only] {
	clip: rect(0,0,0,0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	position: absolute;
	width: 1px
}

.fui-field {
	flex: 1
}

.fui-field[data-conditionally-hidden] {
	display: none
}

.fui-label-left-input .fui-field-container,.fui-label-right-input .fui-field-container {
	align-items: baseline;
	display: flex
}

.fui-label-left-input .fui-input-container,.fui-label-right-input .fui-input-container {
	flex: 1
}

.fui-label-below-input>.fui-field-container>.fui-input-container>.fui-fieldset>.fui-legend {
	clear: both;
	display: block;
	float: left;
	width: 100%
}

.fui-layout-horizontal .fui-layout-wrap,.fui-row {
	display: flex;
	flex-wrap: wrap
}

[data-fui-page-hidden] {
	display: none
}

.fui-btn-container,.fui-btn-wrapper {
	display: flex;
	justify-content: flex-start
}

.fui-btn-container.fui-btn-right,.fui-btn-wrapper.fui-btn-right {
	justify-content: flex-end
}

.fui-btn-container.fui-btn-center,.fui-btn-container.fui-btn-center-save-left,.fui-btn-container.fui-btn-center-save-right,.fui-btn-wrapper.fui-btn-center,.fui-btn-wrapper.fui-btn-center-save-left,.fui-btn-wrapper.fui-btn-center-save-right {
	justify-content: center
}

.fui-btn-container.fui-btn-left-right,.fui-btn-container.fui-btn-save-left,.fui-btn-container.fui-btn-save-right,.fui-btn-wrapper.fui-btn-left-right,.fui-btn-wrapper.fui-btn-save-left,.fui-btn-wrapper.fui-btn-save-right {
	justify-content: space-between
}

.fui-btn-container.fui-btn-right-save-left,.fui-btn-wrapper.fui-btn-right-save-left {
	justify-content: flex-start
}

.fui-btn-container.fui-btn-center-save-left,.fui-btn-container.fui-btn-right-save-left,.fui-btn-container.fui-btn-save-left,.fui-btn-wrapper.fui-btn-center-save-left,.fui-btn-wrapper.fui-btn-right-save-left,.fui-btn-wrapper.fui-btn-save-left {
	flex-direction: row-reverse
}

.fui-btn-container .fui-btn[data-conditionally-hidden],.fui-btn-wrapper .fui-btn[data-conditionally-hidden] {
	display: none
}

.fui-save,.fui-submit {
	order: 10
}

.fui-btn * {
	pointer-events: none
}

.fui-i {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #374151;
	color: var(--fui-color,var(--fui-gray-700));
	font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
	font-family: var(--fui-font);
	font-size: 16px;
	font-size: var(--fui-font-size);
	margin: 0;
	padding: 0
}

.fui-i *,.fui-i :after,.fui-i :before {
	border: 0 solid #d1d5db;
	border: var(--fui-border);
	box-sizing: border-box
}

.fui-btn {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-color: #f3f4f6;
	background-color: var(--fui-btn-bg-color,var(--fui-gray-100));
	border: 1px solid transparent;
	border: var(--fui-btn-border);
	border-radius: .25rem;
	border-radius: var(--fui-btn-border-radius,var(--fui-border-radius));
	display: inline-block;
	display: var(--fui-btn-display);
	font-family: inherit;
	font-size: .875rem;
	font-size: var(--fui-btn-font-size);
	font-weight: 500;
	font-weight: var(--fui-btn-font-weight);
	line-height: 1.5;
	line-height: var(--fui-btn-line-height);
	margin: 0;
	padding: .5rem 1rem;
	padding: var(--fui-btn-padding);
	text-align: center;
	text-align: var(--fui-btn-text-align);
	text-decoration: none;
	-webkit-text-decoration: var(--fui-btn-text-decoration);
	text-decoration: var(--fui-btn-text-decoration);
	transition-duration: .15s;
	transition-duration: var(--fui-btn-transition-duration);
	transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform;
	transition-property: var(--fui-btn-transition-property);
	transition-timing-function: cubic-bezier(.4,0,.2,1);
	transition-timing-function: var(--fui-btn-transition-timing-function);
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	vertical-align: middle;
	vertical-align: var(--fui-btn-vertical-align);
	white-space: nowrap;
	white-space: var(--fui-btn-white-space)
}

.fui-btn:active,.fui-btn:focus,.fui-btn:hover {
	background-color: #e5e7eb;
	background-color: var(--fui-btn-bg-color-hover,var(--fui-gray-200));
	outline: 0;
	text-decoration: none
}

.fui-btn.disabled,.fui-btn:disabled {
	opacity: .65;
	opacity: var(--fui-btn-opacity-disabled);
	pointer-events: none
}

.fui-btn:not(:disabled):not(.disabled) {
	cursor: pointer
}

.fui-btn-link {
	background-color: transparent;
	color: #516fea;
	color: var(--fui-submit-btn-bg-color,var(--fui-primary-color))
}

.fui-btn-link:active,.fui-btn-link:focus,.fui-btn-link:hover, .fui-underline {
	background-color: transparent;
	text-decoration: underline
}

.fui-link {
	color: #516fea;
	color: var(--fui-link-color,var(--fui-primary-color));
	text-decoration: underline;
	-webkit-text-decoration: var(--fui-link-text-decoration);
	text-decoration: var(--fui-link-text-decoration);
	transition-duration: .15s;
	transition-duration: var(--fui-link-transition-duration);
	transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform;
	transition-property: var(--fui-link-transition-property);
	transition-timing-function: cubic-bezier(.4,0,.2,1);
	transition-timing-function: var(--fui-link-transition-timing-function)
}

.fui-link:active,.fui-link:focus,.fui-link:hover {
	color: #2349e5;
	color: var(--fui-link-color-hover,var(--fui-primary-color-hover))
}

.fui-loading {
	color: transparent!important;
	min-height: 1rem;
	min-height: var(--fui-loading-min-height);
	pointer-events: none;
	position: relative
}

.fui-loading:after {
	animation: loading .5s linear infinite;
	animation: var(--fui-loading-animation);
	border-color: #516fea;
	border-color: var(--fui-loading-border-color,var(--fui-primary-color));
	border-radius: 9999px;
	border-right-color: transparent;
	border-style: solid;
	border-top-color: transparent;
	border-width: 2px;
	border-width: var(--fui-loading-border-width);
	content: "";
	display: block;
	height: 1rem;
	height: var(--fui-loading-height);
	left: 50%;
	left: var(--fui-loading-left);
	margin-left: -.5rem;
	margin-left: var(--fui-loading-margin-left);
	margin-top: -.5rem;
	margin-top: var(--fui-loading-margin-top);
	position: absolute;
	top: calc(50% - 1px);
	top: var(--fui-loading-top);
	width: 1rem;
	width: var(--fui-loading-width);
	z-index: 1;
	z-index: var(--fui-loading-z-index)
}

@keyframes loading {
	0% {
			transform: rotate(0)
	}

	to {
			transform: rotate(1turn)
	}
}

.fui-alert {
	border-radius: .25rem;
	border-radius: var(--fui-alert-border-radius,var(--fui-border-radius));
	font-size: .875rem;
	font-size: var(--fui-alert-font-size);
	font-weight: 500;
	font-weight: var(--fui-alert-font-weight);
	line-height: 1.25rem;
	line-height: var(--fui-alert-line-height);
	margin-bottom: 1rem;
	margin-bottom: var(--fui-alert-margin-bottom);
	padding: 1rem;
	padding: var(--fui-alert-padding)
}

.fui-alert-error {
	background-color: #fdf2f2;
	background-color: var(--fui-alert-error-bg-color);
	color: #771d1d;
	color: var(--fui-alert-error-color)
}

.fui-alert-success {
	background-color: #f3faf7;
	background-color: var(--fui-alert-success-bg-color);
	color: #03543f;
	color: var(--fui-alert-success-color)
}

.fui-alert-error.fui-alert-bottom-form {
	margin-bottom: 0;
	margin-top: 1rem
}

.fui-btn-wrapper {
	margin: 0 -.5rem;
	margin: var(--fui-btn-container-margin);
	padding: 1rem 0 0;
	padding: var(--fui-btn-container-padding)
}

.fui-btn-wrapper .fui-btn {
	margin: 0 .5rem;
	margin: var(--fui-btn-margin)
}

.fui-submit {
	background-color: #516fea;
	background-color: var(--fui-submit-btn-bg-color,var(--fui-primary-color));
	border-color: #516fea;
	border-color: var(--fui-submit-btn-border-color,var(--fui-primary-color));
	color: #fff;
	color: var(--fui-submit-btn-color)
}

.fui-submit.fui-loading:after {
	border-bottom-color: #fff;
	border-bottom-color: var(--fui-submit-btn-spinner-color);
	border-left-color: #fff;
	border-left-color: var(--fui-submit-btn-spinner-color)
}

.fui-submit:active,.fui-submit:focus,.fui-submit:hover {
	background-color: #2349e5;
	background-color: var(--fui-submit-btn-bg-color-hover,var(--fui-primary-color-hover));
	border-color: #2349e5;
	border-color: var(--fui-submit-btn-border-color-hover,var(--fui-primary-color-hover));
	color: #fff;
	color: var(--fui-submit-btn-color-hover)
}

.fui-submit:active,.fui-submit:focus {
	box-shadow: 0 0 0 3px rgba(164,202,254,.45);
	box-shadow: var(--fui-submit-btn-box-shadow-focus,var(--fui-focus-shadow))
}

.fui-page:not(.fui-hidden)+.fui-page {
	margin-top: 1.5rem;
	margin-top: var(--fui-row-gutter-top)
}

.fui-field-rows {
	margin-bottom: -1.25rem;
	margin-bottom: calc(var(--fui-row-gutter)*-1)
}

.fui-row {
	margin-left: -.5rem;
	margin-left: calc(var(--fui-field-gutter)*-1);
	margin-right: -.5rem;
	margin-right: calc(var(--fui-field-gutter)*-1)
}

.fui-title {
	font-size: 1.4rem;
	font-size: var(--fui-title-font-size);
	font-weight: 700;
	font-weight: var(--fui-title-font-weight);
	margin: 0 0 1rem;
	margin: var(--fui-title-margin);
	padding: 0
}

.fui-page-title {
	font-size: 1.125rem;
	font-size: var(--fui-page-title-font-size);
	font-weight: 600;
	font-weight: var(--fui-page-title-font-weight);
	margin: 0 0 1rem;
	margin: var(--fui-page-title-margin);
	padding: 0
}

.fui-tabs {
	border-bottom: 1px solid #d1d5db;
	border-bottom: var(--fui-tabs-border-bottom,1px solid var(--fui-border-color));
	display: flex;
	flex-wrap: wrap;
	font-size: .875rem;
	font-size: var(--fui-tabs-font-size);
	margin-bottom: 1rem;
	margin-bottom: var(--fui-tabs-margin-bottom)
}

.fui-tab {
	border: 1px solid transparent;
	border: var(--fui-tab-border);
	color: #6b7280;
	color: var(--fui-tab-color,var(--fui-gray-500));
	margin-bottom: -1px;
	margin-bottom: var(--fui-tab-margin-bottom);
	padding: .5rem 1rem;
	padding: var(--fui-tab-padding)
}

.fui-tab a {
	color: inherit;
	text-decoration: none
}

.fui-tab[data-conditionally-hidden] {
	display: none
}

.fui-tab-active {
	background-color: #fff;
	background-color: var(--fui-tab-active-bg-color);
	border-color: #d1d5db;
	border-color: var(--fui-tab-active-border-color,var(--fui-border-color));
	border-bottom-color: transparent;
	border-bottom-color: var(--fui-tab-active-border-bottom-color);
	border-radius: .25rem .25rem 0 0;
	border-radius: var(--fui-tab-active-border-radius,var(--fui-border-radius) var(--fui-border-radius) 0 0);
	color: #374151;
	color: var(--fui-tab-active-color,var(--fui-gray-700));
	font-weight: 500;
	font-weight: var(--fui-tab-active-font-weight)
}

.fui-tab-error {
	color: #f56565;
	color: var(--fui-tab-error-color,var(--fui-error))
}

.fui-progress {
	background-color: #e5e7eb;
	background-color: var(--fui-progress-bg-color,var(--fui-gray-200));
	border-radius: .25rem;
	border-radius: var(--fui-progress-border-radius,var(--fui-border-radius));
	color: #fff;
	color: var(--fui-progress-color);
	display: flex;
	font-size: .8rem;
	font-size: var(--fui-progress-font-size);
	font-weight: 500;
	font-weight: var(--fui-progress-font-weight);
	height: 1.2rem;
	height: var(--fui-progress-height);
	overflow: hidden
}

.fui-progress-start .fui-progress {
	margin-bottom: 1rem
}

.fui-progress-end .fui-progress {
	margin-top: 1rem
}

.fui-progress-bar {
	background-color: #516fea;
	background-color: var(--fui-progress-bar-bg-color,var(--fui-primary-color));
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	transition: width .3s ease;
	transition: var(--fui-progress-bar-transition)
}

.fui-field {
	padding-left: .5rem;
	padding-left: var(--fui-field-gutter);
	padding-right: .5rem;
	padding-right: var(--fui-field-gutter)
}

.fui-field:not(.fui-type-hidden-field) {
	margin-bottom: 1.25rem;
	margin-bottom: var(--fui-row-gutter)
}

.fui-label-left-input .fui-input-container {
	margin-left: .5rem;
	margin-left: var(--fui-field-gutter)
}

.fui-label-right-input .fui-input-container {
	margin-right: .5rem;
	margin-right: var(--fui-field-gutter)
}

.fui-label,.fui-legend {
	color: #374151;
	color: var(--fui-label-color,var(--fui-gray-700));
	display: block;
	font-size: .875rem;
	font-size: var(--fui-label-font-size);
	font-weight: 500;
	font-weight: var(--fui-label-font-weight);
	line-height: 1.25;
	line-height: var(--fui-label-line-height);
	padding: 0
}

.fui-legend.large {
	font-size: var(--fui-font-size) ;
}

.fui-label-above-input .fui-label,.fui-label-above-input .fui-legend,.fui-subfield-label-above-input .fui-label,.fui-subfield-label-above-input .fui-legend {
	margin-bottom: .5rem;
	margin-bottom: var(--fui-label-margin)
}

.fui-label-below-input .fui-label,.fui-label-below-input .fui-legend,.fui-subfield-label-below-input .fui-label,.fui-subfield-label-below-input .fui-legend {
	margin-top: .5rem;
	margin-top: var(--fui-label-margin)
}

.fui-error .fui-label,.fui-error .fui-legend {
	border-color: #f8b4b4;
	border-color: var(--fui-label-error-border-color);
	color: #771d1d;
	color: var(--fui-label-error-color)
}

.fui-required {
	color: #f56565;
	color: var(--fui-required-color,var(--fui-error))
}

.fui-file-summary,.fui-instructions,.fui-limit-text {
	color: #9ca3af;
	color: var(--fui-instructions-color,var(--fui-gray-400));
	display: block;
	font-size: .875rem;
	font-size: var(--fui-instructions-font-size);
	line-height: 1.25;
	line-height: var(--fui-instructions-line-height)
}

.fui-file-summary p,.fui-file-summary ul,.fui-instructions p,.fui-instructions ul,.fui-limit-text p,.fui-limit-text ul {
	margin: 0
}

.fui-instructions-above-input .fui-instructions {
	margin-bottom: .5rem;
	margin-bottom: var(--fui-instructions-margin)
}

.fui-instructions-below-input .fui-instructions,.fui-limit-text {
	margin-top: .5rem;
	margin-top: var(--fui-instructions-margin)
}

.fui-limit-text .fui-limit-number.fui-limit-number-error {
	color: #f56565;
	color: var(--fui-required-color,var(--fui-error))
}

.fui-file-summary>p {
	margin-bottom: .5rem;
	margin-bottom: var(--fui-instructions-margin);
	margin-top: .5rem;
	margin-top: var(--fui-instructions-margin)
}

.fui-file-summary ul {
	padding-left: 1.75rem
}

.fui-file-summary li {
	list-style: disc
}

.fui-fieldset {
	border: 0;
	margin: 0;
	padding: 0
}

.formie-recaptcha-placeholder>div>* {
	margin-top: 1rem
}

.formie-friendly-captcha-placeholder {
	padding-bottom: 12px;
	position: relative
}

.fui-errors {
	margin: 0;
	padding: 0
}

.fui-error-message {
	color: #f56565;
	color: var(--fui-error-color,var(--fui-error))
}

.fui-error-message,.fui-success-message {
	display: block;
	font-size: .875rem;
	font-size: var(--fui-error-font-size);
	margin-top: .5rem;
	margin-top: var(--fui-error-margin-top)
}

.fui-success-message {
	color: #00ae2a;
	color: var(--fui-success-color,var(--fui-success))
}

.fui-address-location-link {
	float: right
}

.fui-checkbox,.fui-radio {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	font-family: inherit;
	font-size: .875rem;
	font-size: var(--fui-check-font-size);
	line-height: 1.5;
	line-height: var(--fui-check-line-height);
	margin: 0;
	position: relative;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none
}

.fui-checkbox:not(:last-child),.fui-radio:not(:last-child) {
	margin-bottom: .25rem;
	margin-bottom: var(--fui-check-margin-bottom)
}

.fui-layout-horizontal .fui-layout-wrap>.fui-checkbox,.fui-layout-horizontal .fui-layout-wrap>.fui-radio {
	margin-right: 1rem;
	margin-right: var(--fui-check-margin-right)
}

.fui-checkbox input,.fui-radio input {
	clip: rect(0,0,0,0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	position: absolute;
	width: 1px
}

.fui-checkbox input:focus+label:before,.fui-radio input:focus+label:before {
	border-color: #a4cafe;
	border-color: var(--fui-check-border-color-focus,var(--fui-focus-border-color));
	box-shadow: 0 0 0 3px rgba(164,202,254,.45);
	box-shadow: var(--fui-check-box-shadow-focus,var(--fui-focus-shadow))
}

.fui-checkbox input:checked+label:before,.fui-radio input:checked+label:before {
	background-color: #516fea;
	background-color: var(--fui-check-bg-color-checked,var(--fui-primary-color));
	border-color: #516fea;
	border-color: var(--fui-check-border-color-checked,var(--fui-primary-color))
}

.fui-checkbox input:active+label:before,.fui-radio input:active+label:before {
	background-color: #f0f1f4;
	background-color: var(--fui-check-bg-color)
}

.fui-checkbox label,.fui-radio label {
	display: inline-block;
	line-height: 1.5rem;
	line-height: var(--fui-check-label-line-height);
	padding-left: 1.8rem;
	padding-left: var(--fui-check-label-padding-left);
	position: relative
}

.fui-checkbox label,.fui-checkbox label:before,.fui-radio label,.fui-radio label:before {
	transition: all .15s cubic-bezier(.4,0,.2,1);
	transition: var(--fui-check-label-transition)
}

.fui-checkbox label:before,.fui-radio label:before {
	background-color: #fff;
	background-color: var(--fui-check-label-bg-color);
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 50% 50%;
	border: 1px solid #d1d5db;
	border: var(--fui-check-label-border,1px solid var(--fui-border-color));
	content: "";
	cursor: pointer;
	display: block;
	height: 1rem;
	height: var(--fui-check-label-height);
	left: 0;
	position: absolute;
	top: 5px;
	top: var(--fui-check-label-top);
	width: 1rem;
	width: var(--fui-check-label-width)
}

.fui-checkbox label:before {
	border-radius: 2px;
	border-radius: var(--fui-check-check-border-radius)
}

.fui-checkbox input:checked+label:before {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
	background-image: var(--fui-check-check-bg-image);
	background-size: 8px auto;
	background-size: var(--fui-check-check-bg-size)
}

.fui-radio label:before {
	border-radius: 50%;
	border-radius: var(--fui-check-radio-border-radius)
}

.fui-radio input:checked+label:before {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
	background-image: var(--fui-check-radio-bg-image);
	background-size: 8px auto;
	background-size: var(--fui-check-radio-bg-size)
}

.fui-input,.fui-select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-color: #fff;
	background-color: var(--fui-input-background-color);
	border: 1px solid #d1d5db;
	border: var(--fui-input-border,1px solid var(--fui-border-color));
	border-radius: .25rem;
	border-radius: var(--fui-input-border-radius,var(--fui-border-radius));
	display: block;
	font-family: inherit;
	font-size: .875rem;
	font-size: var(--fui-input-font-size);
	line-height: 1.5;
	line-height: var(--fui-input-line-height);
	margin: 0;
	padding: .5rem .75rem;
	padding: var(--fui-input-padding);
	transition-duration: .15s;
	transition-duration: var(--fui-input-transition-duration);
	transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform;
	transition-property: var(--fui-input-transition-property);
	transition-timing-function: cubic-bezier(.4,0,.2,1);
	transition-timing-function: var(--fui-input-transition-timing-function);
	width: 100%;
	width: var(--fui-input-width)
}

.fui-input.fui-focus,.fui-input:focus,.fui-select.fui-focus,.fui-select:focus {
	border-color: #a4cafe;
	border-color: var(--fui-input-border-color-focus,var(--fui-focus-border-color));
	box-shadow: 0 0 0 3px rgba(164,202,254,.45);
	box-shadow: var(--fui-input-box-shadow-focus,var(--fui-focus-shadow));
	outline: 0
}

.fui-input.fui-error,.fui-select.fui-error {
	border-color: #f8b4b4;
	border-color: var(--fui-input-error-border-color);
	color: #771d1d;
	color: var(--fui-input-error-color)
}

.fui-input.fui-error.fui-focus,.fui-input.fui-error:focus,.fui-select.fui-error.fui-focus,.fui-select.fui-error:focus {
	box-shadow: 0 0 0 3px hsla(0,83%,84%,.45);
	box-shadow: var(--fui-input-error-box-shadow-focus)
}

.fui-input::-moz-placeholder,.fui-select::-moz-placeholder {
	color: #9ca3af;
	color: var(--fui-input-placeholder-color,var(--fui-gray-400))
}

.fui-input:-ms-input-placeholder,.fui-select:-ms-input-placeholder {
	color: #9ca3af;
	color: var(--fui-input-placeholder-color,var(--fui-gray-400))
}

.fui-input::placeholder,.fui-select::placeholder {
	color: #9ca3af;
	color: var(--fui-input-placeholder-color,var(--fui-gray-400))
}

.fui-input[type=file],.fui-select[type=file] {
	height: auto
}

.fui-input[type=color],.fui-select[type=color] {
	height: 39px;
	padding: .1rem
}

@media (max-width: 639px) {
	.fui-input,.fui-select {
			font-size:16px
	}
}

.fui-name-prefix {
	flex: 0 0 auto
}

.fui-repeater-row {
	border: 1px solid #d1d5db;
	border: var(--fui-repeater-row-border,1px solid var(--fui-border-color));
	border-radius: .25rem;
	border-radius: var(--fui-repeater-row-border-radius,var(--fui-border-radius));
	margin-bottom: 1rem;
	margin-bottom: var(--fui-repeater-row-margin-bottom);
	padding: 1rem;
	padding: var(--fui-repeater-row-padding);
	position: relative
}

.fui-repeater-add-btn {
	padding-left: 2rem;
	padding-left: var(--fui-repeater-add-btn-padding-left);
	position: relative
}

.fui-repeater-add-btn:after {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512'%3E%3Cpath fill='currentColor' d='M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z'/%3E%3C/svg%3E");
	background-image: var(--fui-repeater-add-btn-bg-image);
	background-repeat: no-repeat;
	content: "";
	display: block;
	height: 14px;
	height: var(--fui-repeater-add-btn-height);
	left: .75rem;
	left: var(--fui-repeater-add-btn-left);
	position: absolute;
	top: .75rem;
	top: var(--fui-repeater-add-btn-top);
	width: 14px;
	width: var(--fui-repeater-add-btn-width)
}

.fui-repeater-remove-btn {
	border: 1px solid #d1d5db;
	border: var(--fui-repeater-remove-btn-border,1px solid var(--fui-border-color));
	border-radius: 50%;
	border-radius: var(--fui-repeater-remove-btn-border-radius);
	height: 0;
	height: var(--fui-repeater-remove-btn-height);
	padding: 13px;
	padding: var(--fui-repeater-remove-btn-padding);
	position: absolute;
	right: -14px;
	right: var(--fui-repeater-remove-btn-right);
	text-indent: -9999px;
	text-indent: var(--fui-repeater-remove-btn-text-indent);
	top: 0;
	top: var(--fui-repeater-remove-btn-top);
	transform: translateY(-50%);
	transform: var(--fui-repeater-remove-btn-transform);
	width: 0;
	width: var(--fui-repeater-remove-btn-width)
}

.fui-repeater-remove-btn:after {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='currentColor' d='m207.6 256 107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'/%3E%3C/svg%3E");
	background-image: var(--fui-repeater-remove-btn-icon-bg-image);
	background-repeat: no-repeat;
	content: "";
	display: block;
	height: 14px;
	height: var(--fui-repeater-remove-btn-icon-height);
	left: 50%;
	left: var(--fui-repeater-remove-btn-icon-left);
	position: absolute;
	top: 50%;
	top: var(--fui-repeater-remove-btn-icon-top);
	transform: translate(-50%,-50%);
	transform: var(--fui-repeater-remove-btn-icon-transform);
	width: 9px;
	width: var(--fui-repeater-remove-btn-icon-width)
}

.fui-hr {
	border: 0;
	width: 100%
}

.fui-select[multiple],.fui-select[size] {
	height: auto
}

.fui-select[multiple] option,.fui-select[size] option {
	padding: .1rem .4rem;
	padding: var(--fui-select-option-padding)
}

.fui-select:not([multiple]):not([size]) {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='m7 7 3-3 3 3m0 6-3 3-3-3' stroke='%239fa6b2' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
	background-image: var(--fui-select-bg-image);
	background-position: right .25rem center;
	background-position: var(--fui-select-bg-position);
	background-repeat: no-repeat;
	background-size: 1.5em 1.5em;
	background-size: var(--fui-select-bg-size);
	padding-right: 2rem;
	padding-right: var(--fui-select-padding-right)
}

.fui-select::-ms-expand {
	display: none
}

.fui-table {
	border-collapse: collapse;
	border-collapse: var(--fui-table-border-collapse);
	margin-bottom: 1rem;
	margin-bottom: var(--fui-table-margin-bottom);
	width: 100%;
	width: var(--fui-table-width)
}

.fui-table th {
	color: #6b7280;
	color: var(--fui-table-th-color,var(--fui-gray-500));
	font-size: .75rem;
	font-size: var(--fui-table-th-font-size);
	font-weight: 600;
	font-weight: var(--fui-table-th-font-weight);
	text-align: inherit;
	text-align: var(--fui-table-th-text-align)
}

.fui-table td,.fui-table th {
	padding: .2rem;
	padding: var(--fui-table-row-padding)
}

.fui-table td:first-child,.fui-table th:first-child {
	padding-left: 0
}

.fui-table td:last-child,.fui-table th:last-child {
	padding-right: 0
}

.fui-table [data-col-remove] {
	width: 1px
}

.fui-table-add-btn {
	padding-left: 2rem;
	padding-left: var(--fui-table-add-btn-padding-left);
	position: relative
}

.fui-table-add-btn:after {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512'%3E%3Cpath fill='currentColor' d='M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z'/%3E%3C/svg%3E");
	background-image: var(--fui-table-add-btn-bg-image);
	background-repeat: no-repeat;
	content: "";
	display: block;
	height: 14px;
	height: var(--fui-table-add-btn-height);
	left: .75rem;
	left: var(--fui-table-add-btn-left);
	position: absolute;
	top: .75rem;
	top: var(--fui-table-add-btn-top);
	width: 14px;
	width: var(--fui-table-add-btn-width)
}

.fui-table-remove-btn {
	border: 1px solid #d1d5db;
	border: var(--fui-table-remove-btn-border,1px solid var(--fui-border-color));
	border-radius: 50%;
	border-radius: var(--fui-table-remove-btn-border-radius);
	height: 0;
	padding: 13px;
	padding: var(--fui-table-remove-btn-padding);
	position: relative;
	text-indent: -9999px;
	text-indent: var(--fui-table-remove-btn-text-indent);
	width: 0
}

.fui-table-remove-btn:after {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='currentColor' d='m207.6 256 107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'/%3E%3C/svg%3E");
	background-image: var(--fui-table-remove-btn-bg-image);
	background-repeat: no-repeat;
	content: "";
	display: block;
	height: 14px;
	height: var(--fui-table-remove-btn-height);
	left: 50%;
	left: var(--fui-table-remove-btn-left);
	position: absolute;
	top: 50%;
	top: var(--fui-table-remove-btn-top);
	transform: translate(-50%,-50%);
	transform: var(--fui-table-remove-btn-transform);
	width: 9px;
	width: var(--fui-table-remove-btn-width)
}

.fui-group {
	border: 1px solid #d1d5db;
	border: var(--fui-group-border,1px solid var(--fui-border-color));
	border-radius: .25rem;
	border-radius: var(--fui-group-border-radius,var(--fui-border-radius));
	padding: 1rem;
	padding: var(--fui-group-padding)
}

.fui-rich-text {
	position: relative
}

.fui-rich-text-toolbar {
	align-items: center;
	border-bottom: 1px solid rgba(49,49,93,.15);
	border-radius: 3px 3px 0 0;
	box-shadow: 0 2px 3px 0 rgba(49,49,93,.075);
	display: flex;
	flex-wrap: wrap;
	left: 0;
	padding: 4px 8px;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 1
}

.fui-rich-text-content {
	min-height: 10rem;
	padding-top: 50px
}

.fui-rich-text-content p {
	margin: 0 0 1rem
}

.fui-rich-text-button {
	align-items: center;
	background: transparent;
	border-radius: 3px;
	color: #1c2e36;
	cursor: pointer;
	display: flex;
	font-size: 16px;
	height: 32px;
	justify-content: center;
	margin: 0 2px;
	padding-left: 4px;
	padding-right: 4px;
	text-align: center;
	width: 32px
}

.fui-rich-text-button.fui-rich-text-selected,.fui-rich-text-button:hover {
	background-color: #edf3fa
}

.fui-rich-text-content ol,.fui-rich-text-content ul {
	list-style-type: revert;
	margin: revert;
	padding: revert
}

.fui-rich-text-content h1,.fui-rich-text-content h2,.fui-rich-text-content h3,.fui-rich-text-content h4,.fui-rich-text-content h5,.fui-rich-text-content h6 {
	font-size: revert;
	font-weight: revert
}

.fui-rich-text-content blockquote,.fui-rich-text-content dd,.fui-rich-text-content dl,.fui-rich-text-content figure,.fui-rich-text-content h1,.fui-rich-text-content h2,.fui-rich-text-content h3,.fui-rich-text-content h4,.fui-rich-text-content h5,.fui-rich-text-content h6,.fui-rich-text-content hr,.fui-rich-text-content pre {
	margin: revert
}

.fui-rich-text-content a {
	color: revert;
	-webkit-text-decoration: revert;
	text-decoration: revert
}

.fui-rich-text-content hr {
	border-style: inset;
	border-width: 1px;
	height: revert;
	overflow: revert
}

.fui-rich-text-content blockquote {
	border-left: .25em solid #dfe2e5;
	color: #6a737d;
	margin: 0 0 1rem;
	padding: 0 1em
}

.fui-rich-text-content pre {
	background: #f4f5f7;
	border-radius: 4px;
	padding: 1rem
}

.fui-rich-text-content[data-placeholder]:empty:before {
	content: attr(data-placeholder)
}

.fui-summary-blocks {
	border: 1px solid #d1d5db;
	border: var(--fui-summary-border,1px solid var(--fui-border-color));
	border-radius: .25rem;
	border-radius: var(--fui-summary-border-radius,var(--fui-border-radius));
	padding: 1rem;
	padding: var(--fui-summary-padding);
	position: relative
}

.fui-summary-block {
	margin-bottom: 1rem
}

.fui-summary-block:last-child {
	margin-bottom: 0
}

.fui-type-signature .fui-input-container {
	position: relative
}

.fui-type-signature .fui-input-container canvas {
	background: #f9fafb;
	background: var(--fui-signature-bg);
	border: 1px solid #d1d5db;
	border: var(--fui-signature-border,1px solid var(--fui-border-color));
	border-radius: .25rem;
	border-radius: var(--fui-signature-border-radius,var(--fui-border-radius));
	height: 8rem;
	height: var(--fui-signature-height);
	width: 100%;
	width: var(--fui-signature-width)
}

.fui-type-signature .fui-input-container .fui-signature-clear-btn {
	border: 1px solid #d1d5db;
	border: var(--fui-signature-remove-btn-border,1px solid var(--fui-border-color));
	border-radius: 50%;
	border-radius: var(--fui-signature-remove-btn-border-radius);
	height: 0;
	height: var(--fui-signature-remove-btn-height);
	padding: 13px;
	padding: var(--fui-signature-remove-btn-padding);
	position: absolute;
	right: -14px;
	right: var(--fui-signature-remove-btn-right);
	text-indent: -9999px;
	text-indent: var(--fui-signature-remove-btn-text-indent);
	top: 0;
	top: var(--fui-signature-remove-btn-top);
	transform: translateY(-50%);
	transform: var(--fui-signature-remove-btn-transform);
	width: 0;
	width: var(--fui-signature-remove-btn-width)
}

.fui-type-signature .fui-input-container .fui-signature-clear-btn:after {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg aria-hidden='true' data-prefix='far' data-icon='times' class='svg-inline--fa fa-times fa-w-10' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='currentColor' d='m207.6 256 107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'/%3E%3C/svg%3E");
	background-image: var(--fui-signature-remove-btn-icon-bg-image);
	background-repeat: no-repeat;
	content: "";
	display: block;
	height: 14px;
	height: var(--fui-signature-remove-btn-icon-height);
	left: 50%;
	left: var(--fui-signature-remove-btn-icon-left);
	position: absolute;
	top: 50%;
	top: var(--fui-signature-remove-btn-icon-top);
	transform: translate(-50%,-50%);
	transform: var(--fui-signature-remove-btn-icon-transform);
	width: 9px;
	width: var(--fui-signature-remove-btn-icon-width)
}

.fui-modal {
	align-items: center;
	bottom: 0;
	display: flex;
	left: 0;
	opacity: 0;
	overflow-y: auto;
	position: fixed;
	right: 0;
	top: 0;
	transition: opacity .1s linear,visibility .1s linear 0s;
	visibility: hidden;
	will-change: opacity;
	z-index: 10000
}

.fui-modal.is-open {
	opacity: 1;
	transition: opacity .1s linear,visibility 0s linear;
	visibility: visible
}

.fui-modal-backdrop {
	background-color: rgba(0,0,0,.6);
	bottom: 0;
	left: 0;
	opacity: 1;
	position: fixed;
	right: 0;
	top: 0
}

.fui-modal-content {
	background: #fff;
	border-radius: 4px;
	height: 90%;
	margin: auto;
	max-height: 40rem;
	max-width: 50rem;
	padding: 1rem;
	position: relative;
	width: 90%;
	z-index: 1
}

.fui-table-row.odd {
	background-color: #f3f4f6;
}

.fui-note-text {
	font-size: 13px;
}

.fui-note-text ul{
	padding-left: 1.75rem
}
