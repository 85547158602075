@mixin form-validation() {

    // Parsley
    // --------------------------------------------------

    .parsley-error {
        :not(label),
        .file-upload-wrapper label {
            color: color(validation, error);
            border-color: color(validation, error) !important;
        }

        label::before,
        label::after {
            border-color: color(validation, error) !important;
        }
    }

    .parsley-errors-list {
        list-style: none;
        margin-top: .5em;
        flex: 1 1 100%;
        order: 2;

        .form-item & {
            float: left;
        }
    }
}
