// Sliders
// --------------------------------------------------------------------------

div.slider .slick-dots {
    //to validate
    //bottom: calc(5rem + 25px);
}


.slick-slide {
    position: relative;
    // height: 100%;
    // min-height: 450px;

    @include mq(md, 1) {
        .header--home__cover {
            &::after {
                padding-top: 350px;
            }
        }
    }

    @include mq(md) {
        .slick-initialized:not(.slider--spaces) & { display: flex; }
        .slider-concept .slick-initialized & { display: block; }

        &,
        .header--home__cover {
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: linear-gradient(to top, rgba(25, 25, 25, 1) 0%, rgba(25, 25, 25, 0) 30%);
                opacity: 1;
                z-index: 1;
            }
        }
    }
}

.slider-concept .slick-slide::after {
    display: none;
}

.slick-with-pagination {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: $grid-container-width;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;

    .slick-track,
    .slick-slide {
        //min-height: 820px;
    }
}

// Arrows
.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;
    width: 34px;
    height: 34px;
    padding: 0;
    cursor: pointer;
    color: $white;
    border: none;
    outline: none;
    transition: all $transition-fast ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
    // top: 68%;
    bottom: $root-vgrid / 2;
    right: 0;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 50%;

    &::before {
        opacity: 1;
        display: block;
        color: inherit;
        font-size: 1rem;
        font-family: $font-icon;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        line-height: 1;
        font-size: 30px;
    }

    @include mq(sm) {
        top: 78%;
        width: 68px;
        height: 68px;
        bottom: auto;
    }


    @include mq(md) {
        top: calc(50% - 1.5rem);
        &:hover {
            color: color(brand, primary);
        }
    }
}

.slick-prev {
    right: auto;
    left: .5rem;
    @include mq(lg) {
        left: 2rem;
    }
}

.slick-next {
    right: .5rem;
    @include mq(lg) {
        right: 2rem;
    }
}

.slick-prev::before {
    content: "";
    background-image: url("/dist/img/svg/angle-left--thin.svg");
    height: 20px;
    background-repeat: no-repeat;
    background-position: center;

    @include mq(md) {
        height: 37px;
    }
}

.slick-next::before {
    content: "";
    background-image: url("/dist/img/svg/angle-right--thin.svg");
    height: 20px;
    background-repeat: no-repeat;
    background-position: center;
    @include mq(md) {
        height: 37px;
    }
}

// Dots

ul.slick-dots {
    position: absolute;
    bottom: 25px;
    left: 0;
    right: 0;
    display: block;
    max-width: calc(100% - 8rem - (100% / 6));
    padding: 0;
    margin: 0 auto;
    list-style: none;
    text-align: center;
    z-index: 50;
}


.slick-dots li {
    position: relative;
    display: inline-block;
    width: 14px;
    height: 14px;
    margin: 0 6px;
    padding: 0;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    cursor: pointer;
    transition: all $transition-fast ease-in-out;
}



.slick-dots li.slick-active {
    background: none;

    @include mq(sm) {
        background: $white;
    }
}


.slick-with-pagination {
    position: relative;

    &.slick-max-height .slick-slider {
        @extend %img-cover;
        max-height: 820px;
        overflow: hidden;

        img {
            max-height: 820px;
        }
    }

    .slider__label {
        display: none;
        position: absolute;
        bottom: 1.5rem;
        right: calc(#{$grid-gutter-width} + 1.5rem);
        z-index: 40;
        color: $white;

        @include mq(md) {
            display: block;
        }

        img {
            width: 18px;
            height: auto;
            display: inline-block;
            margin-right: .7rem;
        }
    }

    .slick-prev,
    .slick-next {
        display: none;

        @include mq(xs) {
            display: block;
        }
    }

    .slick-dots {
        max-width: none;
        font-size: rem(20px);

        @include mq(md) {
            display: block;
            margin-left: $grid-gutter-width/2;
        }

        li,
        .slick-active {
            color: $black;
        }

        .slick-active {
            font-size: rem(36px);
        }
    }

    &.slider-espace {
        .slick-dots {
            bottom: auto;
            top: -52px;
            right: 0;
            left: auto;

            li {
                color: color(greys, base);
            }
        }
    }

    @include mq(sm, 1) {
        .slider--spaces {
            .slick-dots {
                li {
                    width: auto;
                    height: auto;
                    font-size: rem(14px);
                    span {
                        font-size: rem(26px);
                    }

                    &:not(.slick-active) {
                        display: none;
                    }
                }
            }
        }
    }
}

.slide {
    @include keep-ratio("16/9");
}

.slider-nav__slide {
    position: relative;

    .slide {
        margin: 10px;
        cursor: pointer;
    }

    [gr-lazyloader~='loading']::after {
        display: none;
    }

    [gr-lazyloader~="done"]::after {
        box-shadow: inset 0 0 0 0 transparentize($color-primary, .2);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: none;
        z-index: 9;
        content: '';
        position: absolute;
        display: block;
    }

    &.slick-current [gr-lazyloader~="done"]::after {
        transition: $transition-normal ease-in-out;
        box-shadow: inset 0 0 0 10px transparentize($color-primary, .2);
    }
}

.slider-concept,
.slider-case-study,
.slider-espace,
.slider-product {
    .slider-nav {
        margin-top: $grid-gutter-width;

        .slick-prev,
        .slick-next {
            color: color(greys, base);
            background: transparent;
            top: calc(50% - 1.5rem);
            right: -45px;

            &::before { font-weight: 700; }

            @include mq(md) {
                &:hover { color: color(brand, primary); }
            }

            &.slick-disabled {
                cursor: not-allowed;
                pointer-events: none;
                color: color(greys, xlight);
            }
        }

        .slick-prev {
            right: auto;
            left: -45px;
        }
    }
}

// .slider-concept {
//     .slide img {
//         object-fit: contain;
//     }
// }

.slider-product {

    flex-direction: row-reverse;
    align-items: center;

    & > div:first-child {
        width: 100%;
        @include mq(sm) { width: calc(1000% / 12); }
    }

    & > div:last-child {
        width: 100%;
        @include mq(sm) { width: calc(200% / 12); }
    }

    .slider-nav {
        margin-left: $grid-gutter-width * 2;
        margin-right: $grid-gutter-width * 2;

        @include mq(sm) {
            margin-top: $grid-gutter-width * 2;
            margin-bottom: $grid-gutter-width * 2;
            margin-left: 0;
            margin-right: 0;

            .slick-prev,
            .slick-next {
                left: 0;
                right: 0;
                margin: auto;
                top: -45px;
                transform: rotate(90deg);
            }

            .slick-next {
                left: 0;
                right: 0;
                margin: auto;
                top: auto;
                bottom: -45px;
            }
        }
    }
}

.slick-vertical .slick-slide {
    border: 0;
}


.slider--spaces {
    @include mq(md, 1) {
        .slick-dots {
            position: relative;
            bottom: 0;
            background-color: #ededed;
            padding: $root-vgrid / 2 0;
            height: 60px;
        }

        .slick-arrow {
            z-index: 99;
            bottom: -5px;
            top: inherit;
            transform: translateY(-20%);
            width: 50px;
            height: 50px;

            &::before {
                height: 30px;
            }

            &.slick-prev::before {
                left: 0;
            }

            &.slick-next::before {
                right: 0;
            }
        }
    }
}

.slider--home {


    @include mq(md, 1) {
        padding-bottom: 4rem;
        background-color: #ededed;

        .slick-next {
            right: 50%;
            transform: translateX(100%);
            margin-right: -10px;
        }

        .slick-prev {
            left: 50%;
            transform: translateX(-100%);
            margin-left: -10px;
        }

        .header--home__inner-box {
            .h1 + .typography {
                margin-top: 2rem;
            }
        }
    }
}
