// Buttons
// --------------------------------------------------------------------------

%btn {
    @include btn-size(.875rem, .6875rem, .875rem, 2px solid, 0);
    font-weight: 400;
    //text-transform: uppercase;
    // transition: $transition-normal ease-in-out;
    transition: color $transition-normal ease-in-out,
                background-color $transition-normal ease-in-out,
                border-color $transition-normal ease-in-out;
    // letter-spacing: 1px;

    span {
        vertical-align: middle;
    }

    span + span {
        display: inline-block;
        margin-left: .5rem;
    }

    &:hover {
        text-decoration: none;

        span {
            border: 0;
        }
    }

    .icon-arrow {
        width: 12px;
    }
}

@include btn('.btn--primary');

@include btn('.dark-geeen-btn');

.dark-geeen-btn,
.btn--primary {
    @extend %btn;
    @include btn-colors($white, color(brand, primary), color(brand, primary));
    @include mq(md) {
        &:not([disabled]):hover {
            @include btn-colors( color(brand, primary), transparent, color(brand, primary));
        }
    }

    &.is-active {
        @include btn-colors( color(brand, primary), transparent, color(brand, primary));
    }
}


@include btn('.btn--red');

.btn--red {
    @extend %btn;
    @include btn-colors($white, color(member-zone, red), color(member-zone, red));
    @include mq(md) {
        &:not([disabled]):hover {
            @include btn-colors( color(member-zone, red), transparent, color(member-zone, red));
        }
    }

    &.is-active {
        @include btn-colors( color(member-zone, red), transparent, color(member-zone, red));
    }
}


@include btn('.btn--secondary');

.btn--secondary {
    @extend %btn;
    @include btn-colors($root-color, $white, $white);
    @include mq(md) {
        &:not([disabled]):hover {
            @include btn-colors($white, transparent, $white);
        }
    }
}

@include btn('.btn--bordered');

.btn--bordered {
    @extend %btn;
    @include btn-colors($color-primary, white, $color-primary);
    border-width: 1px;
    min-width: 200px;
    text-align: center;

    @include mq(md) {
        &:not([disabled]):hover {
            @include btn-colors($white, $color-primary, $color-primary);
        }
    }
}


@include btn('.btn--download');

.btn--download {
    @extend %btn;
    @include btn-colors($root-color, color(greys, xlight), color(greys, xlight));
    @include mq(md) {
        &:not([disabled]):hover {
            @include btn-colors($root-color, transparent, $root-color);
        }
    }
    display: block;
}

.btn--download-smaller {
    @include mq(lg) {
        font-size: rem(13px);
    }
    @include mq(xl) {
        font-size: rem(14px);
    }
}

@include btn('.btn--ghost');

.btn--ghost {
    @extend %btn;
    @include btn-colors($white, transparent, $white);
    @include mq(md) {
        &:not([disabled]):hover {
            @include btn-colors(color(greys, xdark), $white, $white);
        }
    }
}

.btn--ghost-invert {
    @extend %btn;
    @include btn-colors($color-primary, $white, $color-primary);
    border-width: 1px;

    @include mq(md) {
        &:not([disabled]):hover {
            @include btn-colors($white, color(brand, primary), color(brand, primary));
        }
    }
}

@include btn('.btn--gray');

.btn--gray {
    @extend %btn;
    @include btn-colors(#fff, color(greys, dark2), color(greys, dark2));
    @include mq(md) {
        &:not([disabled]):hover {
            @include btn-colors($root-color, transparent, $root-color);
        }
    }
}

.btn--centered {
    @extend %btn;
    display: table;
    margin-left: auto;
    margin-right: auto;
}

.btn--right {
    @extend %btn;
    display: table;
    margin-left: auto;
    margin-right: 0;
}

.btn--block {
    width: 100%;
    text-align: center;
}

.btn__group {
    margin-top: 1em;

    [class*="btn--"] + [class*="btn--"] {
        margin-left: .4rem;
    }

    .btn--primary {
        margin-bottom: 1em;
    }
}

.btn--text {
    color: currentColor;

    span {
        line-height: 1.3;
        display: inline-block;
        border-bottom: 1px solid transparent;
        transition: border $transition-fast ease-in-out;
    }

    @include mq(md) {
        &:hover {
            span {
                border-color: currentColor;
            }
        }
    }

    .section--product & {
        @include mq(lg, 1) {
            display: none;
        }
        span {
            border-color: currentColor;
        }
    }
}

.section-video {
    .btn--text span {
        border-color: currentColor;
    }
}


[class*=btn] {
    &.disabled,
    &.processing {
        cursor: not-allowed;
        pointer-events: none;
        background-color: color(greys, base);
        border-color: color(greys, base);
    }

    &[class*=icon-] {
        white-space: nowrap;
        flex: 0 0 auto;
    }

    &.icon-download::before,
    &.icon-pencil::before,
    &.icon-refresh::before {
        margin-right: .75em;
    }

    &.icon-refresh.processing::before {
        @include animate(spin, $transition-slow, infinite, normal);
    }
}
