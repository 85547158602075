/* ==========================================================================
    HERO / Tickets / Component
========================================================================== */

.t-hero {
    background: rgba($black, .9);
    color: $white;
    position: relative;
    overflow: hidden;

    min-height: rem(420px);


    &::after {
        content: '';
        min-height: inherit;
        font-size: 0;
    }

    .ytplayer-container {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
    }

    @include mq(sm) {
        padding-top: $root-vgrid;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        min-height: 100vh;

    }

    &--video {
        @include mq(lg) {
            height: 86vh;
            min-height: 86vh;
        }

        @include mq(xl) {
            height: 98vh;
            min-height: 98vh;
        }
    }
}

.t-hero__cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.t-hero__cover-image {
    width: 100%;
    height: 100%;
    img {
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
        width: 100%;
    }

    .objectfit {
        background-size: cover;
        width: 100%;
        height: 100%;
        background-position: center;

        img {
            display: none;
        }
    }
}

.t-hero__cover-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity $transition-normal;
    opacity: 0;
    // transform: scale3d(1.5, 1.5, 1.5);
    &.loaded {
        opacity: 1;
    }
}

.t-hero__content {
    position: relative;
    z-index: 1;
    max-width: 1600px;
    padding: $grid-gutter-width / 2;




    &.left {
        text-align: left;
    }

    &.center {
        text-align: center;
        margin-left: 0;
        margin-right: 0;

        .typography {
            margin-left: auto;
            margin-right: auto;
        }
    }

    &.right {
        text-align: right;

        .typography {
            margin-left: auto;
            margin-right: 0;
        }
    }

    .typography {
        max-width: 900px;
        margin-left: 0;
        margin-right: auto;
        font-weight: 600;

        p {
            font-size: 9vw;
            line-height: 1.1;
            margin-bottom: rem(20px);

            @include mq(sm) {
                margin-bottom: rem(30px);
            }

            @include mq(md) {
                font-size: 4vw;
            }

            &.subtitle {
                font-weight: 200;
                font-size: 4vw;
                line-height: 1.1;

                @include mq(md) {
                    font-size: rem(16px);
                }

                @include mq(lg) {
                    font-size: rem(20px);
                }
            }
        }

        .btn--primary {
            &:hover {
                background-color: white;
            }
        }
    }
}

.t-hero__cover-image {
    .is-mobile {
        display: block;

        @include mq(sm) {
            display: none;
        }
    }

    .is-desktop {
        display: none;

        @include mq(sm) {
            display: block;
        }
    }
}

.t-hero--content-center {
    @include mq(sm, 1) {
        display: flex;
        align-items: center;
    }
}


.t-hero--content-top {
    @include mq(sm, 1) {
        display: flex;

        .t-hero__content {
            display: flex;
            flex-direction: column;

            [class*=btn] {
                align-self: flex-start;
            }
        }
    }

    .t-hero__content.center {
        [class*=btn] {
            align-self: center;

        }
    }

    .t-hero__content.right {
        [class*=btn] {
            align-self: flex-end;

        }
    }
}

.t-hero--content-top-spread {
    @include mq(sm, 1) {
        display: flex;

        .t-hero__content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            [class*=btn] {
                align-self: flex-start;
            }
        }
    }

    .t-hero__content.center {
        [class*=btn] {
            align-self: center;

        }
    }

    .t-hero__content.right {
        [class*=btn] {
            align-self: flex-end;

        }
    }
}

.t-hero--content-bottom {
    @include mq(sm, 1) {
        display: flex;
        align-items: flex-end;
    }
}

.subtitle--hide-mobile {
    @include mq(sm, 1) {
        display: none;
    }
}
