.related-products-section {
  margin-top: 3rem;
  margin-bottom: 4rem;
}

.related-products-container {
  max-width: 1200px;
  margin: 0 auto;
}

.related-products {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -8px;
}

.related-product {
  display: block;
  position: relative;
  padding: 0 8px 15px 8px;
  width: 100%;

  @media (min-width: 600px) {
    width: 50%;
  }

  @media (min-width: 900px) {
    width: 33.3333%;
  }

  .rp-image {
    display: block;
    width: 100%;
    height: auto;
  }

  .rp-content {
    width: 100%;
    padding: 15px;
    color: black;

    @media (min-width: 900px) {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      background-color: rgba(255,255,255,0.8);
      padding: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      opacity: 0;
      transition: opacity 0.4s ease-out;
    }
  }

  .rp-content-inner {
    width: 100%;

    @media (min-width: 900px) {
      background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 3.3073001 3.3072901' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m 67.360825,106.87213 -0.29104,0.29104 1.15358,1.15623 h -2.51619 v 0.41275 h 2.51619 l -1.15358,1.15623 0.29104,0.29104 1.65365,-1.65365 z' style='stroke-width:.264583' transform='translate(-65.707175,-106.87213)'/%3E%3C/svg%3E");
      background-size: 12.5px 12.5px;
      background-repeat: no-repeat;
      background-position: bottom 5px right;
      padding-right: 20px;
    }
  }

  &:hover .rp-content {
    opacity: 1;
  }

  .rp-title {
    font-size: 1.125em;
  }

  .rp-text {
    margin-top: 10px;
    font-size: 0.75em;
  }
}
