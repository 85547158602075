// Custom Select
// --------------------------------------------------------------------------
// @example html - Markup
// <div class='form-item | form-item--inline'>
//      <select>
//           <option disabled checked value='default'> ... </option>
//           <option value=' ... '> ... </option>
//           <option value=' ... '> ... </option>
//           <option value=' ... '> ... </option>
//      </select>
// </div>

@mixin form-select($select-js: false, $select-size: $form-element-height, $class: '.gr-select') {

    @if $select-js {
        #{$class} {
            position: relative;
            height: $select-size;
            cursor: pointer;
            display: inherit;

            &.disabled {
                opacity: .5;
                pointer-events: none;
                cursor: not-allowed;
            }

            select {
                opacity: 0;
                height: $select-size;
            }

            &.is-inverted {
                #{$class}__box {
                    &:focus,
                    &:active,
                    &.is-active {
                        border-radius: 0 0 $form-element-radius $form-element-radius;
                    }
                }

                #{$class}__options {
                    top: auto;
                    bottom: 100%;
                    margin-bottom: 0;
                    border-radius: $form-element-radius $form-element-radius 0 0;

                    &.is-active {
                        margin-top: 0;
                        margin-bottom: -1px;
                    }
                }
            }

            &.is-active {
                #{$class}__box {
                    border-radius: $form-element-radius $form-element-radius 0 0;

                    &::after {
                        transform: rotate(-180deg);
                    }
                }

                #{$class}__options {
                    border-width: $form-element-border-width;
                    border-style: $form-element-border-style;
                    border-color: $form-element-focus-color;
                    height: auto;
                    overflow-y: auto;
                }
            }
        }

        #{$class}__box {
            @extend %input;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            transition: border-radius $transition-fast ease-in-out;

            display: flex;
            align-items: center;
            justify-content: space-between;

            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            &.is-selected {
                color: inherit;
            }

            > div {
                flex: 1;
            }

            &::after {
                display: block;
                content: '';
                width: $select-size;
                height: $select-size;
                flex-basis: $select-size;
                text-align: center;
                transition: transform $transition-fast ease-in-out;
                margin-right: -$form-element-padding;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 284.929 284.929'%3E %3Cpath d='M282.082 76.51L267.808 62.24c-1.902-1.906-4.093-2.856-6.57-2.856-2.47 0-4.66.95-6.563 2.856L142.465 174.44 30.263 62.24c-1.903-1.905-4.093-2.855-6.567-2.855-2.475 0-4.665.95-6.567 2.856L2.856 76.516C.95 78.417 0 80.607 0 83.082c0 2.473.953 4.663 2.856 6.565L135.9 222.693c1.9 1.903 4.092 2.854 6.566 2.854s4.66-.95 6.562-2.854L282.082 89.647c1.902-1.903 2.847-4.093 2.847-6.565 0-2.475-.946-4.665-2.848-6.57z'/%3E %3C/svg%3E");
                background-repeat: no-repeat;
                background-position: center;
                background-size: 1em;
            }

            &:focus,
            &:active {
                border-radius: $form-element-radius $form-element-radius 0 0;

                &::after {
                    transform: rotate(-180deg);
                }
            }

            select:focus ~ & {
                border-color: $form-element-focus-color;
            }

            [class^='field--inline'] & {
                flex: 1 1 auto;
            }
        }

        #{$class}__options {
            height: 0;
            will-change: height;
            overflow: hidden;
            position: absolute;
            top: 100%;
            right: 0;
            left: 0;
            z-index: map-get($z-indexes, overlay) - 1;
            margin-top: -1px;
            text-align: left;
            background-color: $form-element-background-color;
            border-radius: 0 0 $form-element-radius $form-element-radius;
            transition: height $transition-fast ease-in-out, padding $transition-fast ease-in-out;

            li {
                padding-left: $form-element-padding;
                padding-right: $form-element-padding;
                list-style-type: none;

                &:last-child {
                    border-radius: 0 0 $form-element-radius $form-element-radius;
                }

                &[rel='default'],
                &[rel='hide'] {
                    display: none;
                }

                &[disabled] {
                    display: none;
                    font-style: italic;
                    opacity: .75;
                }
            }

            select:focus ~ & {
                border-color: $form-element-focus-color;
            }

            .parsley-error & {
                border-color: color(validation, error) !important;
            }
        }
    }
    @else {
        select {
            @extend %input;
            position: relative;
            height: $select-size;
            border-radius: $form-element-radius;
            appearance: none;
            outline: 0;
            cursor: pointer;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 284.929 284.929'%3E %3Cpath d='M282.082 76.51L267.808 62.24c-1.902-1.906-4.093-2.856-6.57-2.856-2.47 0-4.66.95-6.563 2.856L142.465 174.44 30.263 62.24c-1.903-1.905-4.093-2.855-6.567-2.855-2.475 0-4.665.95-6.567 2.856L2.856 76.516C.95 78.417 0 80.607 0 83.082c0 2.473.953 4.663 2.856 6.565L135.9 222.693c1.9 1.903 4.092 2.854 6.566 2.854s4.66-.95 6.562-2.854L282.082 89.647c1.902-1.903 2.847-4.093 2.847-6.565 0-2.475-.946-4.665-2.848-6.57z'/%3E %3C/svg%3E");
            background-position: right center;
            background-repeat: no-repeat;
            background-size: $select-size 1em;

            &::-ms-expand {
                display: none;
            }

            &:focus::-ms-value {
                color: currentColor;
                background-color: transparent;
            }

            [class^='field--inline'] & {
                flex: 1 1 auto;
            }
        }
    }
}
