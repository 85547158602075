
.navigation__trigger {
    background-color: transparent;
    border: none;
    outline: none;
    width: 35px;
    min-height: 35px;
    z-index: 1001;
    position: relative;
    top: 2px;
    right: 0;
}

.navigation__trigger .bar,
.navigation__trigger .bar::after,
.navigation__trigger .bar::before {
    content: "";
    background: color(greys, xdark);
    display: block;
    height: 2px;
    transition: transform .4s, background .4s;
    transform-origin: 50% 50%;
    position: absolute;
    width: 19px;
}

.navigation__trigger .bar::after {
    transition-delay: 0s;
}

.navigation__trigger .bar::before {
    bottom: calc(100% + 5px);
}

.navigation__trigger .bar::after {
    top: calc(100% + 5px);
}

.navigation__trigger.is-active .bar {
    background-color: transparent;
}

.navigation__trigger.is-active .bar::before {
    transform: rotate(45deg) !important;
    transform: translateY(7px) rotate(45deg) !important;
}

.navigation__trigger.is-active .bar::after {
    transform: translateY(-7px) rotate(-45deg) !important;
    transition-duration: .4s;
}
