// Forms
// --------------------------------------------------------------------------

@include form-layout();
@include form-checkbox();
@include form-file();
@include form-radio();
@include form-select(true);
@include form-normalize('#fff', true);
@include form-validation();

input[type=date] {
    appearance: none;
}

.gr-form__container {
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;
}

.gr-form__field__upload {
    overflow: hidden;
}

.gr-form__field--experience {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.gr-form__field--formation {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.form--job-offer [gr-grid=row].gr-form__field {
    margin-left: -#{$grid-gutter-width/2};
    margin-right: -#{$grid-gutter-width/2};

    > * + * {
        margin: .75em 0;
    }

    @include mq(md) {
        padding-left: 0;
        padding-right: 0;
        margin-left: 0;
        margin-right: 0;

        > * + * {
            margin: 0;
        }
    }
}

.form {
    label {
        font-size: .75em;
    }

    .file-upload-wrapper label,
    .radio-wrapper label,
    .checkbox-wrapper label {
        font-size: 1em;
    }

    &__cover {
        @extend %img-cover;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        z-index: -1;

        &[gr-lazyloader] {
            background: $white;

            img {
                transition: opacity $transition-fast map-get($easing, easeinsine);
                transition-delay: $transition-normal;
                z-index: 1;
                object-fit: contain;
                top: $grid-gutter-width;
                bottom: $grid-gutter-width;
            }
        }

        &::before {
            z-index: 9;
        }
    }
}

.gr-form__field--inline {
    label {
        width: rem(120px);
        padding-right: rem(10px);
    }

    input[type='checkbox'] + label {
        width: auto;
    }
}

.gr-form__field--forgot-password,
.parsley-error .gr-form__field--forgot-password {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    a {
        flex-basis: 100%;
        display: block;
        font-size: em(13px);
        color: $color-primary;
        @include mq(sm, 1) {
            margin-top: $root-vgrid / 3;
        }
    }
}

.gr-form__field--remember {
    padding-top: $root-vgrid / 2;


    label:first-child {
        @include mq(md) {
            display: none;
        }
        @include mq(xl) {
            display: block;
        }
    }
    input[type='checkbox'] + label {
        color: $color-primary;
        line-height: 1;
        padding-left: 1.5rem;
        min-height: 0;

        &::before {
            width: em(15px);
            height: em(15px);
        }
        &::after {
            top: 0;
            left: em(4px);
        }
    }

    .btn--right {
        margin-top: 0;
        @include mq(sm, 1) {
            margin-left: 0;
            margin-top: $root-vgrid / 2;
        }
    }
}

.gr-form__action {
    flex: 1 0 100% !important;
    max-width: 100% !important;
}

.gr-form .gr-select {
    &.disabled {
        cursor: default;
    }

    .gr-select__box {
        border-bottom: 0;

        &.disabled {
            pointer-events: none;
            background-color: color(member-zone, grey-light);
        }
    }
    .gr-select__options {
        border: 1px solid color(greys, light);
        border-top: 0;
        height: 1px;
    }
}

.gr-select--button {
    position: relative;
    cursor: pointer;
    height: $form-element-height;
    border: 2px solid $color-primary;
    padding: .6875rem .875rem;
    color: $color-primary;

    select {
        width: 100%;
        background-color: transparent;
        appearance: none;

        @include mq(lg) {
            appearance: menulist;
        }
    }
}

// .gr-select--button__wrapper {
//     position: relative;
//     display: flex;
//
//     &::after {
//         display: block;
//         content: '';
//         width: $form-element-height;
//         height: $form-element-height;
//         flex-basis: $form-element-height;
//         text-align: center;
//         transition: transform $transition-fast ease-in-out;
//         margin-right: -$form-element-padding;
//         background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 284.929 284.929'%3E %3Cpath fill='%238f897b' d='M282.082 76.51L267.808 62.24c-1.902-1.906-4.093-2.856-6.57-2.856-2.47 0-4.66.95-6.563 2.856L142.465 174.44 30.263 62.24c-1.903-1.905-4.093-2.855-6.567-2.855-2.475 0-4.665.95-6.567 2.856L2.856 76.516C.95 78.417 0 80.607 0 83.082c0 2.473.953 4.663 2.856 6.565L135.9 222.693c1.9 1.903 4.092 2.854 6.566 2.854s4.66-.95 6.562-2.854L282.082 89.647c1.902-1.903 2.847-4.093 2.847-6.565 0-2.475-.946-4.665-2.848-6.57z'/%3E %3C/svg%3E");
//         background-repeat: no-repeat;
//         background-position: center;
//         background-size: 1em;
//     }
// }

.gr-form input.ghost,
.gr-form input[type="checkbox"].ghost + label::before,
.gr-form textarea.ghost,
.gr-form select.ghost + .gr-select__box {
    background-color: transparent;
    transition: $transition-fast map-get($easing, easeinsine);
}

.gr-form input.inverted,
.gr-form input[type="checkbox"].inverted + label::before,
.gr-form textarea.inverted,
.gr-form select.inverted + .gr-select__box,
.gr-form select.inverted + .gr-select__box + .gr-select__options {
    border-color: $white;
    background-color: rgba(0, 0, 0, .75);

    @include placeholder {
        color: $white;
    }
}

.gr-form input[type="checkbox"].inverted + label,
.gr-form input[type="checkbox"].inverted + label::after {
    color: $white;
    border-color: $white;
}

.gr-form select.inverted + .gr-select__box::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='white' viewBox='0 0 284.929 284.929'%3E %3Cpath d='M282.082 76.51L267.808 62.24c-1.902-1.906-4.093-2.856-6.57-2.856-2.47 0-4.66.95-6.563 2.856L142.465 174.44 30.263 62.24c-1.903-1.905-4.093-2.855-6.567-2.855-2.475 0-4.665.95-6.567 2.856L2.856 76.516C.95 78.417 0 80.607 0 83.082c0 2.473.953 4.663 2.856 6.565L135.9 222.693c1.9 1.903 4.092 2.854 6.566 2.854s4.66-.95 6.562-2.854L282.082 89.647c1.902-1.903 2.847-4.093 2.847-6.565 0-2.475-.946-4.665-2.848-6.57z'/%3E %3C/svg%3E");
}

.gr-form input.inverted:focus,
.gr-form input[type="checkbox"].inverted:focus + label::before,
.gr-form textarea.inverted:focus,
.gr-form .is-active select.inverted + .gr-select__box,
.gr-form input.ghost:focus,
.gr-form input[type="checkbox"].ghost:focus + label::before,
.gr-form textarea.ghost:focus,
.gr-form .is-active select.ghost + .gr-select__box,
.gr-form .is-active select.inverted + .gr-select__box,
.gr-form select.ghost + .gr-select__box + .gr-select__options,
.gr-form select.inverted + .gr-select__box + .gr-select__options {
    background-color: rgba(0, 0, 0, .9);
}

.form-blog__categories {
    .gr-form__field--inline {
        margin-top: $form-element-margin;
        margin-bottom: $root-vgrid/2;

        label {
            flex: 1 0 100%;
            //text-transform: uppercase;
            font-weight: 700;
            font-size: .875em;
        }

        input {
            max-width: 400px;
            margin-right: $root-hgrid;
            background-color: $white;
        }
    }

    .btn--primary {
        margin-bottom: $root-vgrid/2;
    }

    .gr-form__field--inline {

        label {
            flex: 0 0 auto;
        }

        input[type='checkbox'] + label {
            background-color: color(greys, xlight);
            padding: rem(13px);
            padding-left: calc((#{$form-element-height} * .5 * 1.25) + #{rem(13px)});
            text-transform: none;
            font-weight: 400;
            line-height: inherit;
            margin-right: $root-padding;
            white-space: nowrap;

            &::before {
                background-color: $white;
            }

            &::before,
            &::after {
                margin-top: em(14px);
                margin-left: em(14px);
            }
        }

        input[type='checkbox']:checked + label {
            background-color: $color-primary;
            color: white;
        }
    }
}


.form-subscribe {
    position: relative;

    &:not(.form-subscribe--small):not(.form-subscribe--medium) {
        padding-top: (2 * $root-vgrid);
        padding-bottom: (2 * $root-vgrid);

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: calc(100% / 3);
            bottom: 0;
            background-color: transparentize($color-primary, .2);
            transform: skew(-15deg);
            transform-origin: top left;
        }
    }


    .gr-form__field--inline {

        label {
            flex: 1 0 100%;
            //text-transform: uppercase;
            font-weight: 700;
            font-size: .875em;
            margin-bottom: $root-vgrid;
        }

        input {
            max-width: 100%;
            background-color: $white;

            @include mq(sm) {
                margin-right: $root-hgrid/1.5;
            }

            &:focus,
            &:active {
                background-color: white;
            }
        }
    }
}

.form-subscribe--small fieldset {
    background: color(greys, xlight);
    padding: $root-padding $root-padding;

    .gr-form__field--inline {
        max-width: 550px;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        margin-top: $form-element-margin;

        @include mq(sm, 1) {
            display: block;
            text-align: center;

            input {
                width: 100%;
                margin-bottom: $root-vgrid/4;
                text-align: center;
            }
        }

        label {
            flex: 0 0 auto;
            margin-bottom: 0;
            max-width: 100%;
            margin-left: $grid-gutter-width;
            margin-bottom: $form-element-margin;

            @include mq(sm) {
                margin-bottom: 0;
            }
        }
    }
}

.form-subscribe--medium fieldset {
    background: color(greys, xlight);
    padding: $root-padding $root-padding;

    .gr-form__field--inline {
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        margin-top: $form-element-margin;

        > * {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: auto;

            & + * {
                margin-top: $root-vgrid/2;

                @include mq(md) {
                    margin-right: 20px;
                }
            }
        }

        .form-error {
            flex-basis: 100%;
        }

        .btn--primary {
            flex-grow: 0;
            margin-right: 0;
        }

        @include mq(md, 1) {
            display: block;
            text-align: center;

            input {
                width: 100%;
                text-align: center;
            }
        }
    }
}

.form-subscribe--box,
.form--login,
.form--contact,
.form--spontaneous,
.form--job-offer {
    text-align: left;

    .gr-form__field {
        margin: .75em 0;
    }
}

.form--alert .js-subscribe-form-item {
    .gr-form__field {
        margin: .75em 0;
    }

    @include mq(md) {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;

        .gr-form__field {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: 0;
            padding-left: $grid-gutter-width/2;
            padding-right: $grid-gutter-width/2;
            margin: 0;
        }

        .btn--primary {
            margin-top: 25px;
            height: calc(2.875rem - 2px);
        }
    }
}

.form--spontaneous {

    @include mq(md) {
        display: flex;
        flex-flow: row wrap;
        max-width: 900px;
        margin-left: auto;
        margin-right: auto;

        > .gr-form__field {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: 50%;
            max-width: 50%;
            padding-left: $grid-gutter-width/2;
            padding-right: $grid-gutter-width/2;
        }
    }
}

@include mq(sm, 1) {
    .form--spontaneous {
        .gr-form__field {
            margin: .75em 0;
        }
    }
}

.form--request {
    background-color: rgba(0, 0, 0, .75);
    padding: $root-vgrid;

    .gr-form__field {
        margin: 0 0 .75em;
    }
}


.form--filters {

    fieldset {
        margin-bottom: $root-vgrid;

        button.btn--primary {
            display: none;
        }
    }

    .title--arrow {
        margin-top: $root-vgrid;
        margin-bottom: 1rem;
    }

    .icon {
        height: $form-element-height * .375;
        width: $form-element-height * .375;
        border: 0;
        background: none;
        margin-left: $form-element-height * .375;
        flex-shrink: 0;
    }

    .gr-form__field {
        width: 100%;
        margin-bottom: (.5 * $root-padding);

        &--inline {
            flex-wrap: nowrap;
        }
    }

    .search-form {
        max-width: 300px;

        > div {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: auto;

            input {
                width: 100%;
            }
        }
    }

    .gr-form__field .gr-form__field {
        display: none;
        margin: ($root-vgrid * .5) 0 ($root-vgrid * .5) $root-hgrid;
    }

    .gr-form__field input[type='checkbox'] + label {
        padding-left: 2em;
        width: 100%;
    }

    .gr-form__field input[type='checkbox'].js-parent + label > a::after,
    .gr-form__field input[type='checkbox'].js-parent + label > span::after {
        content: $icon-angle-right;
        font-size: .5em;
        margin-left: 1em;
        display: inline-block;
        font-family: $font-icon;
    }

    .gr-form__field input[type='checkbox']:checked ~ .gr-form__field,
    .gr-form__field input[type='checkbox']:indeterminate ~ .gr-form__field,
    .gr-form__field input[type='checkbox'][indeterminate] ~ .gr-form__field {
        display: block;
    }

    .gr-form__field input[type='checkbox']:checked + label span,
    .gr-form__field input[type='checkbox']:indeterminate + label span,
    .gr-form__field input[type='checkbox'][indeterminate] + label span {
        font-weight: 700;

        &::after {
            transform: rotate(90deg);
        }
    }


    & + .data-downloadable {
        margin-top: 2 * $root-vgrid;

        .gr-dropdown {
            margin-bottom: 1rem;
        }
    }
}

.form--dealers fieldset {

    .gr-form__field--inline {
        margin: $grid-gutter-width/1.2 0;
        margin-bottom: 0;

        input {
            margin-right: 1.5rem;
        }
    }
}

.form--dealers__results {

    address {

        display: flex;
        align-items: flex-start;

        > img,
        > svg {
            width: 15px;
            min-width: 15px;
            display: block;
            margin-right: 1rem;

            &:last-child {
                align-self: center;
                margin-left: 1rem;
                margin-right: 0;
            }
        }

        &.primary {
            > svg:first-child {
                fill: color(greys, base);
            }
        }

        > div {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: auto;
        }

        padding-bottom: $root-vgrid/2;

        a {
            color: currentColor;

            &:hover {
                text-decoration: underline;
            }
        }

        .title {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}


.form--dealers__results.form--dealers__results--our-address {
    margin-left: auto;
    margin-right: auto;
    overflow: visible;

    address {
        cursor: default;
        margin-top: 0;
        border-top: 1px solid color(brand, primary);
        border-bottom: 0;
        padding-bottom: 0;

        @include mq(md) {
            padding: 0;
            border-top: 0;
            padding-top: $root-vgrid;

            br {
                display: none;
            }

            > div > *:not(.title) {
                margin-left: rem(30px);
            }


            & + address {
                margin-top: $root-vgrid;
                border-top: 1px solid color(brand, primary);
            }
        }

        &:first-of-type {
            border-top: 0;
        }

        > .svg {
            display: none;
        }

        .title {
            justify-content: center;
            margin-bottom: $root-vgrid/2;

            @include mq(md) {
                min-width: 320px;
                justify-content: flex-start;
                margin: 0;

            }

            .svg {
                width: 13px;
                height: 13px;
                position: relative;
                top: -2px;
                margin-right: .7rem;
            }
        }
    }
}


.gr-form-upload__wrapper label::after {
    background: url(../img/svg/upload.svg) no-repeat center center;
    background-size: 1em;
    border-left: 0;
}

.form--dealers__results {
    overflow: auto;
    max-height: 100%;
    padding-bottom: $root-vgrid*2;
    padding-right: $grid-gutter-width / 2;
    -webkit-overflow-scrolling: touch;

}


.parsley-required {
    font-size: rem(14px);
    color: color(validation, error);
}

.parsley-error {
    .btn--primary {
        @include btn-colors($white, color(brand, primary), color(brand, primary));
    }
}

#form-errors,
.form-errors {
    font-size: rem(18px);
    color: color(validation, error);
}

.banner--login #form-errors,
.banner--login .form-errors {
    margin-top: rem(25px);
}

.form--filters-career,
.form--filters {

    .gr-form__field {
        overflow: hidden;
    }

    .filters-options__title {
        @include mq(md) {
            display: none;
        }
    }
}

.applyFilter__container {
    text-align: right;
    margin-bottom: $root-vgrid;
}

.close-filters {
    display: inline-block;
    font-size: rem(18px);
    line-height: 1;
    color: $root-color;
    text-align: center;
    padding: .4rem .6rem;
    border: 1px solid color(brand, primary);

    @include mq(md) {
        display: none;
    }
}

@include mq(md, 1) {
    .form--filters {
        height: 0;
        opacity: 0;
    }

    .form--filters.is-fixed {
        position: fixed;
        opacity: 1;
        top: 3.25em;
        left: 0;
        z-index: 100;
        width: 100%;
        height: calc(100vh - 3.25em);
        padding: 1rem;
        overflow: auto;
        background-color: color(brand, primaryLight);
        padding-bottom: $root-vgrid*2;
        -webkit-overflow-scrolling: touch;
    }

    .filters-options__title {
        display: block;
        padding-bottom: $root-vgrid/1.5;
        margin-bottom: $root-vgrid;
        text-align: center;
        border-bottom: 1px solid $black;
    }
}

.filters-options {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: $grid-gutter-width/3;
    padding-right: $grid-gutter-width/3;
    padding-top: $root-vgrid/3;
    padding-bottom: $root-vgrid/3;
    margin-top: $root-vgrid;
    margin-bottom: $root-vgrid;
    color: color(brand, primary);
    font-size: rem(18px);
    font-weight: 600;
    border-top: 1px solid $black;
    border-bottom: 1px solid $black;

    &.is-sticky {
        position: fixed;
        color: $white;
        width: calc(100% - #{$grid-gutter-width});
        top: calc(2.9em - #{$root-vgrid});
        z-index: 100;
        border-top: 1px solid color(brand, primary);
        border-bottom: 1px solid color(brand, primary);
        background-color: color(brand, primary);
    }

    @include mq(md) {
        display: none;
    }

    &::after {
        content: "";
        font-size: .5em;
        margin-left: 1em;
        display: inline-block;
        font-family: "artopex-iconfont";
    }
}
