.zm-tabs-trigger {

    .zm-tabs-trigger__select {
        @include mq(sm) {
            display: none;
            min-width: 0;
        }
    }

    .zm-tabs-trigger__links {
        display: none;
        list-style: none;
        align-items: center;
        justify-content: center;

        a {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            color: black;

            &.is-active {
                color: color(member-zone, red);
            }
        }

        ul {
            list-style: none;
            padding-left: 2em;
        }

        @include mq(sm) {
            display: flex;
        }
    }
}


.zm-tabs-trigger__select {
    position: relative;
    border: 1px solid color(greys, light);
    background-color: white;
    width: 100%;

    @include mq(md) {
        max-width: 320px;
        margin-left: auto;
        margin-right: 0;
    }

    .zm-tabs-trigger__select-icon {
        position: absolute;
        z-index: 1;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);
    }

    select {
        position: relative;
        z-index: 2;
        width: 100%;
        padding: 0 1rem;
        height: 40px;
        background-color: transparent;
    }
}


.zm-tabs-trigger--inner-nav {
    .zm-tabs-trigger__select {
        margin-bottom: $root-vgrid;

        @include mq(sm) {
            display: block;
        }

        @include mq(md) {
            max-width: 100%;
        }

        @include mq(lg) {
            display: none;
        }
    }

    .zm-tabs-trigger__links {
        display: none;

        @include mq(lg) {
            display: block;
        }

        > li:first-child a {
            padding-top: 0;
        }

        a {
            padding-top: $root-vgrid/2;
            padding-bottom: $root-vgrid/2;
            padding-left: 0;
            padding-right: 0;
            display: block;
            letter-spacing: .3px;
        }

        ul a {
            padding-top: $root-vgrid/4;
            padding-bottom: $root-vgrid/4;
        }
    }
}
