img {
  max-width: 100%;
}

// section container
// -------------------------------------------- 
.section-container-sm {
  [gr-grid=container] {
      max-width: 900px;
  }
}

.section-container-base {
  [gr-grid=container] {
      max-width: 1200px;
  }
}

.section-container-md {
  [gr-grid=container] {
      max-width: 1490px;
  }
}

// Class
// -------------------------------------------- 
$factorSpacing: 5;

@for $i from 1 through 20 {

  .mt-#{$i} {
    margin-top: #{$i * $factorSpacing}px;
  }

}

.max-w-430 {
  max-width: 430px;
}

.max-w-650 {
  max-width: 650px;
}

