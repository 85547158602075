.ressources {
    padding: 2rem;

    .ressources__inner {
        @include mq(sm, 1) {
            max-height: 400px;
        }
    }

    &.is-opened {
        .icon-angle-up {
            transform: scale(-1);
        }

        .ressources__inner {
            max-height: 400px;
        }
    }
}

.ressources__trigger {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    [class*="icon-"] {
        font-size: 1.5rem;
        transition: all $transition-fast ease-in-out;
        display: none;

        @include mq(sm) {
            display: block;
        }
    }

    > * {
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: auto;
    }
}

.ressources__list {
    li + li {
        margin-top: .5rem;
    }

    a {
        color: currentcolor;
    }

    [class*="icon-"] {
        padding-right: .5rem;
    }
}

.ressources__inner {
    max-height: 0;
    overflow: hidden;
    text-align: left;
    transition: max-height $transition-fast ease-in-out;

    [class*="btn--"] {
        width: 100%;
        text-align: center;
    }
}
