[gr-grid~=row--block] {
    & > .ais-hits {
        position: relative;
        display: flex;
        flex-flow: row wrap;
    }

    &#hits {
        display: block;
    }
}

.form--filters {
    .ais-hierarchical-menu--header,
    .ais-refinement-list--header {
        fieldset {
            margin-bottom: 0;
        }
    }

    .ais-hierarchical-menu--list__lvl1,
    .ais-hierarchical-menu--list__lvl2 {
        margin-left: 30px;
    }

    .ais-hierarchical-menu--list__lvl0 > .ais-hierarchical-menu--item > div:not(.ais-hierarchical-menu--list) input[type='checkbox'] {
        & + label > span::after {
            content: $icon-angle-right;
            font-size: .5em;
            margin-left: 1em;
            display: inline-block;
            font-family: $font-icon;
        }

        &:checked ~ .gr-form__field,
        &:indeterminate ~ .gr-form__field,
        &[indeterminate] ~ .gr-form__field {
            display: block;
        }

        &:checked + label span,
        &:indeterminate + label span,
        &[indeterminate] + label span {
            font-weight: 700;

            &::after {
                transform: rotate(90deg);
            }
        }
    }
}
