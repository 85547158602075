// Components: Accordion-Tabs
// --------------------------------------------------------------------------

@include accordion-tabs('.tabs', md);

@include accordion-tabs-member('.tabs--faq', md);

.tabs {
    list-style: none;

    .tab {
        margin: 1rem 0;
    }

    .tab-link.gr-active + .gr-active {
        display: flex;
    }

    [gr-show-all-trigger] {
        margin-top: $root-vgrid;

        @include mq(md) {
            display: table;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .tab-content {
        font-size: 1rem;
        text-align: left;
        padding: 0 $grid-gutter-width/2;

        @include mq(sm) {
            padding: $root-vgrid $root-hgrid;
        }

        // maximum 2 rows, hide if more

        @include mq(sm, 1) {
            [gr-grid~=row--block] {
                & > div:not(:nth-child(-n+4)) {
                    display: none;
                }
            }
        }

        @include mq(sm, lg) {
            [gr-grid~=row--block] {
                & > div:not(:nth-child(-n+6)) {
                    display: none;
                }
            }
        }

        @include mq(lg) {
            [gr-grid~=row--block] {
                & > div:not(:nth-child(-n+10)) {
                    display: none;
                }
            }
        }
    }
}

.tabs--docs {
    [gr-acc-tabs-trigger] {
        position: relative;
    }

    [gr-acc-tabs-trigger] {
        display: flex !important;
        align-items: center;
        justify-content: space-between;
    }

    [gr-acc-tabs-trigger]::after {
        font-family: "artopex-iconfont";
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        display: inline-block;
        content: $icon-angle-down;

        position: relative;
        right: 1.5rem;
        font-size: .75em;
        margin-top: auto;
        margin-bottom: auto;

    }

    [gr-acc-tabs-trigger~="active"]::after {
        transform: rotate(180deg);
    }

    .tab-link {
        background: color(greys, xlight);
        padding: 1em;
        @extend %h3;
        margin: 0;
        color: currentColor;
    }

    .tab-content {
        padding-left: $grid-gutter-width/2;
        padding-right: $grid-gutter-width/2;
        padding-top: 0;
        padding-bottom: 0;
    }

    .table tbody td {
        border-top: 0;
        border-bottom: 1px solid color(greys, xlight);
        background-color: $white;
        padding-top: 1em;
        padding-bottom: 1em;
    }
}

.tabs--faq {
    max-width: 1185px;
    margin-left: auto;
    margin-right: auto;

    svg {
        margin-left: 1rem;
        min-width: 15px;
        path {
            fill: color(brand, primary);
        }
    }

    .minus {
        display: none;
    }

    .gr-active {
        .minus {
            display: block;
        }

        .plus {
            display: none;
        }
    }


    [gr-acc-tabs-member-trigger] {
        position: relative;
    }

    [gr-acc-tabs-member-trigger]::after {
        font-family: "artopex-iconfont";
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        display: inline-block;
        content: $icon-angle-down;
        font-size: .9em;
        margin-top: auto;
        margin-bottom: auto;
    }

    [gr-acc-tabs-member-trigger~="active"]::after {
        transform: rotate(180deg);
    }

    .tab-link {
        color: $root-color;
        font-weight: 600;
        padding-top: $root-vgrid/2;
        padding-bottom: $root-vgrid/2;
        display: flex !important;
        align-items: center;
        justify-content: space-between;

        > span {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: auto;
        }

        &::after {
            position: relative;
            top: auto;
            left: auto;
        }
    }

    .tab {
        margin: 0;
        border-bottom: 1px solid color(greys, light);
    }

    .tab-content {
        padding: $root-vgrid/2 0;
        padding-top: 0;
    }
}

.tabs--product {
    & > .tab > .tab-link {
        @extend %btn;
        @include btn-colors($root-color, color(greys, xlight), transparent);
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        transition: none;

        &.gr-active {
            color: $white;
            background-color: color(greys, base);
        }

        @include mq(md) {
            background-color: transparent;

            &.gr-active {
                color: $root-color;
                background-color: color(greys, xlight);
            }
        }

        @include mq(md, 1) {
            padding-right: rem(50px);
        }
    }

    .tab-content {
        .tab-content {
            padding-bottom: 0;
        }

        .tab-link {
            display: block;
            padding: rem(10px);
            background-color: color(greys, xlight);
            margin-bottom: $root-vgrid/2;
            color: $root-color;

            &:hover,
            &.gr-active {
                background-color: color(greys, base);
                color: $white;
            }

            &.level-2 {
                padding-right: rem($grid-gutter-width);
            }
        }

        @include mq(md) {
            background-color: color(greys, xlight);

            .tab-link {
                background-color: $white;
            }
        }

        @include mq(md, 1) {
            &.level-3 {
                padding-right: rem($grid-gutter-width);
            }
        }
    }

    .tab-link {
        position: relative;

        &.parent {
            &::after {
                content: none;
                font-family: $font-icon;
                font-size: 12px;
                position: absolute;
                top: 35%;
                right: rem(15px);
                transition: transform $transition-fast;
            }

            &.is-open {
                &::after {
                    transform: rotate(180deg);
                }
            }

            &.level-2::after,
            &.level-3::after {
                top: 30%;
            }

            @include mq(md) {
                &.level-2::after {
                    content: $icon-angle-down;
                }
            }

            @include mq(md, 1) {
                &::after {
                    content: $icon-angle-down;
                }
            }
        }
    }
}

.tabs[gr-acc-tabs]:not([gr-acc-tabs~='tab']):not([gr-acc-tabs~='acc']) .tab-link.level-3 {
    display: none;

    @include mq(md) {
        display: none;
    }

    &.parent-is-open {
        display: block;
    }
}

.tab-content .gr-form {
    label {
        min-width: 100px;
    }

    .gr-form__field--inline + .gr-form__field--inline {
        margin-top: $root-vgrid/5;
    }
}

.tabs.tabs--product__elements {

    position: relative;

    .tab-link {
        @include mq(md) {
            width: 100%;
        }

        span {
            display: inline-block;
            //text-transform: uppercase;
            font-weight: 400;
            padding: 1px 2px;
            font-size: .875rem;
        }
    }

    .card__figure {
        margin-bottom: $root-vgrid/4;

        &::after {
            display: none;
        }
    }

    @include mq(md) {

        .tab__title,
        .tab-link {
            position: absolute;
            left: 0;
            max-width: 190px;
        }

        .tab-content {
            margin-left: 190px;
            width: calc(100% - 190px) !important;
            padding-top: 0;
        }
    }
}
