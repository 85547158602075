// ==========================================================================
// Navigation
// ==========================================================================

// <nav gr-nav-effect="reveal-left" gr-nav-sticky="always" class="$nav-class">
//     <div class="$nav-class__nav-icon" gr-nav-trigger><img src="" class="svg"></div>
//     <a class="$nav-class__brand" href=""><img src="" alt="" /></a>
//     <section class="$nav-class__panel" gr-nav-panel>
//         <ul class="$nav-class__main">
//             <li><a href="">...</a></li>
//             ...
//         </ul>
//     </section>
// </nav>



@mixin grNavigation ($nav-options : ()) {

    $nav-options-default : (
        class:             '.nav',
        height:            5rem,
        mobile-height:     2.5rem,
        mobile-width:      20rem,
        breakpoint:        md,
        icon-color:        black,
        icon-bar-height:   2px
    );

    $grNavigation-options:  map-merge( $nav-options-default, $nav-options );

    $nav-class:             map-get( $grNavigation-options, class);
    $nav-height:            map-get( $grNavigation-options, height);
    $nav-total-height:      map-get( $grNavigation-options, total-height);
    $nav-mobile-height:     map-get( $grNavigation-options, mobile-height);
    $nav-mobile-width:      map-get( $grNavigation-options, mobile-width);
    $nav-breakpoint:        map-get( $grNavigation-options, breakpoint);
    $nav-icon-color:        map-get( $grNavigation-options, icon-color);
    $nav-icon-bar-height:   map-get( $grNavigation-options, icon-bar-height);

    @if (map-get($grid-breakpoints, $nav-breakpoint) or $nav-breakpoint==always or $nav-breakpoint==never) {

        #{$nav-class} {



            // .has-nav-active {
            //     overflow: hidden;
            // }

            @if $nav-breakpoint!=never {

                [gr-nav-trigger~='squeeze'] {
                   @include animated-icon('hamburger', 'cross', $nav-icon-color, $nav-icon-bar-height, 0);
                }

                [gr-nav-trigger] {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    margin: calc(#{$nav-mobile-height} / 3) $grid-gutter-width;
                    height: calc(#{$nav-mobile-height} / 3);
                    width: calc(#{$nav-mobile-height} / 3);
                    @if $nav-breakpoint!=always {
                        //@include mq($nav-breakpoint) { display: none; }
                        @include mq(md) { display: none; }
                    }
                }

                &[gr-nav*=left] [gr-nav-trigger] { left: 0; }

                &[gr-nav*=right] [gr-nav-trigger] { right: 0; }
            }


            &[gr-nav]:not([gr-nav~='offset-top']) {
                position: absolute;
                z-index: (map-get($z-indexes, nav) - 1);
                top: 0;
                left: 0;
                right: 0;
                width: auto;
                display: block;

                & ~ [gr-overlay] { @extend %gr-overlay;}

                & > [gr-grid] { position: relative; }

                // Responsive nav height snf offset page around $nav-breakpoint

                @if $nav-breakpoint!=never and $nav-breakpoint!=always {
                    height: $nav-mobile-height;
                    & + * { margin-top: $nav-mobile-height; }
                    @include mq(md) {
                        height: $nav-total-height;
                        & + * { margin-top: $nav-total-height; }
                    }
                } @else {
                    height: $nav-total-height;
                    & + * { margin-top: $nav-total-height; }
                }

                // Sticky option

                &[gr-nav~=sticky] { position: fixed; }

                @if $nav-breakpoint!=always and $nav-breakpoint!=never {

                    &[gr-nav~=sticky-above] {
                        @include mq($nav-breakpoint) { position: fixed; }
                    }

                    &[gr-nav~=sticky-below] {
                        @include mq($nav-breakpoint, 1) { position: fixed; }
                    }
                }
            }

            [gr-nav-panel] {
                position: absolute;
                z-index: map-get($z-indexes, nav);
                width: $nav-mobile-width;
                max-width: 100vw;
                height: 100vh;
                transition: transform $transition-fast map-get($easing, easeinsine); // Needs to be removed
                overflow: scroll;
                -webkit-overflow-scrolling: touch;

                ul {
                    list-style-type: none;

                    & > li {
                        position: relative;
                    }
                }

                @if $nav-breakpoint and $nav-breakpoint!=always and $nav-breakpoint!=never {
                    @include mq(md) {
                        position: static;
                        transform: none;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        align-items: center;
                        justify-content: center;
                        flex: 1 1 0;
                        width: 100%;
                        height: $nav-height;
                        overflow-x: hidden;
                        overflow-y: visible;

                        ul > li {
                            display: inline-block;
                            position: static;
                        }

                        body:not(.is-touch) & ul > li.is-active [gr-subnav-panel],
                        body:not(.is-touch) & ul >li:hover [gr-subnav-panel] {
                            height: auto;
                            display: block;
                            z-index: 2;
                        }

                        body.is-touch & ul>li.is-active,
                        body.is-touch & ul >li:hover {

                            [gr-subnav-panel] {
                                height: auto;
                                display: block;
                                z-index: 2;
                            }

                        }
                    }
                }
            }

            [gr-subnav-trigger] {
                position: absolute;
                top: 0;
                right: 0;

                @if $nav-breakpoint!=never and $nav-breakpoint!=always {
                    @include mq($nav-breakpoint) { display: none; }
                }
            }

            [gr-subnav-panel] {
                height: 0;
                padding: 0;
                overflow: hidden;

                &[gr-subnav-panel~='active'] {
                    height: auto;
                }

                @if $nav-breakpoint!=never and $nav-breakpoint!=always {
                    @include mq(md) {
                        transform-origin: top center;
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: $nav-height;
                        height: auto;
                        z-index: 1;
                        display: none;

                        &[gr-nav-subpanel~='active'] {
                            display: block;
                            z-index: 2;
                        }
                    }
                }
            }

            &[gr-nav*='reveal'] [gr-nav-panel] {
                @if $nav-breakpoint!=never and $nav-breakpoint!=always {
                    top: $nav-mobile-height;
                    max-height: calc(100vh - #{$nav-mobile-height});
                    @include mq(md) {
                        top: $nav-height;
                        max-height: none;
                    }
                }
            }

            &[gr-nav~='reveal-left'] {
                [gr-nav-panel] { left: -$nav-mobile-width; }
                [gr-nav-panel~='active'] { transform: translateX(100%); }
            }

            &[gr-nav~='reveal-right'] {
                [gr-nav-panel] { left: 100%; }
                [gr-nav-panel~='active'] { transform: translateX(-100%); }
            }

            &[gr-nav~='reveal-top'] {
                [gr-nav-panel] {


                    @if $nav-breakpoint!=always and $nav-breakpoint!=never {
                        @include mq(md, 1) {
                            left: 0;
                            right: 0;
                            z-index: -1;
                            transform: translateY(-100%);
                            height: auto;
                            width: 100%;
                        }
                    }
                }

                [gr-nav-panel~='active'] { transform: translateY(0); }
            }

            &[gr-nav~='offset-left'] {
                [gr-nav-panel] {
                    top: 0;
                    left: -$nav-mobile-width;
                }

                &[gr-nav~='active'],
                &[gr-nav~='active'] ~ * { transform: translateX($nav-mobile-width); }
            }

            &[gr-nav~='offset-right'] {
                [gr-nav-panel] {
                    top: 0;
                    left: 100%;
                }

                &[gr-nav~='active'],
                &[gr-nav~='active'] ~ * { transform: translateX(-$nav-mobile-width); }
            }

            &[gr-nav~='offset-top'] {

                @if $nav-breakpoint!=never and $nav-breakpoint!=always {
                    @include mq(md) {
                        height: $nav-total-height;
                        & + * { padding-top: $nav-total-height; }
                    }
                } @else {
                    height: $nav-total-height;
                    & + * { padding-top: $nav-total-height; }
                }

                [gr-nav-panel] {
                    height: 0;
                    width: 100%;
                    padding: 0;
                    position: static;

                    @if $nav-breakpoint and $nav-breakpoint!=always and $nav-breakpoint!=never {
                        @include mq(md) {
                            position: static;
                            transform: none;
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            align-items: center;
                            justify-content: center;
                            flex: 1 1 0;
                            width: 100%;
                            height: $nav-height;
                            overflow-x: hidden;
                            overflow-y: visible;
                        }
                    }
                }

                &[gr-nav~='active'] [gr-nav-panel] { height: 100%; }
            }

            @if $nav-breakpoint!=always and $nav-breakpoint!=never {
                // @include mq($nav-breakpoint) {
                @include mq(md) {
                    [gr-nav-panel] {
                        left: auto;
                        right: auto;
                        transform: none !important;
                        transition: none !important;
                        z-index: map-get($z-indexes, nav);
                    }
                }
            }
        }
    }
    @else {
        @warn 'Unfortunately, no value could be retrieved from `#{$breakpoint}`. '
            + 'Please make sure it is defined in `$grid-breakpoints` map.';
    }
}
