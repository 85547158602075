// Media Box
// --------------------------------------------------------------------------

[gr-mediabox] {
    display: none;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .7);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;

    figure {
        position: absolute;
        top: $root-vgrid;
        left: $root-hgrid;
        bottom: $root-vgrid;
        right: $root-hgrid;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    img {
        display: block;
        max-width: 100%;
    }

    figcaption {
        margin: 15px auto;
        width: 50%;
        text-align: center;
        font-size: 1em;
        line-height: 1.5;
        font-weight: 700;
        color: $white;
        //text-transform: uppercase;
    }
}

[gr-formbox] {
    display: none;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .7);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    //display: flex;
    // justify-content: center;
    // align-items: center;


    & > div {
        max-width: 600px;
        margin: auto;
        background-color: $white;
        position: relative;
        top: 50%;
        transform: translate3d(0, -50%, 0);
    }

    form {
        padding: 2rem;
        // position: absolute;
        // top: $root-vgrid;
        // left: $root-hgrid;
        // bottom: $root-vgrid;
        // right: $root-hgrid;
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        // align-items: center;
    }
}
