// Titles
// --------------------------------------------------------------------------

%title {
    font-size: 1rem;
    color: currentColor;
    position: relative;
    line-height: 1.1;

    & + .typography,
    & + .card__subtitle {
        margin-top: 1em;
    }
}

.banner--sidebyside__title,
.section__title,
.banner__title {
    @extend %title;
    font-family: $font-headings;
    font-weight: 700;
}

.section__title {
    margin-bottom: $root-vgrid;

    & + [gr-grid*="row"] {
        margin-top: $root-vgrid;

        @include mq(sm) {
            margin-top: (2 * $root-vgrid);
        }
    }
}

.banner__title {
    margin-bottom: .5rem;
}


.tags {
    //text-transform: uppercase;
}

.tag,
.card__subtitle {
    @extend %title;
    font-size: em(12px);
    margin-top: .5rem;
}

.nav__title {
    @extend %title;
    font-weight: 600;
    //text-transform: uppercase;
    font-size: 1.5em;
    padding-top: $root-vgrid;
    padding-bottom: $root-vgrid;

    &::before {
        font-size: .75em;
        margin-right: 1em;
    }
}

.title {
    @extend %title;
    //text-transform: uppercase;
    font-weight: 700;

    &--small {
        font-size: em(14px);
    }

    &--big {
        font-size: em(24px);
    }

    &--centered {
        text-align: center;
    }
}

.title--arrow {
    font-size: 1.125em;
    line-height: 1.1;
    font-weight: 600;
    //text-transform: uppercase;
    color: inherit;
    position: relative;
    //padding-left: 1em;
    display: inline-block;

    a {
        color: currentColor;
        text-decoration: underline;
    }

    @include mq(sm) {
        font-size: 1.5em;
    }

    &::before {
        position: absolute;
        top: .5em;
        left: 0;
        content: $icon-arrow;
        font-family: $font-icon;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1.6;
        display: inline-block;
        font-size: .5em;
        color: color(greys, base);
        display: none;
    }

    & + .typography,
    & + .card__subtitle {
        margin-top: 1em;
    }
}

.title--video {
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    display: inline-block;
}
