// Navigations
// --------------------------------------------------------------------------

@include grNavigation($nav-options);

.nav [gr-nav-trigger] {
    margin: calc(3.25em / 3) 20px;
    left: 0;
}

.nav {
    background: $white;

    &[gr-nav]:not([gr-nav~='offset-top']) + * {
        padding-top: calc(#{map-get($nav-options, mobile-height)});
        margin-top: 0;
    }

    .nav-grid-container {
        text-align: left;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .nav-top-container {
        background-color:$white;
        position: relative;
    }

    .nav-bottom-container {
        position: relative;
        margin-top: -10px;
    }

    button { right: 0; }

    @include mq(md) {
        justify-content: space-between;

        &[gr-nav]:not([gr-nav~='offset-top']) + * {
            padding-top: map-get($nav-options, total-height);
        }
    }
}

@include mq(md, 1) {
    .nav[gr-nav]:not([gr-nav~='offset-top']) {
        &.gmap-opened {
            z-index: 1;
        }
    }
}


.nav__brand {
    margin: auto;

    img,
    svg {
        display: inline-block;
        width: 100px;
        height: map-get($nav-options, mobile-height);
    }

    @include mq(md) {
        margin-left: 0;

        img,
        svg {
            width: 140px;
            height: 70px;
        }
    }
}

.nav__contact {
    display: none;

    @include mq(md) {
        display: inline-block;
    }
}

.nav .nav__panel {
    overflow: auto;
    background: $white;
    padding: (.5 * $root-vgrid) (.5 * $grid-gutter-width) $root-vgrid;

    @include mq(md) {
        z-index: 1;
        background: none;
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        padding: 0;
        height: map-get($nav-options, height);
        overflow: visible;

        .nav__main {
            display: flex;
            justify-content: flex-start;
            height: 100%;

            &:first-child {
                margin-left: -.625em;
                margin-right: auto;
            }

            &:last-child {
                margin-right: -.625em;
            }

            li {
                display: flex;

                &.has-submenu:hover .nav__link {
                    background-color: color(greys, xlight);
                }

                .nav__link {
                    display: flex;
                    align-items: center;
                }

                &.search-cta {
                    position: relative;
                    align-items: stretch;
                    justify-content: stretch;
                }
            }
        }
    }

    @include mq(lg) {
        .nav__main {
            &:first-child {
                margin-left: -1em;
            }

            &:last-child {
                margin-right: -1em;
            }
        }
    }

    @include mq(xl) {
        .nav__main {
            &:first-child {
                margin-left: -1.25em;
            }

            &:last-child {
                margin-right: -1.25em;
            }
        }
    }
}


.nav .nav__subpanel {
    transition: $transition-fast ease-in-out;

    form {
        width: 100%;
    }

    @include mq(md) {
        padding-top: $root-vgrid;
        padding-bottom: $root-vgrid/2;
    }

    [gr-grid="container"] {
        position: relative;

        [gr-grid="block"] {
            margin-top: 0 !important;
        }
    }

    .title--arrow {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .sub-nav-list {

        @include mq(sm) {
            columns: 2;

        }

        @include mq(md) {
            columns: 3;
        }

        @include mq(lg) {
            columns: 5;
        }
    }
}

.nav__link.nav__link--subpanel {
    display: none;

    @include mq(md) {
        display: block;
    }
}

.nav .nav__subpanel.nav__subpanel--find-us {
    height: auto;
    text-align: center;

    @include mq(md) {
        background-color: color(greys, xlight);
        padding-top: $root-vgrid;
        padding-bottom: $root-vgrid*2;
        text-align: left;
    }

    .socials {
        display: none;

        @include mq(md) {
            display: block;
        }

        svg {
            margin-right: 1rem;
        }
        svg path {
            fill: color(brand, primary);
        }
    }


    [gr-grid~=row--block] {
        display: flex;
        justify-content: flex-end;

        @include mq(md, 1) {
            display: block;

            [gr-grid=block] {
                margin-left: auto;
                margin-right: auto;

                a {
                    line-height: 1.2;
                    font-weight: 400;
                    font-size: 1em;
                    padding-top: .5rem;
                    padding-bottom: 0;
                }

                .card__body {
                    display: none;
                }
            }
        }
    }
}

.search-is-open .nav .nav__subpanel.nav__subpanel--find-us {
    display: none !important;
}

.nav__subpanel--search {
    height: 220px;
    display: flex;
    align-items: center;
    background: white;

    form {
        input[type=search] {
            font-size: $typo-heading-max;
            height: $typo-heading-max;
            border: 0;
            flex: 1 1 auto;
            min-width: 0;
        }

        .gr-form__field--inline {
            flex-wrap: nowrap;
        }

        .icon {
            font-size: $typo-heading-max * .5;
            height: $typo-heading-max * .5;
            width: $typo-heading-max * .5;
            border: 0;
            background: none;
            margin-right: $form-element-padding;
        }
    }
}

.nav__link {
    color: $black;
    display: block;
    text-align: center;
    white-space: nowrap;

    .btn--primary {
        padding-left: .75em;
        padding-right: .75em;

        em {
            display: none;
        }
    }

    @include mq(lg) {
        .btn--primary {
            em {
                display: inline-block;
                font-style: normal;
                text-transform: none;
                margin-right: .25em;
            }
        }
    }

    @include mq(lg) {
        position: relative;
        border: 0;
        display: inline-block;
        padding: 0;
        text-align: left;

        &:not(.btn--primary).active > span:not(.search-cta__icon),
        li:hover &:not(.btn--primary) > span:not(.search-cta__icon) {
            box-shadow: 0 3px 0 0 $color-primary;
        }
    }
}

.nav__link {
    font-weight: 400;
    font-size: em(14px);
    padding-left: .625em;
    padding-right: .625em;

    @include mq(lg) {
        font-size: em(18px);
        padding-left: 1em;
        padding-right: 1em;
    }

    @include mq(xl) {
        padding-left: 1.25em;
        padding-right: 1.25em;
    }

    &.no-padding {
        @include mq(md, 1) {
            padding: 0 !important;
        }
    }
}

.nav__main-secondary {
    .nav__link {
        @include mq(lg) {
            font-size: em(16px);
        }
    }
}

footer {
    .nav__link {
        padding-left: .625em;
        padding-right: .625em;
        display: block;

        @include mq(md) {
            text-align: left;
        }
        @include mq(lg) {
            padding-left: .625em;
            padding-right: .625em;
        }


        &.no-padding {
            padding: 0 !important;
        }
    }

    @include mq(md) {
        .nav__footer {
            .nav__link {
                padding: 0 0 10px;
            }
        }
    }

}

#gr-input-search {
    min-width: 300px;
}

.nav__footer {
    // @include mq(md) {
    //     padding: 0 .5 * $grid-gutter-width;
    // }


    display: block;

    li {
        .nav__link {
            font-size: em(16px);
            display: block;
            align-items: center;

            span {
                line-height: 1.3;
                display: inline-block;
                border-bottom: 1px solid transparent;
                transition: border $transition-fast ease-in-out;
            }
        }

        @include mq(md) {
            display: block;
            &:hover {
                span {
                    border-color: currentColor;
                }
            }
        }
    }

    .customer-service,
    .cookie-management {
        text-align: left;
        @include mq(md) {
            text-align: center;
            padding-top: 0;
        }
    }
}

.nav__blog {
    display: flex;
    justify-content: space-between;
    align-items: center;

    li {

        &:first-child .nav__link {
            white-space: nowrap;
            margin-right: $grid-gutter-width;
        }

        &:last-child .nav__link {
            text-align: right;
        }

        .nav__link {
            font-size: em(14px);
            text-decoration: underline;
            font-weight: 700;
        }
    }
}


.form--search {
    max-width: 600px;

    .gr-form__field--inline {
        flex-wrap: nowrap;
    }

    input[type=search] {
        margin-right: 1rem;
    }

    .icon {
        font-size: $typo-heading-max * .5;
        height: $typo-heading-max * .5;
        width: $typo-heading-max * .5;
        border: 0;
        background: none;
        margin-right: $form-element-padding;
        flex-shrink: 0;
        cursor: pointer;
    }

}
