// Footer
// --------------------------------------------------------------------------

footer {
    position: relative;
    margin-bottom: 30px;


    .legal-wrapper {
        padding: 0 15px;

        .legal-container {
            width: 100%;
            padding-top: 15px;
            border-top: 1px solid #E8E7E5;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;

            @include mq(md) {
                flex-direction: row;
            }
        }
    }

    .legal {
        // margin: 0 15px;
        font-size: 14px;
        color: #8E897B;
        text-align: center;
    
        @include mq(md, 1) {
            flex-basis: 100%;
            flex-grow: 1;
            max-width: 100%;
            font-size: em(15px);
        }
    }

    .social-icons--link {
        padding: 0;

        .social-icons--title {
            text-align: center;
        }
        .social-icons--items {
            display: flex;
            align-items: center;
            column-gap: 10px;
        }

        @include mq(md) {
            .social-icons--title {
                text-align: left;
            }
        }
    }

}

.footer__inner {
    padding-top: calc(#{$root-vgrid/2} + 45px);
    padding-bottom: $root-vgrid/2;
    text-align: center;
    font-size: em(14px);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    row-gap: 30px;

    @include mq(sm) {
        justify-content: space-between;
        
        text-align: left;
        padding-top: $root-vgrid/2;
        


        // nav {
        //     flex: 1 0 auto;
        // }
    }

    @include mq(md) {
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: start;
        text-align: left;
        row-gap: 0;
    }

    
}

.footer {
    background: color(greys, xdark);
    color: $white;
    font-size: .75rem;
    position: relative;
    z-index: 12;
    


    [gr-grid] {
        padding: $root-vgrid $grid-gutter-width;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;

        @include mq(sm) {
            flex-direction: row;
        }

        .footer__copyright {
            padding-bottom: $root-vgrid;

            @include mq(sm) {
                padding-bottom: 0;
            }
        }
    }
}


.footer__back2Top {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    position: absolute;
    right: calc(50% - 45px * .5);
    top: 0;
    color: $white;
    background-color: $black;

    @include mq(sm) {
        top: -45px * .5;
    }

    > span {
        line-height: 1;
    }

    @include mq(sm) {
        top: -45px;
        right: 0;
    }
}
