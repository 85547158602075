@include pagination();

.pagination {
    font-size: .75em;
    font-weight: 700;
    text-align: center;
    margin-top: (2 * $root-vgrid);

    &__btn--prev,
    &__btn--next,
    &__link,
    &__link--current,
    &__link--disabled {
        display: inline-block;
        color: $root-color;
        // margin: 0 .5em;

        text-align: center;
        padding: .5em;

        @include mq(sm) {
            width: rem(42px);
            padding: 1em;

        }

    }

    &__btn--disabled {
        color: #aaa;
    }

    &__link--current {
        color: $white;
        background-color: $root-color;
        padding: 1em;
        width: rem(42px);

        .pagination__link {
            color: $white;
        }
    }
}


.job-pagination .pagination {
    margin-top: $root-vgrid/3;
}

.job-pagnation__pagination {

    > * + * {
        margin-top: $root-vgrid;
    }

    @include mq(md) {
        display: flex;
        align-items: center;
        justify-content: center;

        > * {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: 0;

            & + * {
                margin-top: 0;
            }

            &:last-child {
                text-align: right;
            }
        }
    }
}



.ais-pagination {
    > li {
        padding: 0;
        width: auto;
        background-color: transparent;
        &.pagination__link--current {
            a {
                color: $white;
                background-color: $root-color;
                width: rem(42px);
                padding: 1em;
            }
        }

        &.pagination__btn--disabled {
            span {
                color: #aaa;
            }
        }
    }
}
