.container-photoSwipe {
  margin: 0 auto;
  max-width: 1140px;
  overflow: hidden;
}

.photoSwipe-section-title {
    margin-bottom: 45px;
    padding: 0 15px;
}

.swiper-photoSwipe {
  .swiper-slide {
    width: auto !important;

    a {
      display: block;

      &:hover {
        .swiper-slide-image {
          transform: scale(1.3);
        }
      }
    }

    .swiper-slide-image {
      margin: 0 auto;
      transition: transform 800ms linear;
    }
  }

  .swiper-slide-container-image {
    height: 270px;
    overflow: hidden;
  }

  .swiper-slide-image {
  }

  .swiper-slide-content {
    margin: 20px 0 40px;
  }

  .swiper-slide-title {
    font-family: "CircularStd", "Arial", sans-serif, "Arial", sans-serif;
    font-size: 1.2rem;
    text-align: center;
    font-weight: bold;
    color: #191919;
  }

  .swiper-slide-body {
    margin-top: 0px;
    font-family: "CircularStd", "Arial", sans-serif, "Arial", sans-serif;
    font-size: 1rem;
    text-align: center;
    color: #191919;
  }

  // nav
  // --------------------------------------------
  .swiper-button-prev-custom,
  .swiper-button-next-custom {
    background-color: rgba(255, 255, 255, 0.6);
    width: 40px;
    height: 40px;
    margin-top: -20px !important;
    top: 135px !important;
    position: absolute;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;

    &:hover {
        color: #9d162e;
    }

    &.swiper-button-disabled {
      opacity: 0;
      pointer-events: none;
    }

    .icon-angle-right,
    .icon-angle-left {
        position: relative;
        top: 3px;
    }
  }

  .swiper-button-prev-custom {
    left: 0;

    .icon-angle-left {
        left: -3px;
    }

  }

  .swiper-button-next-custom {
    right: 0;
  }

  // pagination
  // --------------------------------------------
  .swiper-horizontal > .swiper-pagination-progressbar,
  .swiper-pagination-progressbar.swiper-pagination-horizontal,
  .swiper-pagination-progressbar.swiper-pagination-vertical.swiper-pagination-progressbar-opposite,
  .swiper-vertical
    > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
    top: inherit !important;
    bottom: 0 !important;
  }

  .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background: #78705e;
  }

  // scrollbar
  // --------------------------------------------
  .swiper-horizontal > .swiper-scrollbar,
  .swiper-scrollbar.swiper-scrollbar-horizontal {
    width: 100%;
    left: 0;
    height: 8px;
    background: #ccc;
    border-radius: 0;
  }

  .swiper-scrollbar-drag {
    background-color: #9d162e;
    cursor: pointer;
    border-radius: 0;
    position: relative;
    height: 12px;
    top: -2px;

    &:before,
    &:after {
      content: "";
      display: block;
      width: 20px;
      height: 2px;
      background-color: white;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      z-index: 10;
    }

    &:before {
      top: 3px;
    }

    &:after {
      top: 7px;
    }
  }
}

.swiper-photoSwipe-single {
    .swiper-slide-container-image {
        height: 320px;
        overflow: hidden;
    }

    .swiper-slide-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .swiper-button-prev-custom,
    .swiper-button-next-custom {
        position: absolute;
        background-color: black;
        width: 40px;
        height: 40px;
        margin-top: -20px !important;
        top: 50% !important;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        cursor: pointer;

        &:hover {
            color: #9d162e;
        }

        &.swiper-button-disabled {
            opacity: 0;
            pointer-events: none;
        }

        .icon-angle-right,
        .icon-angle-left {
            position: relative;
            top: 3px;
        }
    }

    .swiper-button-prev-custom {
        left: 0;

        .icon-angle-left {
            left: -3px;
        }

    }

    .swiper-button-next-custom {
        right: 0;
    }
}
