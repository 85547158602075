.nav--member {

    z-index: 99;
    background-color: rgba(0, 0, 0, 1);
    top: 0;
    left: 0;
    bottom: 0;
    padding: 3rem 40px;
    color: #fff;
    width: 100%;
    text-align: center;

    @include mq(md) {
        position: fixed;
        width: 20%;
        min-width: 210px;
        background-color: rgba(0, 0, 0, .75);
    }

    ul {
        margin: $root-vgrid 0 0;
        border-top: 1px solid rgba(255, 255, 255, .25);
        border-bottom: 1px solid rgba(255, 255, 255, .25);
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-around;

        @include mq(md) {
            display: block;
            margin: $root-vgrid -40px $root-vgrid * 3;
            border-bottom: 0;
            width: auto;
        }

        li {

            padding: .75em;

            @include mq(md) {
                border-bottom: 1px solid rgba(255, 255, 255, .25);
            }

            a {
                //text-transform: uppercase;
                color: $white;
                font-weight: 700;
                font-size: em(14px);
                box-shadow: none;
            }

            &.is-active a {
                color: $color-primary;
            }
        }
    }


    .avatar img {
        border: 3px solid $white;
    }

    footer {
        display: none;

        @include mq(md) {
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            padding: $grid-gutter-width * .5;
            text-align: center;

            .legal {
                display: block;
                font-size: em(12px);
                color: $white;

            }
        }
    }
}
