.section--spaces--mosaic {
    position: relative;
    margin-bottom: 4.5 * $root-vgrid;
}

.spaces--cta {

    &__content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        width: 100%;
        margin-top: $root-vgrid;
        margin-bottom: $root-vgrid;

        .section__title {
            margin-bottom: 0;
        }

        @include mq(sm) {
            margin-top: (2 * $root-vgrid);
            margin-bottom: (2 * $root-vgrid);
        }

        .btn {
            @extend .btn--primary;
        }
    }

    @include mq(lg) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 20;
        width: 26rem;
        height: 26rem;

        &:hover .spaces--cta__content {
            background-color: rgba(0, 0, 0, 1);
        }

        &__content {
            background-color: rgba(0, 0, 0, .75);
            transition: background-color $transition-normal ease-in-out;
            border-radius: 50%;
            color: white;
            z-index: 10;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 4rem;
            height: 100%;
            margin: auto;

            .section__title {
                margin-bottom: $root-vgrid;
            }

            .btn {
                background-color: $white;
                border-color: $white;
                color: $root-color;

                &:not([disabled]):hover {
                    color: $white;
                    border-color: $white;
                }
            }
        }
    }
}

@include banner('.banner--cta', 220px, $root-vgrid);

.banner--cta {
    max-width: 80%;
    margin: auto;

    &__cover img {
        filter: grayscale(100%);
    }
}

.banner--cta__cover::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 50%;
    bottom: 0;
    background-color: transparentize($color-primary, .2);
    transform: skew(15deg);
    transform-origin: bottom right;
}

.banner--cta__inner {
    text-align: left;
    padding: $root-vgrid * 2 50% $root-vgrid * 2 $root-hgrid * 4;
}


.video-cta__container {
    width: 100%;
    height: 100%;
    // background: rgba(0, 0, 0, .6);
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, .35);
}

.video-cta,
.vt-cta {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
    width: 8rem;
    height: 8rem;

    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: none;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
        content: '';
        width: 55px;
        height: 55px;
        background-image: url('/dist/img/svg/play-video.svg');
        background-position: center;
        background-size: contain;
    }
}

.video-cta::before {
}

.vt-cta::before {
    content: $icon-camera;
}

.vt-new_cta {
    cursor: pointer;
    z-index: 20;
    width: 100%;
    text-align: center;
    display: block;

    &::before {
        content: $icon-play-circle;
        font-family: $font-icon;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $black;
        font-size: 3.5rem;
        width: 6rem;
        height: 6rem;
        margin: 0 auto;
    }
}

.nav-cta {
    position: absolute;
    bottom: $root-vgrid;
    right: $root-hgrid;
}

.search-cta {
    label {
        display: inline-block;
        margin-left: .5em;
        cursor: pointer;

        @media screen and (min-width: 1025px) {
            display: none;
        }
    }
}

.search-cta {
    .gr-form__field {
        display: none;
    }

    .gr-form__field {
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        width: auto;
        margin: 0;
        transform: scale3d(0, 1, 1) translateY(-50%);
        transition: transform 240ms;
        transform-origin: right;
    }

    &.gr-active,
    &[gr-search="active"] {
        .gr-form__field {
            transform: scale3d(1, 1, 1) translateY(-50%);
        }

        .search-cta__icon {
            z-index: 1;
        }
    }
}

.search-cta__icon {
    @include animated-icon('search', 'default', $root-color, 2px, 0);
    display: inline-block;
    margin-left: em(5px);
    font-size: rem(16px);
    color: $root-color;

    @include mq(lg) {
        margin-bottom: rem(-1px);
    }
}

.chatbot-cta {
    width: 48px;
    height: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #9D162E;
    background-color: #9D162E;
    color: white;
    border-radius: 50%;
    padding: 4px;
    cursor: pointer;
    position: fixed;
    bottom: 16px;
    right: 16px;
    z-index: 10;
    text-align: center;
    line-height: 1;
    transition: background-color $transition-fast ease-in-out, color $transition-fast ease-in-out;

    @include mq(md) {
        width: 88px;
        height: 88px;
        padding: 16px;
        bottom: 40px;
        right: 40px;
    }

    &:hover {
        background-color: white;
        color: #9D162E;
    }

    &__label {
        display: none;
        font-size: 0.75rem;
        text-align: center;
        font-style: normal;

        @include mq(md) {
            display: block;
            margin-top: 4px;
        }
    }

    &__icon {
        width: 1.875rem;
    }
}
