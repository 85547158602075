// ==========================================================================
// Breadcrumb
// ==========================================================================

@mixin breadcrumbs($class: '.breadcrumbs', $separator: '\232a') {

    #{$class} {
        list-style: none;

        li {
            display: inline-block;
        }

        li:not(:last-child) {
            &::after {
                content: $separator;
                font-size: .75em;
                display: inline-block;
                margin: 0 .2em;
            }
        }

        li:last-child {
            a {
                cursor: default;
            }
        }
    }
}
