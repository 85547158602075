.career-alert {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 200;
    padding-top: $root-vgrid/3;
    padding-bottom: $root-vgrid/3;
    color: white;
    font-size: rem(16px);
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;
    background-color: color(member-zone, red);
    text-align: center;
    transition: transform $transition-fast ease-in-out;

    &[js-job-alert~=is-hidden] {
        transform: translateY(100%);
    }

    @include mq(xs) {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: left;
    }

    @include mq(sm) {
        font-size: rem(18px);
    }

    a {
        display: inline-block;
        color: currentColor;
        font-size: rem(16px);
        padding-bottom: .1rem;
        border-bottom: 1px solid white;
        margin-top: $root-vgrid/2;

        @include mq(xs) {
            margin-top: 0;
        }
    }
}

.career-alert__text {
    display: flex;
    align-items: center;
    line-height: 1.1;
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;
    text-align: left;

    img {
        padding-right: $grid-gutter-width/2;
        width: 55px;
        height: auto;
    }
}

.career-table {
    list-style: none;
    font-size: rem(18px);
    max-width: 1490px;
    margin-left: auto;
    margin-right: auto;

    li {
        padding-top: $root-vgrid/2;
        padding-bottom: $root-vgrid/2;
        padding-left: $grid-gutter-width/2;
        padding-right: $grid-gutter-width/2;
        border-top: 1px solid color(member-zone, grey-dark);
        border-bottom: 1px solid color(member-zone, grey-dark);

        @include mq(md) {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    li + li {
        border-top: 0;
    }

    .career-table__title {
        font-size: rem(24px);
        line-height: 1.1;
        color: color(greys, dark);
        margin-bottom: $root-vgrid/4;

        @include mq(sm) {
            margin-bottom: 0;
        }
    }

    .career-table__infos {
        color: color(member-zone, grey-base);

        @include mq(sm) {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        @include mq(md) {

            justify-content: center;
        }
    }

    .career-table__infos > * {

        @include mq(md) {
            margin-left: 3rem;
            min-width: 175px;
        }

        &.btn--primary {
            margin-top: $root-vgrid/2;

            @include mq(sm) {
                margin-top: 0;
            }

            @include mq(md) {
                min-width: 0;
            }
        }
    }
}


.images-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: $root-vgrid/2;
    margin-left: -#{$grid-gutter-width/4};
    margin-right: -#{$grid-gutter-width/4};
}

.images-section__image {
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    width: 100%;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    padding-left: $grid-gutter-width/4;
    padding-right: $grid-gutter-width/4;
    flex-basis: 50%;
    min-width: 50%;

    @include mq(sm) {
        flex-basis: 0;
        min-width: 0;
    }

    > div {
        @include keep-ratio('1/1');
        width: 100%;
        background-position: center;
        background-size: cover;
    }
}

.images-section__image--double {
    flex-grow: 2;
    flex-basis: 100%;
    min-width: 100%;
    margin-bottom: $grid-gutter-width/2;

    @include mq(sm) {
        flex-basis: 0;
        min-width: 0;
        margin-bottom: 0;
    }

    > div {
        @include keep-ratio('2/1');
    }
}


.header--career__inner.header--home__inner .typography {
    display: block;
    p {
        display: block;
    }
}

.row-block-benefit {
    max-width: 1490px;
    margin-left: auto;
    margin-right: auto;

    [gr-grid=block] {
        display: flex;
        text-align: left;
    }

    .benefit__icon {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: color(member-zone, red);
        flex-shrink: 0;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin-top: $root-vgrid/2;

        @include mq(md) {
            width: 100px;
            height: 100px;
        }

        img {
            max-width: 25px;
            height: auto;

            @include mq(md) {
                max-width: 50px;
            }
        }
    }

    .benefit__title {
        position: relative;
        color: color(greys, dark);
        margin-bottom: $root-vgrid/2;

        &::after {
            content: '';
            display: block;
            width: 50px;
            height: 2px;
            background-color: rgba(143, 137, 123, .4);
        }
    }

    .benefit__content {
        margin-left: 1rem;
    }
}


.filter-career__column {
    > div + div {
        margin-top: $root-vgrid/2;
    }

    @include mq(md) {
        column-count: 1;
        column-gap: $grid-gutter-width;
    }

    @include mq(lg) {
        column-count: 2;
    }
}

.filter-career__title {
    color: color(member-zone, grey-xdark);
    margin-bottom: $root-vgrid/2;
}

.filter-career__link {
    text-decoration: underline;
    color: color(brand, primary);
    display: block;
    margin-top: $root-vgrid/2;
}

.form--filters-career {
    display: none;
    background-color: color(member-zone, grey-xlight);

    @include mq(md, 1) {
        &.is-opened {
            display: block;
            overflow: auto;
            position: fixed;
            top: 3.25em;
            left: 0;
            z-index: 100;
            width: 100%;
            height: calc(100vh - 3.25em);
            z-index: 30;
        }
    }

    @include mq(md) {
        position: relative;
        width: auto;
        height: auto;
        top: auto;
        left: auto;
        max-width: 1510px;
        margin-left: auto;
        margin-right: auto;

        &.is-opened {
            display: block;
        }

        &.is-opened .filter-career__inner {
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            justify-content: center;
        }
    }

    .filters-options__title,
    .applyFilter__container {
        @include mq(md) {
            display: none;
        }
    }
}

.form--filters-career {
    padding: 2rem;
    margin-bottom: $root-vgrid;
}

.form--filters-career .filter-career__inner {
    > div {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 50%;

        @include mq(md) {
            flex-basis: 0;
            padding-left: $grid-gutter-width;
            padding-right: $grid-gutter-width;
        }

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-right: 0;
            flex-basis: 100%;
            margin-top: $root-vgrid*1.5;

            @include mq(md, 1) {
                border: 0;
            }

            @include mq(md) {
                flex-basis: 0;
                margin-top: 0;
            }
        }

        & + div {
            margin-top: $root-vgrid;

            @include mq(md) {
                margin-top: 0;
                border-left: 1px solid color(greys, light);
            }
        }

        &:nth-child(3) {
            @include mq(md) {
                min-width: 215px;
                max-width: 215px;
            }

            @include mq(lg) {
                min-width: 300px;
                max-width: 300px;
            }
        }
    }
}


[js-toggle-filters-career] {
    .icon-angle-down {
        font-size: rem(12px);
        transition: transform $transition-fast ease-in-out;
    }

    &.is-opened .icon-angle-down {
        transform: rotate(-180deg);
    }
}


.job-offer__full-form {
    display: none;
}


.job-form {
    margin-top: $root-vgrid;

    @include mq(md) {
        margin-top: 0;
    }

    @include mq(lg) {
        margin-left: 3rem;
    }

    .gr-form__field--without-label {
        > label:first-child {
            display: none;
        }
    }

}

[job-offer~='form-full'] {
    display: block;
    max-width: 930px;

    .job-offer__body {
        display: none;
    }

    .job-offer__full-form {
        display: block;
    }

    .gr-form__field > .gr-form__field {
        margin: 0;
    }

    .job-form {
        padding-top: 0;
        background-color: transparent;

        @include mq(lg) {
            margin-left: 0;
        }

        .gr-form__field__upload,
        > .h1,
        .job-offer__small-form-options {
            display: none;
        }

        .gr-form {
            padding-left: 0;
            padding-right: 0;
        }

        .gr-form__field--without-label {
            > label:first-child {
                display: block;
            }
        }
    }

    .form--job-offer {
        .job-offer__form-step-1,
        .job-offer__form-step-2,
        .job-offer__form-step-3 {
            @include mq(md) {
                display: flex;
                flex-wrap: wrap;
                margin-left: -#{$grid-gutter-width/2};
                margin-right: -#{$grid-gutter-width/2};

                > * {
                    flex-shrink: 0;
                    flex-grow: 1;
                    flex-basis: 50%;
                    min-width: 50%;
                    max-width: 50%;
                    padding-left: #{$grid-gutter-width/2};
                    padding-right: #{$grid-gutter-width/2};
                }
            }
        }
    }
}


.job-offer__header {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    padding-top: $root-vgrid/2;
    padding-bottom: $root-vgrid/2;
    margin-top: $root-vgrid;
    margin-bottom: $root-vgrid;

    @include mq(lg) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    > div {
        display: flex;
        align-items: center;
        margin-top: $root-vgrid/2;
        margin-bottom: $root-vgrid/2;

        @include mq(lg) {
            margin-top: 0;
            margin-bottom: 0;
        }

        * + * {
            margin-left: .6rem;
        }
    }
}


.job-offer_link {
    display: inline-block;
    text-decoration: underline;
    color: color(member-zone, grey-xdark);
    margin-top: $root-vgrid/5;
    margin-bottom: $root-vgrid/5;
}

.job-offer__socials {
    display: flex;
}

.formation-template,
.experience-template {
    padding-bottom: $root-vgrid/2;
    margin-bottom: $root-vgrid/2;
    border-bottom: 1px solid transparentize($black, .8);

    .job-offer_link {
        display: none;
    }
}


#other-formations .job-offer_link,
#other-experiences .job-offer_link {
    display: block;
}
