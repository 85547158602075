
.nav__panel, .nav__top {
    display: none !important;

    @include mq(md) {
        display: flex !important;
    }
}

.mobile-nav {
    @include mq(md) {
        display: none;
    }
}

@include mq(md, 1) {
    .nav-is-open {
        overflow: hidden;
    }

    .nav[gr-nav]:not([gr-nav~='offset-top']),
    .site-container {
        margin-left: 0;
        transition: margin-left $transition-fast linear;
        width: 100%;
    }

    .nav-is-open .nav[gr-nav]:not([gr-nav~='offset-top']),
    .nav-is-open .site-container {
        margin-left: 275px;
    }
    .nav-is-open {
        .site-container {
            overflow: hidden;
        }

        > * {
            width: 100vw;
        }
    }
}

.mobile-nav {
    position: fixed;
    width: 275px;
    height: 100%;
    background-color: white;
    overflow: auto;
    z-index: 10000;
    left: 0;
    transform: translateX(-100%);
    top: 0;
    -webkit-overflow-scrolling: touch;
    transition: transform $transition-fast linear;
}

.nav-is-open .mobile-nav {
    transform: translateX(0);
}

.mobile-nav__panel {
    background-color: color(greys, xlight);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 0;
    display: block;
    opacity: 0;
    overflow: hidden;
    transition: opacity $transition-fast linear 50ms;

    &.is-opened {
        display: block;
        opacity: 1;
        z-index: 20;
        height: 100%;
        overflow: auto;
    }
}

.mobile-nav__black-link {
    display: block;
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;
    padding-top: $root-vgrid/2.5;
    padding-bottom: $root-vgrid/2.5;
    border-bottom: 1px solid #b2b2b2;
    color: color(greys, base);
    font-size: rem(14px);
    letter-spacing: .5px;

    .icon-angle-left {
        font-size: rem(10px);
    }
}

.mobile-nav .nav__link,
.mobile-nav__link {
    display: block;
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;
    padding-top: $root-vgrid/2;
    padding-bottom: $root-vgrid/2;
    border-bottom: 1px solid #b2b2b2;
    color: black;
    text-align: left;
}

.mobile-nav__link:active {
    background-color: white;
}

.mobile-nav .gr-form {
    border-bottom: 1px solid #b2b2b2;

    .gr-form__field--inline {
        flex-wrap: nowrap;
        padding-left: $grid-gutter-width/2;
        padding-right: $grid-gutter-width/2;
    }

    .gr-form__field--inline input[type='search'],
    button {
        color: color(brand, primary);
        padding: 0;
        border: 0;
        background-color: color(greys, xlight);
    }

    button {
        position: relative;
        top: 2px;
        font-size: rem(18px);
    }
}


.mobile-nav {
    .socials {
        padding-top: $root-vgrid/2;
        padding-left: $grid-gutter-width*1.5;
        padding-right: $grid-gutter-width*1.5;
    }
    .socials .card {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    svg path {
        fill: color(brand, primary);
    }
}
