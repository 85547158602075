.modal-landing {
    position: relative;
    background-color: white;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5rem;
    margin-bottom: 5rem;

    @include mq(sm) {
        max-width: 650px;
    }

    @include mq(md) {
        min-width: 800px;
    }

    @include mq(lg) {
        max-width: 1000px;
    }

    .modal-landing__body {
        padding: $grid-gutter-width/2;

        @include mq(sm) {
            padding: 2.5rem;
        }
    }

    .card__figure {
        @include keep-ratio('250/100');
    }

    .mfp-close {
        color: #fff;
        right: 0;
        top: -35px;
        text-align: right;
        width: 100%;
        opacity: 1;
    }
}
