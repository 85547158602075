.showrooms {
    position: relative;

    .showrooms__banner {
        position: relative;
    }
}

.showroom_banner-container .showrooms__banner {
    &::after {
        content: '';
        z-index: 1;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(360deg, rgba(0, 0, 0, 0.8) 0.03%, rgba(0, 0, 0, 0) 60.05%);
        pointer-events: none;
    }
}

.tabs-showroom {
    @include mq(sm, 1) {
        [gr-grid="block"] + [gr-grid="block"] {
            margin-top: $root-vgrid/3;
        }
    }

    [gr-acc-tabs-trigger] {
        position: relative;
        border: 1px solid color(greys, light);
        padding: 1rem;
        text-align: center;

        @include mq(sm) {
            display: none !important;
        }

        img {
            position: absolute;
            top: calc(50% - 8px);
            right: 1.5rem;
            display: inline-block;
            transition: transform $transition-fast ease-in-out;
        }

        &.gr-active img {
            transform: rotate(180deg);
        }
    }

    .tab-content {
        padding-left: 0;
        padding-right: 0;
        padding-top: $root-vgrid;
        padding-bottom: $root-vgrid;
        text-align: center;

        .showrooms__inner address a {
            color: currentColor;
        }

        @include mq(sm) {
            display: block !important;
            text-align: left;
            padding: 0;

            .showrooms__inner address {
                margin-top: $root-vgrid/2;

                > div:not(.title) {
                    flex-grow: 1;
                    flex-shrink: 1;
                    flex-basis: auto;

                    br {
                        display: none;
                    }
                }
            }
        }

        .title {
            display: none;

            @include mq(sm) {
                font-size: rem(18px);
                display: block;
                margin-bottom: $root-vgrid/6;
            }
        }

        .showroom_banner-container {
            position: relative;

            .showroom__options {
                display: none;

                @include mq(sm) {
                    display: block;
                }
            }
        }

        .showroom__options {
            margin-top: $root-vgrid;
            z-index: 1;

            @include mq(sm) {
                color: white;
                position: absolute;
                bottom: 1rem;
                right: 1.5rem;
                margin: 0;
                display: none;
            }

            a {
                display: inline-block;
                color: color(brand, primary);

                @include mq(sm) {
                    color: $white;
                }

                & + a {
                    margin-left: 2rem;
                }

                svg,
                img {
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                    margin-bottom: .2rem;

                    path,
                    use {
                        fill: color(brand, primary);

                        @include mq(sm) {
                            fill: $white;
                        }
                    }
                }
            }
        }
    }
}
