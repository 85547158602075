// Cards
// --------------------------------------------------------------------------

@include card('.card', false, false, 0, "4/3");

.card {
    position: relative;
    overflow: hidden;
    //text-transform: uppercase;
    display: block;
    color: $root-color;

    .card__figure {
        color: $white;

        &::after {
            content: "";
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background-color: transparent;
            opacity: 0;
            border: 0;
            animation: none;
            transition: opacity $transition-normal ease-in-out;
        }

        .title--arrow {
            display: none;
        }

        @include mq(sm) {
            &:hover {
                .card__figure-icon {
                    opacity: 1;
                }
            }
        }
    }

    .card__options-container {
        position: absolute;
        width: 100%;
        right: 0;
        bottom: 0;
    }

    .card__figure--right {
        text-align: right;

        .card__figure-icon:nth-child(2) {
            display: none;

            @include mq(lg) {
                display: inline-flex;
            }
        }
    }

    .card__figure-icon {
        display: none;
        opacity: 0;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
        position: relative;
        z-index: 1;
        border: 2px solid color(brand, primary);
        background-color: $white;
        transition: all $transition-fast ease-in-out;

        @include mq(sm) {
            display: inline-flex;

            &:hover {
                background-color: color(brand, primary);

                svg path {
                    fill: $white;
                }
                span {
                    &::before {
                        color: $white;
                    }
                }
            }
        }

        span {
            display: flex;
            align-items: center;
            justify-content: center;
            &::before {
                transition: color $transition-fast ease-in-out;
                color: color(brand, primary);
            }
        }

        svg,
        img {
            position: relative;
            z-index: 1;
            left: auto;
            right: auto;
            bottom: auto;
            top: auto;
            width: 15px;
            height: 15px;

            path {
                transition: all $transition-fast ease-in-out;
                fill: color(brand, primary);
            }
        }
    }

    .card__body {
        margin-top: 1em;

        @include mq(sm, 1) {
            margin-bottom: 1.5em;
        }
    }

    .card__title {
        margin-bottom: $root-vgrid/1.5;

        @include mq(xs) {
            margin-bottom: 0;
        }

        span {
            line-height: 1.3;
            display: inline-block;
            border-bottom: 1px solid transparent;
            transition: border $transition-fast ease-in-out;
            font-size: rem(18px);
        }
    }

    @include mq(md) {
        &:hover {
            .card__figure::after {
                opacity: 1;
            }

            .card__title:not(.card__title--no-link) span {
                border-color: currentColor;
            }
        }
    }
}


.card__view-more {
    text-align: right;
    padding-left: 1rem;
    white-space: nowrap;
}

.card__cta {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 40px;
    height: 40px;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: color(greys, base);
    border-radius: 50%;

    img.svg,
    svg {
        width: 24px;
        height: 24px;
        fill: #b0b0af;
    }

    span {
        display: block;
        width: 15px;
        height: 15px;
        position: relative;


        &::before,
        &::after {
            content: '';
            background-color: $white;
            position: absolute;
            transition: transform $transition-fast ease-in-out;
            will-change: transform;
        }

        &::before {
            width: 100%;
            height: 2px;
            top: 50%;
            margin-top: -1px;
            left: 0;
        }

        &::after {
            height: 100%;
            width: 2px;
            top: 0;
            left: 50%;
            margin-left: -1px;
        }
    }
}

.card--nav {

    .sub-nav-list & {
        break-inside: avoid;
        padding-bottom: .8rem;
    }


    .card__figure {
        @include keep-ratio("2/1");

        margin-bottom: $root-vgrid/4;
    }
    .card__body {
        font-size: rem(18px);
        // font-weight: 700;
        margin: 0;
        padding: 0 0 .5em;
        line-height: 1.2;
    }

    .h-vspacing-mb-half {
        span {
            line-height: 1.3;
            display: inline-block;
            border-bottom: 1px solid transparent;
            transition: border $transition-fast ease-in-out;
        }
    }

    .card__title {
        margin-top: $root-vgrid/4;
        font-size: rem(14px);
    }

    a {
        display: block;
        font-size: .875em;
        padding: 0 0 .25em;
        color: inherit;

        &:hover {
            color: black;

            .h-vspacing-mb-half span {
                border-color: currentColor;
            }
        }
    }
}


@media screen and (min-width: 1025px) and (min-height: 850px) {
    .sub-nav-list .card--nav {
        padding: 0 ($grid-gutter-width * .5) 1.5em;
    }
}

.card--blogpost .card__figure {
    @include keep-ratio("16/9");
}

.card--blogpost .card__figure--zm [gr-lazyloader] {
    @include keep-ratio("148/51");
}

.card--product {
    .card__figure {
        @include keep-ratio("1/1");
    }

    .card__body {
        margin: 0;
    }

    &:hover .card__figure img {
        transform: scale(1);
    }

    .slide__donkeytail & {
        .card__body {
            background: $white;
            padding: $root-padding * .5;
            width: 400px;
            display: none;
            min-height: 90px;
            margin: 0;
            align-items: center;
            justify-content: flex-start;
            z-index: 9;
        }

        .card__cta {
            background-color: black;
        }

        //&:hover,
        &.is-active {
            .card__cta span {
                &::before { transform: rotate(180deg); }
                &::after { transform: rotate(90deg); }
            }

            .card__body { display: flex; }
        }
    }

    .dt-r & .card__body { padding-right: calc(90px + #{$root-padding} * .5); }

    .dt-l & .card__body { padding-left: calc(90px + #{$root-padding} * .5); }

    .dt-b.dt-r & .card__cta {
        padding: 45px 0 0 45px;
        bottom: 0;
        left: auto;
        top: auto;
        right: 0;

        &::before { border-color: transparent transparentize($color-primary, .2) transparentize($color-primary, .2) transparent; }
    }

    .dt-t.dt-l & .card__cta {
        padding: 0 45px 45px 0;
        bottom: auto;
        left: 0;
        top: 0;
        right: auto;

        &::before { border-color: transparentize($color-primary, .2) transparent transparent  transparentize($color-primary, .2); }
    }

    .dt-t.dt-r & .card__cta {
        padding: 0 0 45px 45px;
        bottom: auto;
        left: auto;
        top: 0;
        right: 0;

        &::before { border-color: transparentize($color-primary, .2) transparentize($color-primary, .2) transparent transparent; }
    }
}

.card--gallery {

    .card__figure {
        position: relative;
    }

    .card__cta {
        left: 0;
        right: auto;
        top: 0;
        bottom: auto;
        width: 100%;
        height: 100%;
        border-radius: 0;
        background-color: transparent;

        &::before,
        &::after {
            display: none;
        }
    }

    .card__options-container {
        z-index: 11;
    }

    // Show on hover
    // .card__figure-icon {
    //     opacity: 1;
    // }

    .card__body .typography {
        margin-bottom: -$root-vgrid;
        font-size: em(14px);
    }

    .card__footer {
        display: flex;
        flex-wrap: wrap;

        .btn--primary,
        .gr-form__field--inline {
            flex: 0 0 auto;
            margin-bottom: .5em;
        }
    }

    .form-blog__categories .btn--primary {
        margin-right: $root-hgrid;
    }

    &:hover {
        .card__figure img {
            transform: none;
        }
    }
}

.card--announcement {
    background-position: center;
    background-size: cover;

    .card__text {
        min-height: 250px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 2rem 1rem;
        background-color: rgba(255, 255, 255, 0.8);

        @include mq(md) {
            padding: 2rem 3.5rem;
        }

        @include mq(lg) {
            padding: 2rem 5rem;
        }

        &.typography *:last-child {
            margin-bottom: 0;
        }
    }

    + .card--announcement {
        margin-top: 1.5rem;
    }
}

.counter span::before {
    content: attr(data-counter);
}
