.zm-card-memo {
    position: relative;
    display: block;
    padding: 1rem;
    color: $root-color;
    box-shadow: 0 4px 30px rgba(0, 0, 0, .1);

    @include mq(sm) {
        padding: $grid-gutter-width*1.2;
        display: flex;
        align-items: center;
    }

    .zm-card-memo__column {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;

        &:nth-child(2) {
            margin-top: $root-vgrid/2;

            @include mq(sm) {
                flex-grow: 0;
                margin-top: 0;
                white-space: nowrap;
                padding-left: 2rem;
            }
        }
    }

    .link {
        color: color(brand, primary);
    }
}

.zm-card-memo + .zm-card-memo {
    margin-top: $root-vgrid/1.5;
}

.zm-card-memo__date {
    color: transparentize(black, .7);
    margin-bottom: $root-vgrid/6;
}

.zm-card-memo__featured {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: color(member-zone, red);

    @include mq(sm) {
        left: 1rem;
        right: auto;
    }
}

.zm-card-memo__title {
    font-weight: 600;
    @include mq(sm) {
        font-size: rem(24px);
    }
}
