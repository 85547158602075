// Custom Radio
// --------------------------------------------------
// @example html - Markup
//  <div class='form-item | form-item--inline'>
//      <input type='radio' name='' id=''>
//      <label for=''> ... </label>
// </div>

@mixin form-radio($class: '.gr-radio', $radio-size: $form-element-height/2, $radio-color: color(brand, secondary)) {

    input[type=radio] {
        display: block;
        opacity: 0;
        position: absolute;
        left: ($radio-size/2);
        top: ($radio-size/2);
        transform: translate(-50%, -50%);
        width: auto;
        height: auto;

        & + label {
            position: relative;
            display: inline-block;
            padding-left: $radio-size * 1.25;
            line-height: $radio-size;
            min-height: $radio-size;
            cursor: pointer;
            color: $root-color;

            &::before,
            &::after {
                display: block;
                content: '';
                position: absolute;
            }

            &::before {
                top: 0;
                left: 0;
                height: $radio-size;
                width: $radio-size;
                background-color: $form-element-background-color;
                border: 1px solid $form-element-border-color;
                transition: border-color $transition-fast ease-in-out;
                border-radius: 50%;
            }

            &::after {
                opacity: 0;
                top: $radio-size * .25;
                left: $radio-size * .25;
                height: $radio-size * .5;
                width: $radio-size * .5;
                color: $radio-color;
                border-radius: 100%;
                background-color: $radio-color;
                transition: all $transition-fast ease-in-out;
                transform: scale(0);
            }

            &:hover::before {
                border-color: $radio-color;
            }
        }

        &:focus + label:before {
            outline: 1px dashed color(greys, base);
        }

        &:checked + label {
            &::after {
                opacity: 1;
                transform: scale(1);
            }
        }
    }
}
