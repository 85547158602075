.sharing-feature {
    padding-top: $root-vgrid/1.5;
    padding-bottom: $root-vgrid/1.5;
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;
    background-color: color(greys, xlight);

    @include mq(sm) {
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .title {
        margin-bottom: $root-vgrid/2;

        @include mq(sm) {
            margin-bottom: 0;
            margin-right: 1rem;
        }
    }

    .social-icons--share {
        margin: 0;

    }
}
