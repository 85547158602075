.objectfit {
    background-position: center;
    background-size: cover;
}

.objectfit img {
    display: none;
}

.main {
    position: relative;
    width: 100%;
    //z-index: 1;
    min-height: 45vh;
}

.main--landing,
.main--member {
    min-height: 100vh;
    color: $white;
    background-image: url(/dist/img/artopex_showroom_v2.jpg);
    background-attachment: fixed;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(0, 0, 0, .5);
        content: "";
        z-index: -1;
    }

    [gr-grid="container"] {
        position: relative;
        z-index: 1;
    }

    .title--arrow {
        color: $white;

        &::before {
            color: $color-primary;
        }
    }
}


.main--landing {
    padding-bottom: 6rem;

    footer {
        position: absolute;
        bottom: -5rem;
        left: 0;
        right: 0;
        padding: $grid-gutter-width * .5 $grid-gutter-width;
        text-align: left;

        .legal {
            font-size: em(12px);
            color: $white;
            float: left;
            margin-right: 1em;

            &:last-child {
                float: right;
                margin: 0;
            }

        }
    }
}

.main--member {

    padding: 3rem 3rem 6rem;

    .typography a:not([class]) {
        text-decoration: underline;
        color: inherit;
    }

    @include mq(md) {
        padding: 3rem 3rem 3rem calc(20% + 3rem);
    }

    .gr-form .gr-select {
        max-width: calc(100% / 3);
    }

    .tab,
    .card--blogpost {
        background-color: rgba(color(greys, xlight), .9);
        padding: 1rem 2rem;
        color: $black;
        width: auto;

        & + .card--blogpost,
        & + .tab {
            margin-top: 40px;
        }


    }

    .tabs--faq {
        width: 100%;
        max-width: 100%;
        @include mq(md) {
            max-width: 50%;
            margin-left: 0;
        }

        .tab-link {
            color: $black;
        }

    }

    .tab-content,
    .typography table tbody td,
    .table tbody td {
        border-color: transparent;
        background-color: transparent;
    }

    .pagination {
        &__btn--prev,
        &__btn--next,
        &__link,
        &__link--current,
        &__link--disabled {
            color: $white;
        }

        &__link--current {
            color: $root-color;
            background-color: $color-primary;
        }
    }

    footer {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: $grid-gutter-width * .5;
        text-align: center;

        .legal {
            display: block;
            font-size: em(12px);
            color: $white;
        }

        @include mq(md) {
            display: none;
        }
    }
}

.product-image-main {
    max-height: rem(560px);
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}

[gr-full-height="true"] {
    @include mq(sm) {
        height: calc(100vh - 6rem);
    }
}


.logo-print {
    display: none;
}
