// Different layout for Search page

.l-search-listing {

    .card {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: $root-vgrid / 2;
        padding-bottom: $root-vgrid / 2;
        border-bottom: 1px solid color(greys, light);
        @include mq(sm) {
            margin-bottom: $root-vgrid;
            padding-bottom: $root-vgrid;
            &:first-of-type {
                border-top: 1px solid color(greys, light);
                padding-top: $root-vgrid;
            }
        }

        .card__figure {
            flex-basis: 40%;
            max-width: 40%;
        }
        .card__body {
            padding-left: $root-vgrid / 2;
            flex-basis: 60%;
            max-width: 60%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            display: flex;
            align-items: center;

            @include mq(lg) {
                padding-left: $root-vgrid;

                .card__inner {
                    flex-basis: 100%;
                    padding-right: $root-vgrid;
                }
            }

            @include mq(xl) {
                .card__inner {
                    flex-basis: 80%;
                }

                .card__btn {
                    flex-basis: 20%;
                }
            }
        }
    }

    @include mq(sm, 1) {
        .card {
            .card__title {
                font-size: em(16px);
                margin-bottom: $root-vgrid  / 3;
            }

            .typography {
                display: none;
            }

            .card__btn {
                display: none;
            }
        }
    }


    .card__title + div {
        @include mq(md) {
            margin-bottom: $root-vgrid;
        }
    }

    .card__figure {
        @include keep-ratio('1/1');
        @include change-ratio('4/3', sm);

        &--download {
            background-color: color(greys, xlight);
            img {
                max-width: 30px;
                height: auto;
                display: block;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                @include mq(sm) {
                    max-width: 50px;
                }
            }
        }
        img {
            object-fit: contain;
            font-family: contain;
        }
    }


    .card__btn {
        @include mq(lg) {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}


/* New layout for find us page */

.l-find-us {


    .section--banner {
        @include mq(sm, 1) {
            .banner {
                margin-bottom: $root-vgrid / 2;
            }
        }
    }

    .section--dealers {
        .h1 {
            @include mq(md, 1) {
                text-align: center;
            }
        }
    }

    .section--addresses {

        .form--dealers__results {
            max-height: none;

            .title {
                font-size: em(18px);
            }

            address {
                a {
                    display: block;
                    color: color(greys, base);

                }

                svg {
                    min-width: 15px;
                }
            }
        }
    }

    .form--dealers__results {
        address {

            svg {
                min-width: 15px;
            }
        }
    }

    [gr-expander-trigger] {
        @include mq(md) {
            display: none;
        }
    }

    [gr-expander-trigger="active"] {
        span {
            transform: rotate(-180deg);
        }
    }

    [gr-expander-target] {
        display: none;
    }

    [gr-expander-target="active"] {
        display: block;
    }

    @include mq(md) {
        [gr-expander-target],
        [gr-expander-target="active"] {
            display: block;
        }
    }

    [gr-expander-trigger].btn--primary {
        span {
            font-size: em(10px);
            margin-right: em(5px);
            display: inline-block;
        }
    }
}


/* Different styles for header and search bar on mobile */

.search-is-open {
    @include mq(lg, 1) {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
    }

    @include mq(md) {
        .nav__top {
            position: relative;
        }
    }
}

.nav {
    @include mq(lg, 1) {
        button {
            left: 0;
            right: inherit;
        }
    }

    > [gr-grid="container"] {

        @include mq(md, 1) {
            display: flex;
        }
    }

    .search-is-mobile {
        @include mq(md) {
            display: none;
        }

        .nav__link {
            display: flex;
            position: relative;
            align-items: center;
            position: absolute;
            height: 100%;
            right: 15px;
            top: 0;
            z-index: 9999;
        }

        .gr-form__field--inline {
            flex-direction: column;
        }

        .search-cta .gr-form__field {
            transform: scale3d(0, 1, 1) translateY(0);
            top: 0;
            padding-left: 15px;
            padding-right: 15px;
            background-color: #ededed;
            height: calc(100vh + #{map-get($nav-options, mobile-height)});
            padding-top: $root-vgrid;
        }

        .search-cta[gr-search="active"] .gr-form__field {
            transform: scale3d(1, 1, 1) translateY(0);
        }

        .search-cta {
            h2 {
                padding-bottom: $root-vgrid / 2;
                border-bottom: black 1px solid;
            }
        }
    }

    .search-is-desktop {
        display: none;

        @include mq(md) {
            display: flex;
            position: static;
            align-items: center;
        }
    }
}


.card__title--details {
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
        color: color(brand, primary);
    }
}

.logo--product {
    max-width: 100px;
    max-height: 30px;
    margin-top: $root-vgrid / 3;

    @include mq(md) {
        max-width: 130px;
        max-height: 40px;
    }
}
