
.zm-footer {
    text-align: center;
    padding-top: $root-vgrid/2;
    padding-bottom: $root-vgrid/2;
    color: color(member-zone, grey-dark);
    font-size: rem(14px);

    @include mq(xs) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        > div:nth-child(1) {
            flex-basis: 100%;
        }
    }

    @include mq(md) {
        padding-top: $root-vgrid;
        padding-bottom: $root-vgrid;

        > div:nth-child(1) {
            flex-basis: auto;
        }
    }

    * {
        padding-left: .5rem;
        padding-right: .5rem;
    }

    a {
        color: currentColor;
    }

}


.zm-site-container .zm-footer {
    @include mq(md) {
        padding-left: 145px;
    }

    @include mq(lg) {
        padding-left: 210px;
    }
}
