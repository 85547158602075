// Socials
// --------------------------------------------------------------------------

.social-icons--link {
    text-align: left;
    @include mq(md, 1) {
        flex-grow: 1;
        padding-left: 1.5rem;
    }

    a {
        color: $black;
        position: relative;
        display: block;
        padding-top: .5em;
        line-height: 1;
        @include mq(md) {
            display: inline;
            padding-top: 0;
        }

        span {
            line-height: 1.3;
            display: inline-block;
            border-bottom: 1px solid transparent;
            transition: border $transition-fast ease-in-out;
        }

        @include mq(md) {
            text-align: center;
            margin-left: 1.5em;
            &:hover {
                span {
                    border-color: currentColor;
                }
            }

            &:first-child {
                margin-left: 0;
            }
        }
    }
}

.social-icons--share {
    @include socials('background', $white);
    font-size: .75em;
    display: inline-block;

    a {
        color: $white;
        padding: ($root-padding * .25) ($root-padding * .5) ($root-padding * .25) ($root-padding * .25);
        margin: .25em .25em .25em 0;

        &::before {
            height: 24px;
            width: 24px;
        }

        span {
            padding-left: ($root-padding * .25);
        }
    }
}
