// Banners
// --------------------------------------------------------------------------

@include banner('.banner', 820px, 6rem);
@include banner('.banner--inpage', 820px, 6rem);
@include banner('.banner--toppage', 240px, 6rem);
@include banner('.banner--cta-full', 500px, 0rem);

.banner__inner {
    background-color: rgba(255, 255, 255, .75);
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    padding: $root-vgrid $root-hgrid;
    color: $black;
    width: auto;

    @include mq(sm) {
        max-width: 480px;
    }
}

.banner--right {
    justify-content: flex-start !important;
}

.banner__inner--right {
    @include mq(md) {
        margin-left: 4rem;
    }

    @include mq(lg) {
        margin-left: 8rem;
    }
}

.banner__inner--large {
    width: 100%;
    max-width: 1005px;

    padding: 1rem;

    @include mq(sm) {
        margin-left: $grid-gutter-width/2;
        margin-right: $grid-gutter-width/2;
    }

    @include mq(md) {
        padding: 1rem 3rem;
    }
}

.banner__inner--medium {
    width: 100%;
    max-width: 960px;

    padding: 1rem;

    @include mq(sm) {
        margin-left: $grid-gutter-width/2;
        margin-right: $grid-gutter-width/2;
    }

    @include mq(md) {
        padding: 3rem;
    }
}

.banner--contact {
    .banner__inner {
        text-align: left;
    }
}

.banner--inpage .banner__inner {
    text-align: center;

    .banner__title {
        margin-bottom: 3rem;
    }

}

.banner--inpage-reversed {

    @include mq(sm, 1) {
        display: flex;
        flex-wrap: wrap;

        &::after {
            display: none;
        }

        .banner__cover {
            @include keep-ratio("16/9");
        }

        .banner__inner {
            max-width: 100%;
            padding: 0;
            margin: 0;
        }

        > div {
            flex-grow: 1;
            &:first-child {
                margin-bottom: $root-vgrid;
            }
        }

        .ressources__list {
            & + div {
                text-align: center;

                a {
                    width: auto;
                }
            }
        }

        .ressources__trigger.h1 {
            display: none;
        }
    }
    @include mq(sm) {
        .ressources__list {
            margin-top: em(15px);
        }
    }
}

.banner--hide-typography {
    @include mq(sm, 1) {
        .typography {
            display: none;
        }
        .banner__inner {
            margin-top: 0;
            margin-bottom: 0;
            width: 100%;

            .h1 {
                margin-bottom: $root-vgrid / 2;
            }
        }
    }
}

.banner--values {
    counter-reset: values;

    .value__bullet {
        width: 40px;
        height: 40px;
        min-width: 40px;
        border-radius: 50%;
        margin-left: auto;
        margin-right: auto;
        background-color: color(brand, primary);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: $root-vgrid/2;

        &::before {
            display: block;
            counter-increment: values;
            content: counter(values);
            font-weight: 600;
            color: $white;
        }

    }
}

.banner--values .banner__inner {
    text-align: center;
    background-color: transparent;

    @include mq(sm) {
        max-width: 100%;
        width: 100%;
    }

    .value {
        display: block;
        width: 100%;
        background-color: rgba(255, 255, 255, .9);
        padding: $root-vgrid $root-hgrid 1rem;
        color: $black;
    }
}

.banner--values-alt-display {
    &.banner--inpage {
        .banner__inner {
            @include mq(sm, 1) {
                padding-top: 0;
                .value {
                    background-color: #ededed;
                    padding: 0 $root-vgrid / 2;
                    margin-bottom: $root-vgrid;
                    flex-wrap: nowrap;
                    flex-direction: column;
                    .h1 {
                        text-align: center;
                        padding-left: 0;
                    }

                    .value__bullet {
                        margin-left: auto;
                        margin-bottom: 0;
                        transform: translateY(-50%);
                    }

                    .typography {
                        flex: 1 0 auto;
                        width: 100%;
                    }
                }
            }
        }
    }
}

@include mq(md, 1) {
    .banner--inpage {
        display: block;
        min-height: 0;

        &::after {
            display: none;
        }

        .banner__cover {
            @include keep-ratio("16/9");
        }

        .banner__inner {
            max-width: 100%;
            padding: 0;
            margin: 0;
            padding-top: $root-vgrid/2;
        }

        .banner__inner .value {
            background-color: transparent;
            text-align: left;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding: 0;

            .h1 {
                flex-grow: 1;
                padding-left: 1rem;
            }

            .typography {
                flex-basis: 100%;
            }
        }

        .value__bullet {
            margin-left: 0;
            flex-grow: 0;
            flex-basis: 40px;
        }
    }
}

.banner--login {
    min-height: 100vh;
    color: $white;

    .banner__inner {
        width: 90%;
        max-width: 440px;
        background-color: white;
        color: color(greys, xdark);

        @include mq(sm) {
            padding-left: $root-vgrid*2;
            padding-right: $root-vgrid*2;
        }
    }

    .logo {
        width: 100%;
        height: 52px;
    }

    .title {
        color: color(member-zone, grey-dark);
        line-height: 1.2;
        font-weight: 400;
        margin-bottom: $root-vgrid;
    }

    a {
        color: color(brand, primary);
    }

    .gr-form__field {
        margin-top: 0;
        margin-bottom: $root-vgrid/1.5;
    }

    hr {
        margin-top: $root-vgrid/2;
        margin-bottom: $root-vgrid/2;

    }

    .banner__inner svg path,
    .banner__inner img {
        fill: $white !important;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: $root-vgrid;
    }

    .gr-form {
        text-align: left;

        label {
            color: #7f7f7f;
        }
    }

    .gr-form input {
        background-color: #f8f8f8;
    }
}


.banner--sidebyside {
    position: relative;
}

.banner--sidebyside [gr-grid~=row] {

    @include mq(sm) {
        flex-direction: row;
        align-items: center;
    }
}

.banner--sidebyside-left [gr-grid~=row] {
    @include mq(sm) {
        flex-direction: row-reverse;
    }
}

.banner--sidebyside:nth-child(even) [gr-grid~=row],
.banner--sidebyside--inverted [gr-grid~=row] {

    @include mq(sm) {
        flex-direction: row-reverse;

        .typography {
            text-align: right;
        }

        .h1 {
            text-align: right;
        }
    }
}

.banner--sidebyside__title {

    & + .typography {
        margin-top: $root-vgrid;
    }
}

.banner--sidebyside__picture {
    position: relative;
    align-self: stretch;
    z-index: 0;

    .banner--sidebyside__picture__wrapper {

        @extend %img-cover;
        @include keep-ratio("11/4");

        &[gr-video-bg],
        .section-vt & {
            @include keep-ratio("16/9");
            left: 0;
            right: 0;
        }

        @include mq(sm) {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;

            img {
                left: $grid-gutter-width * .5;
                right: $grid-gutter-width * .5;
                width: calc(100% - #{$grid-gutter-width});
            }
        }
    }
}

.banner--sidebyside__content {

    padding-top: $root-vgrid;
    padding-bottom: $root-vgrid;

    @include mq(sm) {
        padding-top: (2 * $root-vgrid);
        padding-bottom: (2 * $root-vgrid);
        // margin-right: (2 * $root-vgrid);
        // margin-left: (2 * $root-vgrid);
    }

    .video-cta-link {
        display: block;
    }
}

.section-vt,
.section-video {
    .banner--sidebyside__picture__wrapper {
        @include keep-ratio("16/9");
    }

    iframe {
        height: 100%;
        width: 100%;
    }

    .objectfit {
        iframe {
            opacity: 0;
            display: none;

            &.is-active {
                opacity: 1;
                display: block;
            }
        }
    }

    .banner--sidebyside__picture.is-active .banner--sidebyside__picture__wrapper::after {
        transition: left $transition-normal ease-in-out;
        left: 100%;
    }
}

.banner--cta-full {
    .banner--cta-full__cover {
        @include mq(md, 1) {
            height: auto;
            position: relative;
            &::after {
                content: "";
                display: block;
                width: 100%;
                padding-top: 350px;
            }
        }
    }
}

.section-video {
    [gr-grid=container] {
        max-width: 1490px;
    }
}

// banner-mini
// -------------------------------------------- 
.banner-mini {

    &__col-content {
        padding: 20px !important;
        background-color: color(greys, xlight);

        @include mq(md) {
            padding: 40px 80px !important;
        }

    }

    &__col-image {
        display: none !important;
        padding: 0 !important;

        @include mq(md) {
               display: block !important;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center top;
        }
    }

    .order-1 {
        order: 1;
    }

    .order-2 {
        order: 2;
    }
    
}

