// ==========================================================================
// Mixins
// ==========================================================================

// Media-Queries
// --------------------------------------------------------------------------
//  @example scss - Usage
//      @include mq(sm);
//
//  @example css - Output
//     @media (min-width: 768px) {}
//
//
//  @example scss - Usage
//      @include mq(sm, 1);
//
//  @example css - Output
//     @media (max-width: 767px) {}
//
//
//  @example scss - Usage
//      @include mq(sm, md);
//
//  @example css - Output
//    @media (max-width: 1023px) and (min-width: 768px) {}
//
@mixin mq($breakpoint, $max-bp: 0) {
    $default: map-get($grid-breakpoints, $breakpoint);

    @if ($default) {
        // min width only
        @if ($max-bp == 0) {
            @media (min-width: em($default)) { // stylelint-disable-line
                @content;
            }
        }
        // max width only
        @else if ($max-bp == 1) {
            @media (max-width: em(($default - 1px))) { // stylelint-disable-line
                @content;
            }
        }
        // max width and min width
        @else {
            $max: map-get($grid-breakpoints, $max-bp);
            @if ($max) {
                @media (max-width: em(($max - 1px))) and (min-width: em($default)) { // stylelint-disable-line
                    @content;
                }
            }

            @else {
                @warn 'Unfortunately, no value could be retrieved from `#{$max-bp}`. '
                    + 'Please make sure it is defined in `$grid-breakpoints` map '
                    + 'or use 1 for max-width only';
            }
        }
    }
    @else {
        @warn 'Unfortunately, no value could be retrieved from `#{$breakpoint}`. '
            + 'Please make sure it is defined in `$grid-breakpoints` map.';
    }
}

// Clearfix
// --------------------------------------------------------------------------
@mixin clearfix {
    &::after {
        clear: both;
        content: '';
        display: table;
    }
}

// Font-face
// --------------------------------------------------------------------------
//  @example scss - Usage
//      @include font-face(fontName, #{$font__path}, bold);
//      @include font-face(fontName, #{$font__path}, normal, italic);

@mixin font-face ($font-family, $filename, $weight: normal, $style: normal) {
    @font-face {
        font-family: $font-family;
        src: url($filename + '.woff');
        font-weight: $weight;
        font-style: $style;
    }
}

// Keep ratio
// --------------------------------------------------------------------------
// @example scss - Usage
//     figure {
//         @include keepRatio('16/9');
//
//         img,
//         iframe {
//             ...
//         }
//     }

@mixin keep-ratio($ratio: '1/1') {
    @extend %img-cover;

    $ratio1: str-slice($ratio, 1, (str-index($ratio, '/') - 1));
    $ratio2: str-slice($ratio, (str-index($ratio, '/') + 1));

    position: relative;
    overflow: hidden;

    &::before {
        content: '';
        display: block;
        width: 100%;
        padding-top: calc(#{$ratio2} * 100 / #{$ratio1} * 1%);
    }

    & > img,
    & > figure,
    & > picture,
    & > iframe,
    & > video,
    & > objectfit {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }
}

@mixin change-ratio($ratio: '1/1', $breakpoint: '') {
    $ratio1: str-slice($ratio, 1, (str-index($ratio, '/') - 1));
    $ratio2: str-slice($ratio, (str-index($ratio, '/') + 1));

    $default: map-get($grid-breakpoints, $breakpoint);
    @if ($default) {
        @include mq($breakpoint) {
            &::before {
                padding-top: calc(#{$ratio2} * 100 / #{$ratio1} * 1%);
            }
        }
    }
    @else {
        @warn 'Unfortunately, no value could be retrieved from `#{$breakpoint}`. '
            + 'Please make sure it is defined in `$grid-breakpoints` map.';
    }
}

// Placeholder prefixer
// --------------------------------------------------------------------------
// @example scss - Usage
//     input {
//         @include placeholder { color: red; }
//     }

@mixin placeholder {
    $placeholders: ':-webkit-input' ':-moz' '-moz' '-ms-input';

    @each $placeholder in $placeholders {
        &:#{$placeholder}-placeholder { @content; }
    }
}



@mixin helper-vspacing($level: 1, $type: 'margin') {
    $prefix: 'm';

    @if ($type == 'padding') {
        $prefix: 'p';
    }

    .h-vspacing-#{$prefix}-half {
        #{$type}-top: $root-vgrid / 2;
        #{$type}-bottom: $root-vgrid / 2;
    }

    .h-vspacing-#{$prefix}t-half {
        #{$type}-top: $root-vgrid / 2;
    }

    .h-vspacing-#{$prefix}b-half {
        #{$type}-bottom: $root-vgrid / 2;
    }

    @for $i from 1 through $level {
        .h-vspacing-#{$prefix}-#{$i} {
            #{$type}-top: $root-vgrid;
            #{$type}-bottom: $root-vgrid;

            @if ($i != 1) {
                @include mq(sm) {
                    #{$type}-top: $root-vgrid * $i;
                    #{$type}-bottom: $root-vgrid * $i;
                }
            }
        }

        .h-vspacing-#{$prefix}t-#{$i} {
            #{$type}-top: $root-vgrid;

            @if ($i != 1) {
                @include mq(sm) {
                    #{$type}-top: $root-vgrid * $i;
                }
            }
        }

        .h-vspacing-#{$prefix}b-#{$i} {
            #{$type}-bottom: $root-vgrid;

            @if ($i != 1) {
                @include mq(sm) {
                    #{$type}-bottom: $root-vgrid * $i;
                }
            }
        }
    }
}
