// ==========================================================================
// Buttons
// ==========================================================================

@mixin btn($class: '.btn') {
    #{$class} {
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
        user-select: none;
        overflow: hidden;
        position: relative;

        &[disabled] {
            cursor: not-allowed;
            pointer-events: none;
            opacity: .25;
        }
    }
}

// the size
@mixin btn-size(
    $btn-font-size:     $root-font-size,
    $btn-padding-y:     $root-padding,
    $btn-padding-x:     $root-padding,
    $btn-border:        0,
    $btn-radius:        0
) {
    font-size: $btn-font-size;
    padding: $btn-padding-y $btn-padding-x;
    @if ($btn-border) {
        border: $btn-border;
    }
    @if ($btn-radius) {
        border-radius: $btn-radius;
    }
}

@mixin btn-colors(
    $btn-font-color:    $white,
    $btn-bg-color:      color(brand, primary),
    $btn-border-color:  currentColor
) {
    color: $btn-font-color;
    background-color: $btn-bg-color;

    @if ($btn-border-color) {
        border-color: $btn-border-color;
    }
}
