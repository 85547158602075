.card-press-release {
    padding: 1rem;
    background-color: color(greys, xlight);
    @include mq(sm) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 2rem;
        padding-right: 2rem;
    }
    @include mq(md) {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        padding-left: 3rem;
        padding-right: 3rem;
    }
}

.card-press-release__title {
    line-height: 1.16;
    margin-bottom: $root-vgrid/2;
    @include mq(sm) {
        margin-bottom: 0;
    }
}

.card-press-release__date {
    color: transparentize(black, .7);
    margin-bottom: $root-vgrid/4;
}

.card-press-release__button {
    white-space: nowrap;
    @include mq(sm) {
        padding-left: $grid-gutter-width;
    }
}

.card-press-release + .card-press-release {
    margin-top: $root-vgrid/3;
}
