// ==========================================================================
// Form
// ==========================================================================

// $form-element-background-color: if(variable-exists(form-element-background-color), $form-element-background-color, transparent);
// $form-element-height: if(variable-exists(form-element-height), $form-element-height, 2.75rem);
// $form-element-radius: if(variable-exists(form-element-radius), $form-element-radius, 0);
// $form-element-border-style: if(variable-exists(form-element-border-style), $form-element-border-style, solid);
// $form-element-border-color: if(variable-exists(form-element-border-color), $form-element-border-color, #ccc);
// $form-element-border-width: if(variable-exists(form-element-border-width), $form-element-border-width, 1px);
// $form-element-focus-color: if(variable-exists(form-element-focus-color), $form-element-focus-color, color(brand, primary));
// $form-element-padding: if(variable-exists(form-element-padding), $form-element-padding, 1em);

%input {
    @include placeholder {
        color: currentColor;
        opacity: 1;
        font-style: normal;
        transition: opacity $transition-fast;
    }

    height: $form-element-height;
    line-height: inherit;
    background-color: $form-element-background-color;
    width: 100%;
    padding: 0 $form-element-padding;

    @if $form-element-border-width {
        border-width: $form-element-border-width;
        border-style: $form-element-border-style;
        border-color: $form-element-border-color;
    }

    @if $form-element-radius {
        border-radius: $form-element-radius;
    }

    &:focus {
        @include placeholder {
            opacity: .75;
        }

        border-color: $form-element-focus-color;
    }
}

@mixin form-layout($class: $form-class) {

    #{$class}__field,
    #{$class}__field--icon-before,
    #{$class}__field--icon-after,
    #{$class}__field--inline {
        position: relative;
        margin-bottom: $form-element-margin;

        input[type='text'],
        input[type='password'],
        input[type='email'],
        input[type='search'],
        input[type='date'],
        input[type='tel'],
        textarea {
            @extend %input;
        }

        textarea {
            height: $form-element-height * 4;
            padding: $form-element-padding;
        }
    }

    #{$class}__field--icon-before,
    #{$class}__field--icon-after,
    #{$class}__field--inline {
        display: flex;
        flex-direction: row;

        input[type='text'],
        input[type='password'],
        input[type='email'],
        input[type='search'],
        input[type='date'],
        input[type='tel'],
        textarea {
            width: auto;
            flex: 1 0 auto;
            order: 0;
        }
    }

    #{$class}__field--icon-before,
    #{$class}__field--icon-after {
        flex-wrap: wrap;
        align-items: stretch;

        label {
            flex: 1 0 100%;
            order: -2;
            display: block;
        }

        input:not([type='submit']),
        textarea {
            &:focus ~ .icon {
                border-color: $form-element-focus-color;
                color: $form-element-focus-color;
            }
        }

        .icon {
            flex: 0 0 0;
            line-height: inherit;
            width: $form-element-height;
            height: $form-element-height;
            background-color: $form-element-background-color;
            padding: calc((#{$form-element-height} - #{$root-line-height}em) * .5);

            @if $form-element-border-width {
                border-width: $form-element-border-width;
                border-style: $form-element-border-style;
                border-color: $form-element-border-color;
            }
            @if $form-element-radius {
                border-radius: $form-element-radius 0 0 $form-element-radius;
            }

            img {
                width: ($root-line-height * 1em);
                height: ($root-line-height * 1em);
            }
        }
    }

    #{$class}__field--icon-before {
        input:not([type='submit']),
        textarea {
            padding: 0 $form-element-padding 0 0;

            @if $form-element-radius {
                border-radius: 0 $form-element-radius $form-element-radius 0;
            }
        }

        .icon {
            order: -1;
            @if $form-element-radius {
                border-right: 0;
            }
            @if $form-element-radius {
                border-radius: $form-element-radius 0 0 $form-element-radius;
            }
        }
    }

    #{$class}__field--icon-after {
        input:not([type='submit']),
        textarea {
            padding: 0 0 0 $form-element-padding;

            @if $form-element-radius {
                border-radius: $form-element-radius 0 0 $form-element-radius;
            }
        }

        .icon {
            order: 1;

            @if $form-element-radius {
                border-left: 0;
            }

            @if $form-element-radius {
                border-radius: 0 $form-element-radius $form-element-radius 0;
            }
        }
    }

    #{$class}__field--inline {
        align-items: center;
        flex-wrap: wrap;

        input:not([type='submit']),
        textarea {
            flex: 1 1 auto;
        }

        label {
            flex: 0 1 auto;
            line-height: inherit;
            margin-right: $grid-gutter-width;
        }
    }
}
