
.section-cta  {
    .section-cta__banner {
        height: 300px;
        background-position: center;
        background-size: cover;

        @include mq(lg) {
            height: 500px;
        }
    }
}

.section-cta__body {
    margin-top: $root-vgrid;
    margin-bottom: $root-vgrid*2;

    @include mq(sm) {
        display: flex;
        margin-top: $root-vgrid*1.5;
        margin-bottom: $root-vgrid*3;

        > div {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: 50%;
            max-width: 50%;
        }
    }
}

.section-cta__title-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    @include mq(md) {
        justify-content: center;
    }


    @include mq(lg) {
        padding-left: 12rem;
    }
}

.section-cta__text {
    margin-top: $root-vgrid;

    @include mq(sm) {
        margin-top: 0;
    }

    @include mq(lg) {
        padding-right: 8rem;
    }
}
