// ==========================================================================
// Artopex.css, powered by groots
// Author: Globalia
// Licensed under the WTFPL license
// ==========================================================================


// groots core !! DO NOT DELETE !!
// --------------------------------------------------------------------------

// Import all groots SCSS dependancies
@import "generated/_iconfont.scss";
@import 'groots';
// All the code here is here for showcase purpose.
// It shows the best effective way to code in groots philosophy.


// ==========================================================================
// Getting started
// ==========================================================================

// Import vendors SCSS dependancies
@import '../../../node_modules/slick-carousel/slick/slick.scss';
// Redefines variables and/or create aliases
// You can do it here or directly in variables.scss

$root-radius: 0;

$white: color(root, white);
$black: color(root, black);
$color-primary: color(brand, primary);
$color-secondary: color(brand, secondary);
$color-error: color(validation, error);
$color-success: color(validation, success);

// ==========================================================================
// Demo Custom Styles
// ==========================================================================

// Start your custom styling by:
// 1 - If it exists, including a groots mixin with the corresponding parameters.
// 2 - Adding complmentary code.

* {
    // outline: 1px dashed grey !important;
}

// Layout: 12-column Responsive Grid
// --------------------------------------------------------------------------

@include grid('gr-grid', 12, $grid-gutter-width, $grid-container-width);

body {
    position: relative;
}

@import '../../../node_modules/magnific-popup/src/css/main.scss';
@import 'vendor/photoswipe.scss';
@import 'vendor/formie.scss';


// Helpers
@import "components/helpers/spacing";
@import "components/helpers/text";
@import "components/helpers/background";
@import "components/helpers/grid";

// Styleguide
@import "styleguide/base";
@import "styleguide/typography";

// Layout
@import "layout/base";
@import "layout/main";
@import "layout/algolia";
@import "layout/career";

// Components
@import "components/timeline";
@import "components/titles";
@import "components/buttons";
@import "components/pagination";
@import "components/forms";
@import "components/socials";
@import "components/navigations";
@import "components/navigation-mobile";
@import "components/headers";
@import "components/footer";
@import "components/banner";
@import "components/cards";
@import "components/gr-mediabox";
@import "components/tabs";
@import "components/dropdown";
@import "components/cta";
@import 'components/sliders';
@import 'components/nav-member';
@import 'components/alert';
@import 'components/twentytwenty';
@import 'components/ressources';
@import 'components/sharing-feature';
@import 'components/find-dealer';
@import 'components/showroom';
@import 'components/section-cta';
@import 'maintenanceEvolution';
@import 'components/zone-membre/zm-download-table';
@import 'components/zone-membre/zm-delay-table';
@import 'components/zone-membre/zm-card-memo';
@import 'components/zone-membre/zm-card-document';
@import 'components/zone-membre/zm-card-evenement';
@import 'components/zone-membre/zm-tabs-trigger';
@import 'components/zone-membre/zm-card-press-release';
@import 'components/zone-membre/zm-nav';
@import 'components/zone-membre/zm-header';
@import 'components/zone-membre/zm-comportement-nav-header';
@import 'components/zone-membre/zm-base';
@import 'components/zone-membre/zm-banner';
@import 'components/zone-membre/zm-evenement-informations';
@import 'components/zone-membre/zm-footer';
@import 'components/zone-membre/zm-navigation-trigger';
@import 'components/video-hero';
@import 'components/modal-landing';
@import 'components/select2';
@import 'components/sigma-rh';
@import 'components/swiper-photoSwipe';
@import 'components/text';
// @import "components/didomi";

@import 'mutation/general';
@import 'mutation/jobs.scss';
@import "mutation/related-products";

@import './utilities';

.newsletter-banner {
    [gr-grid=row] > [gr-grid] {
        padding-left: 0;
        padding-right: 0;
    }

    [gr-grid].newsletter-banner__feature-article {
        position: relative;
        background-position: center;
        background-size: cover;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        color: $white;
        padding: 1rem;
        min-height: 150px;

        @include mq(md) {
            min-height: 0;
        }

        &::before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 0;
            left: 0;
            top: 0;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #000000 100%);
        }

        > * {
            position: relative;
        }

        a {
            color: currentColor;
            border-bottom-color: currentColor;

        }
    }
}

.memo__header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    @include mq(lg) {
        justify-content: flex-end;
    }

    a {
        color: color(brand, primary);
    }

    a + a {
        margin-left: 1rem;
    }

    hr {
        flex-basis: 100%;
        margin-top: $root-vgrid/2;
    }
}

[gr-message] {
    list-style: none;
    display: block;
    padding: .5rem;
    background-color: color(greys, xlight);
}

.hr-print {
    position: relative;

    a {
        position: absolute;
        left: 50%;
        top: -#{$root-vgrid};
        color: color(brand, primary);
        transform: translateX(-50%);

        @include mq(sm) {
            left: auto;
            right: $grid-gutter-width;
            transform: translate(0, -50%);
        }
    }
}

//   margin: $root-vgrid 0;
//       margin: (1.5 * $root-vgrid) 0;

.gr-form--zm {
    .gr-form__field {
        margin-bottom: $root-vgrid/1.5;

        label {
            display: block;
            margin-bottom: $root-vgrid/8;
        }
    }

    .link {
        color: color(brand, primary);
    }

    .gr-form__password {
        display: none;

        &.is-opened {
            display: block;
        }
    }

    .form__input-icon {
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
            background-color: transparent;
            margin-left: .4rem;
        }
    }
}

.access-request__form-footer {
    align-items: center;

    .gr-form__field {
        margin-bottom: 0;
    }

    .btn--primary {
        min-width: 140px;
        margin-top: $root-vgrid/2;

        @include mq(sm) {
            margin-top: 0;
        }
    }

    > [gr-grid]:nth-child(2) {
        flex-grow: 0;
        flex-basis: auto;
    }
}

.event-media {
    @include keep-ratio("240/110");
}

@media print {
    .zm-nav,
    .zm-header,
    .zm-banner,
    .zm-tabs-trigger,
    .zm-delay-table__footer-page,
    .zm-footer,
    .memo__header,
    .memo__footer {
        display: none;
    }

    .logo-print {
        display: block;
    }

    .main-inner {
        padding: 1rem 0 0;
    }

    hr {
        display: block;
        height: 1px;
        background: transparent;
        width: 100%;
        border: none;
        border-top: solid 1px color(member-zone, grey-light);
    }
    .zm-site-container .main {
        min-height: 100vh;
        padding-left: 0;
    }

    * {
        color: black !important;
    }
}


.l-head-office {
    @include mq(sm) {
        min-height: 445px;
        position: relative;
        background-image: url('/dist/img/header-office-map.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-clip: content-box;
    }
}

.l-head-office .form--dealers__results {
    text-align: center;
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;
    padding-bottom: $root-vgrid*1.5;
    margin-bottom: $root-vgrid*1.5;
    border-bottom: 1px solid color(greys, base);

    .title {
        display: block;
    }

    svg {
        display: none;
    }

    @include mq(sm) {
        text-align: left;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 38%;
        width: 100%;
        max-width: 425px;
        background-color: white;
        margin: 0;
        padding: 1.5rem;
        overflow: visible;
        box-shadow: 0 4px 15px rgba(0, 0, 0, .1);
        border-bottom: 0;

        &::after {
            content: "";
            position: absolute;
            bottom: -12px;
            left: 50%;
            width: 0;
            height: 0;
            transform: translateX(-50%);
            border-style: solid;
            border-width: 12px 12px 0;
            border-color: white transparent transparent;
        }

        .title {
            display: flex;
        }

        svg {
            display: block;
        }
    }

    address {
        margin: 0;
        padding: 0;
    }
}

.form-confirmation:not(:empty) {
    padding: 1.875rem;
    text-align: center;
    color: color(brand, primary);
    font-size: 1.5rem;
    font-weight: bold;
}

.form-confirmation--opaque {
    background-color: rgba(0, 0, 0, .75);
}

.form--filters-fabrics {

    input[type='checkbox'] + label::before,
    input[type='checkbox']:indeterminate + label::after,
    input[type='checkbox']:checked + label::after {
        display: none;
    }

    .gr-form__field input[type='checkbox']:indeterminate + label span,
    .gr-form__field input[type='checkbox'][indeterminate] + label span {
        font-weight: 400;
    }

    .gr-form__field input[type='checkbox'] + label {
        padding-left: 0;

        a {
            display: block;
            color: currentColor;
        }
    }

    .gr-form__field input[type='checkbox']:checked + label a {
        font-weight: 600;
        text-decoration: underline;

        &::after {
            text-decoration: none;
        }
    }
}

.finishes-and-fabrics__container {
    background-color: color(greys, xlight);
    padding: $root-vgrid/1.5;
}

.finishes-and-fabrics__header {
    display: flex;
    align-items: center;
    list-style: none;

    .finishes-and-fabrics__document {
        margin-top: rem(10px);
        margin-left: rem(10px);
        margin-bottom: rem(10px);
    }

    > li {
        padding: $grid-gutter-width/4 $grid-gutter-width/2;

        @include mq(lg) {
            padding: $grid-gutter-width/2 $grid-gutter-width/1.5;
        }

        line-height: 1.1;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 275px;

        &:first-child {
            align-self: stretch;
            text-align: center;
            background-color: color(greys, xlight);
            padding: $grid-gutter-width/4 $grid-gutter-width/2;
        }

        &.finishes-and-fabrics__document {
            max-width: none;

            .icon-download {
                padding-left: .5rem;
                color: color(brand, primary);
            }
        }
    }

    a {
        color: currentColor;
    }
}


a[name="top"] {
    display: block;
}

.scale-down {
    object-fit: scale-down !important;
}

@include mq(sm, 1) {

    [class*="__cover"] {
        &.left img {
            object-position: left;
        }

        &.right img {
            object-position: right;
        }

        &.center img {
            object-position: center;
        }
    }
}

.h-text-align--center img {
    margin-left: auto;
    margin-right: auto;
}

.banner--100vh {
    min-height: 100vh;
}

.tabs.tabs--product__elements .tab-link {
    &.level-2 {
        margin-left: 1rem;
        margin-right: 1rem;

        & + .tab-content {
            padding-left: 36px;
            padding-right: 36px;
        }
    }

    &.level-3,
    &.level-4 {
        margin-left: 2rem;
        margin-right: 2rem;

        & + .tab-content {
            padding-left: 36px;
            padding-right: 36px;
        }
    }

    @include mq(md) {
        &.level-2 {
            width: calc(190px - 1rem);
            margin-right: 0;
        }

        &.leve-3 {
            padding: 0;
            background-color: transparent;

            &:hover span {
                color: $black;
                text-decoration: underline;
            }

            &.gr-active span {
                font-weight: 600;
                color: $black;
                text-decoration: underline;
            }
        }

        &.level-3,
        &.level-4 {
            width: calc(190px - 2rem);
            margin-right: 0;
        }
    }
}

.search__result {
    color: $root-color;
    margin-bottom: $root-vgrid/2;

    > a {
        color: currentColor;
    }
}

.gallery-container {
    display: none;
}

.gallery-conf-container {
    display: none;
}

.support-technique {
    padding-top: $root-vgrid;
    padding-bottom: $root-vgrid;
    background-color: color(greys, xlight);

    > [gr-grid=row] {
        max-width: 1190px;
        margin-left: auto;
        margin-right: auto;
    }

    .typography {
        max-width: 980px;
        margin-left: auto;
        margin-right: auto;
    }
}

.artopex-login {

    > [gr-grid]:first-child {
        @include mq(md) {
            padding-right: 7rem;
        }
    }

    > [gr-grid]:last-child {
        margin-top: $root-vgrid;
        padding-top: $root-vgrid;
        border-top: 1px solid color(greys, light);

        @include mq(sm) {
            margin-top: 0;
            padding-top: 0;
            border-top: 0;
            border-left: 1px solid color(greys, light);
        }

        @include mq(md) {
            padding-left: 4rem;
        }
    }

    &--poste-types {
        padding-top: 2rem;
        padding-bottom: 2rem;
        padding-right: .5rem;
        padding-left: .5rem;
        margin-bottom: 2rem;
        margin-left: 0;
        margin-right: 0;
        @include mq(lg) {
            padding: 2rem 1rem;
        }

        .gr-form__field--inline {
            @include mq(xl, 1) {
                margin-bottom: 1rem;
            }

            @include mq(sm, 1) {
                flex-direction: column;
                align-items: flex-start;
                .gr-form__field--forgot-password {
                    width: 100%;
                }
            }

            input:not([type='submit']) {
                width: 100%;
                @include mq(lg) {
                    width: auto;
                }
            }

            label {
                @include mq(lg, 1) {
                    margin-right: 0;
                }
            }

            .btn--right {
                @include mq(xl, 1) {
                    margin-left: 0;
                    margin-top: 1rem;
                }
            }
        }

        > [gr-grid]:first-child {
            padding-right: 1rem;
            @include mq(lg) {
                padding-right: 2rem;
            }
        }

        > [gr-grid]:last-child {
            padding-left: 1rem;
            @include mq(lg) {
                padding-left: 2rem;
            }
        }
    }
}

.banner__inner address {
    margin-top: 0;

    .title {
        @extend %h1;
        font-weight: normal;
    }

    a {
        color: currentColor;
    }
}

.find-us-navigation {
    a {
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: .5rem;
        display: block;

        @include mq(sm) {
            display: inline-block;
        }
    }
}

.section-shadow {
    position: relative;
    width: 100%;
    min-height: 40px;
    padding-top: 20px;

    .banner {
        margin: $root-vgrid/2 auto;
        min-height: 100px;
    }

    // @include mq(md) {
    &::after {
        content: "";
        background: linear-gradient(to bottom, rgba(242, 242, 240, 1) 0%, rgba(228, 228, 228, 0) 100%);
        width: 100%;
        height: 40px;
        display: block;
        position: absolute;

        top: 0;
        z-index: -1;
        left: 0;
        right: 0;
        // top: 20px;
    }
    // }
}


.icon-title {
    width: 44px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: $root-vgrid/2;
}

.icon-lock {
    position: relative;
    top: 2px;
}

.img-responsive {
    width: 100%;
}

.img-max-height {
    @extend %img-cover;

    img {
        max-height: 820px;
    }
}

.svg.inverted {
    path: $white !important;
}

[gr-video-bg] {
    @include keep-ratio('16/9');
}

@include table('.gr-table', sm);

@include breadcrumbs('.breadcrumbs', '>');

.breadcrumbs {
    font-size: .75em;
    margin-top: 20px;

    a {
        color: inherit;
    }

    @include mq(sm, 1) {
        display: none;
    }
}

.section-espaces-product {
    [gr-grid=block] {
        position: relative;

        @include mq(md) {
            padding-left: 2rem;
            padding-right: 2rem;
        }

        @include mq(lg) {
            padding-left: 5rem;
            padding-right: 5rem;
        }

        &::after {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            content: "";
            display: block;
            width: 1px;
            height: 75%;
            background-color: color(greys, light);
        }

        &:last-child::after {
            display: none;
        }

        .card__figure img {
            object-fit: contain;
            font-family: contain;
        }
    }
}

.section-espaces-product--left-content {
    @include mq(sm, 1) {

        .card__title {
            text-align: left;
        }

        [gr-grid=block] {
            position: relative;
            &::after {
                content: none;
            }
        }
    }
}


// Components: Lazy-Loading
// --------------------------------------------------------------------------

@include lazyload();

.img-lazyload {
    @include keep-ratio('16/9');
}

[gr-lazyloader~='done'] {
    background: none;
}


// Gallery counter

.counter {
    font-weight: 700;

    @include mq(sm) {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .btn--primary {
        margin-top: $root-vgrid/2;

        @include mq(sm) {
            margin-top: 0;
            margin-left: $root-hgrid;
        }
    }

    .download-selected {
        display: block;

        @include mq(sm) {
            display: inline-block;
        }
    }

    [data-counter] {
        padding-right: .4rem;
    }
}

.slider--spaces {
    .slide::before {
        display: none;
    }

    .slide__image {
        position: relative;
        width: 100%;
        @extend %img-cover;
        @include keep-ratio("16/9");
    }

    .slide__image-download {
        margin-top: 10px;
        margin-bottom: 10px;

        @media (min-width: 64em) {
            margin-top: 20px;
            margin-bottom: 0;
        }
    }

    @media (min-width: 64em) {
        .slick-prev, .slick-next {
            top: calc(50% - 3.5rem);
        }

        .slick-slide::after {
            display: none;
        }

        .slide__image::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(to top, #191919 0%, rgba(25, 25, 25, 0) 30%);
            opacity: 1;
            z-index: 1;
        }

        .slick-dots {
            bottom: 100px;
        }
    }
}

// Donkey tail pins

.slide__donkeytails {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.slide__donkeytail {
    z-index: 10;
    position: absolute;

    @include mq(sm, 1) {
        .card__cta {
            display: none;
        }
    }

    &:hover {
        z-index: 11;
    }
}

.slide__donkeytail__container {
    position: relative;
    width: 40px;
    height: 40px;

    .card__cta span::after {
        transition: opacity $transition-fast ease-in-out;
    }

    &.is-active {
        .donkeytails__content {
            visibility: visible;
            opacity: 1;
            transition: opacity $transition-fast linear;
        }

        .card__cta span::after {
            opacity: 0;
        }
    }
}

.donkeytails__content {
    position: absolute;
    display: block;
    background-color: transparentize($white, .15);
    width: 325px;
    height: 90px;
    padding: 1.5rem;
    left: 50%;
    top: -20px;
    transform: translate(-162.5px, -100%);
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s $transition-fast, opacity $transition-fast linear;
    box-shadow: 0 8px 10px 0 rgba(0, 0, 0, .5);
    color: $root-color;

    &::after {
        position: absolute;
        bottom: -15px;
        left: 50%;
        transform: translateX(-50%);
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 15px 12.5px 0;
        border-color: transparentize($white, .15) transparent transparent transparent;
    }

    .dt-t & {
        top: auto;
        bottom: -20px;
        transform: translate(-162.5px, 100%);
    }

    .dt-t &::after {
        top: -15px;
        bottom: auto;
        border-width: 0 12.5px 15px;
        border-color: transparent transparent $white transparent;
    }

    .dt-l & {
        transform: translate(-25px, -100%);

        &::after {
            left: 25px;
        }
    }

    .dt-l.dt-t & {
        transform: translate(-25px, 100%);
    }

    .dt-r & {
        transform: translate(-300px, -100%);

        &::after {
            left: auto;
            right: 0;
        }
    }

    .dt-r.dt-t & {
        transform: translate(-300px, 100%);
    }
}

.report__picture {
    margin: 0 15%;

    [gr-lazyloader] {
        background-color: transparent;
        @include keep-ratio('3/4');
        transform: rotate(-7.5deg);
    }
}

.report__content {
    text-align: left;
}

.avatar {

    margin: $root-vgrid auto;

    &__picture {
        @include keep-ratio('1/1');

        max-width: calc(100% / 3);
        margin: ($root-vgrid * .5) auto;

        img {
            border-radius: 50%;
        }
    }

    &__name {
        color: $white;
        font-weight: 700;
        font-size: em(14px);
    }

}

.mfp-title {
    a {
        color: currentColor;
        padding-left: .2rem;
        padding-right: .2rem;
        color: color(brand, primary);
    }
}

.customer-service {
    .nav__link {
        padding-top: 0;
        font-weight: 600;
    }
}

.prices-list-buttons {
    .btn--primary {
        margin-bottom: 4px;
    }
}

.hidden {
    display: none;
}

@import "mutation";

