.event-informations {
    padding-top: $root-vgrid;
    padding-bottom: $root-vgrid;
    border-bottom: 1px solid color(member-zone, grey-light);
    line-height: 1.2;
    color: color(member-zone, grey-dark);

    @include mq(sm) {
        display: flex;
        align-items: center;

        > * {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: auto;
        }
    }

    svg path {
        fill: color(brand, primary);
    }
}

.event-informations__left {
    display: flex;
    align-items: center;
    margin-bottom: $root-vgrid/2;

    a {
        font-size: rem(14px);
        color: color(brand, primary);
    }

    @include mq(sm) {
        margin-bottom: 0;
        margin-right: 1rem;
    }

    img,
    svg {
        margin-right: 1rem;
        flex-shrink: 0;
    }
}

.event-informations__right {
    @include mq(sm) {
        display: flex;
        align-items: center;

        > * {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: auto;
        }
    }

    > div {
        display: flex;
        align-items: center;
        margin-bottom: $root-vgrid/2;

        @include mq(sm) {
            margin-bottom: 0;

            &:not(:last-child) {
                margin-right: 1rem;
            }
        }

        img,
        svg {
            margin-right: 1rem;
            flex-shrink: 0;
        }

        &.event-informations__button {
            margin-top: $root-vgrid;
            margin-bottom: 0;

            @include mq(sm) {
                margin-top: 0;
                justify-content: flex-end;
            }
        }
    }
}

.event-informations__button {
    @include mq(sm) {
        text-align: right;
    }

    @include mq(lg) {
        .btn--primary {
            min-width: 140px;
            text-align: center;
        }
    }
}
