.gr-form__field .select2-container {
    display: block;
    background-color: white;
    width: 100% !important;
    position: relative;
    z-index: 0;

    &::before {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 2.875em;
        height: 2.875em;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 284.929 284.929'%3E %3Cpath d='M282.082 76.51L267.808 62.24c-1.902-1.906-4.093-2.856-6.57-2.856-2.47 0-4.66.95-6.563 2.856L142.465 174.44 30.263 62.24c-1.903-1.905-4.093-2.855-6.567-2.855-2.475 0-4.665.95-6.567 2.856L2.856 76.516C.95 78.417 0 80.607 0 83.082c0 2.473.953 4.663 2.856 6.565L135.9 222.693c1.9 1.903 4.092 2.854 6.566 2.854s4.66-.95 6.562-2.854L282.082 89.647c1.902-1.903 2.847-4.093 2.847-6.565 0-2.475-.946-4.665-2.848-6.57z'/%3E %3C/svg%3E");
        background-position: right center;
        background-repeat: no-repeat;
        background-size: 16px 1em;
    }
}

.select2-container .select2-selection--multiple {
    border-radius: 0;
    border-color: #d9d9d9;
    position: relative;
    z-index: 1;
    background-color: transparent;
}

.select2-container .select2-search--inline .select2-search__field {
    height: 33px;
}

.select2-container .select2-selection--multiple .select2-selection__rendered > .select2-selection__choice {
    margin-top: 9px;
    display: flex;
    flex-direction: row-reverse;
    border-radius: 0;
    border-color: color(brand, primary);
    color: color(brand, primary);
    background-color: color(greys, xlight);
}

.select2-container .select2-results__option--highlighted[aria-selected] {
    color: color(greys, base);
    background-color: color(greys, xlight);
}

.select2-container .select2-selection--multiple .select2-selection__choice__remove {
    padding-left: 6px;
}
