
.zm-nav + .main {
    padding-left: 145px;

    @include mq(sm) {
        padding-left: 210px;
    }
}

.zm-site-container .main {
    padding-top: 60px;

    @include mq(md) {
        padding-top: 0;
        padding-left: 145px;
    }

    @include mq(lg) {
        padding-left: 210px;
    }
}

.zm-nav,
.zm-header,
.zm-site-container .main {
    transition: all $transition-fast ease-in-out;
}

.main-inner {
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;
    padding-top: $root-vgrid;
    padding-bottom: $root-vgrid*2;
    max-width: 1490px;

    @include mq(md) {
        padding-left: $grid-gutter-width;
        padding-right: $grid-gutter-width;
    }
}

@include mq(md, 1) {
    body.nav-is-opened {
        overflow-x: hidden;
    }

    .zm-site-container .main,
    .zm-header {
        margin-left: 0;
        width: 100%;
    }

    .nav-is-opened .zm-header,
    .nav-is-opened .main {
        margin-left: 145px;
    }

    .nav-is-opened .zm-header,
    .nav-is-opened .main {
        width: 100vw;
    }

    .zm-nav.is-opened {
        transform: translateX(0);
    }

    .nav-is-opened {
        .zm-site-container {
            width: 100vw;
            overflow-x: hidden;
        }
    }
}
