.card-evenement {
    padding: $grid-gutter-width/1.5 $grid-gutter-width/2;
    box-shadow: 0 4px 30px rgba(0, 0, 0, .1);
    color: color(member-zone, grey-dark);

    @include mq(sm) {
        padding: 1.5rem;
    }

    @include mq(md) {
        padding: 2rem;
    }

    > .card-evenement__column {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 0;

        &:nth-child(1) {
            @include mq(sm) {
                min-width: 210px;
                max-width: 250px;
            }
        }

        &:nth-child(2) {
            flex-grow: 3;
            padding-left: 1.5rem;

            @include mq(sm) {

                display: flex;

                > div {
                    flex-grow: 1;
                    flex-shrink: 1;
                    flex-basis: auto;
                }
            }

            @include mq(sm) {
                padding-left: 2rem;
            }

            @include mq(md) {
                padding-left: 2.5rem;
            }
        }
    }

    a:not([class*="btn--"]) {
        color: color(brand, primary);
        font-size: rem(14px);

        @include mq(sm, 1) {
            display: block;
            margin-top: $root-vgrid/2;
        }
    }
}

.card-evenement + .card-evenement {
    margin-top: $root-vgrid;
}


.card-evenement--type-1 {
    display: flex;
    align-items: center;

    > .card-evenement__column {

        &:nth-child(1) {
            text-align: center;
            font-size: rem(36px);
            border-right: 1px solid color(member-zone, grey-light);
            padding-right: $grid-gutter-width/2;

            @include mq(sm) {
                padding-right: 1.5rem;
            }

            @include mq(md) {
                padding-right: 2rem;
            }
        }
    }

    [class*="btn--"] {
        display: none;

        @include mq(sm) {
            display: inline-block;
            margin-bottom: $root-vgrid/2;
        }
    }
}

.card-evenement--type-2 {
    padding: 0;

    @include mq(sm) {
        display: flex;
    }

    > .card-evenement__column {
        &:nth-child(1) {
            @include keep-ratio("57/34");
            @include change-ratio("1.18/1", sm);
        }

        &:nth-child(2) {
            padding: $grid-gutter-width/1.5 $grid-gutter-width/2;

            @include mq(sm) {
                padding: 1.5rem;
            }

            @include mq(md) {
                padding: 2rem;
            }
        }
    }
}


.card-evenement__date {
    margin-bottom: $root-vgrid/4;
}

.card-evenement__date-day,
.card-evenement__date-month {
    font-weight: 600;

    @include mq(sm) {
        display: inline-block;
        font-size: rem(48px);
    }
}

.card-evenement__date-day {
    line-height: 1.1;
}

.card-evenement__date-month {
    @include mq(sm, 1) {
        font-size: rem(16px);
    }
}

.card-evenement__title {
    font-weight: 600;
    line-height: 1.18;
    margin-bottom: $root-vgrid/3;

    @include mq(sm) {
        font-size: rem(24px);
    }
}

.card-evenement__categories {
    display: none;
    font-size: 1rem;
    line-height: 1.18;
    color: transparentize(black, .7);
    max-width: 170px;
    margin-left: auto;
    margin-right: auto;

    @include mq(sm) {
        display: block;
        margin-top: $root-vgrid/1.5;
    }
}

.card-evenement__button-container {
    flex-grow: 0;

    @include mq(sm) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        min-width: 140px;
        max-width: 140px;
        margin-left: 1.5rem;
    }

    [class*="btn--"] {
        // display: none;

        @include mq(sm) {
            display: inline-block;
            margin-bottom: $root-vgrid/2;
        }
    }
}

.card-evenement__tag {
    border-bottom: 1px solid color(member-zone, grey-light);
    margin-top: $root-vgrid/2;
    padding-bottom: $root-vgrid/1.5;
    margin-bottom: $root-vgrid;
    line-height: 1;
    color: transparentize(black, .7);

    @include mq(sm) {
        margin-top: 0;

    }
}

.card-evenement__informations {
    display: none;
    list-style: none;
    margin-top: $root-vgrid/1.5;

    li {
        margin-top: $root-vgrid/3;
        display: flex;
        align-items: center;

        svg,
        img {
            margin-right: 1rem;
            width: 15px;
            height: 15px;

            path {
                fill: color(brand, primary);
            }
        }
    }

    @include mq(sm) {
        display: block;
    }
}


.card-evenement__video::after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    width: 65px;
    height: 65px;
    border: 3px solid white;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    background-image: url('/dist/img/svg/play.svg');
    background-position: calc(50% + 3px) 50%;
    background-repeat: no-repeat;
}

.card-evenement__audio::after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    width: 65px;
    height: 65px;
    border: 3px solid white;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    background-image: url('/dist/img/svg/volume-up.svg');
    background-position: calc(50% + 3px) 50%;
    background-repeat: no-repeat;
    background-size: 70%;
}
