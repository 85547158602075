.section {
    margin-top: $root-vgrid;
    margin-bottom: $root-vgrid;

    @include mq(sm, 1) {
        margin-top: $root-vgrid/1.5;
        margin-bottom: $root-vgrid/1.5;
    }

    & > .section {
        margin-top: 0;
        margin-bottom: 0;
        padding-top: $root-vgrid;
        padding-bottom: $root-vgrid;
    }

    &.intro {
        margin-top: $root-vgrid;
    }

    &--small {
        margin-top: $grid-gutter-width;
        margin-bottom: $grid-gutter-width;
    }

    &--half-offset {
        margin-left: calc(#{$grid-container-width} / 24);
        text-align: left;

        .title--arrow {
            margin-left: -1em;
        }
    }

    &--arrow-offset {
        margin-left: 1.5em;
        text-align: left;

        .title--arrow {
            margin-left: -1em;
        }
    }


    &--author {

        [gr-grid=row] {
            align-items: center;
        }

        .avatar {
            margin: 0;
        }

        .avatar__picture {
            max-width: 75%;
            margin: 0;
        }
    }


    &--blog .title {
        margin-bottom: .5em;
        & + * {
            margin-bottom: $root-vgrid;
        }
    }

    &--sharing {
        display: flex;
        align-items: center;
        justify-content: center;

        .title {
            margin-right: $grid-gutter-width;
        }
    }

    &--fabrics {

        [gr-grid=block] {
            position: relative;
        }

        [gr-lazyloader] {
            @extend %img-cover;
            @include keep-ratio("4/3");
        }
    }

    &--history {
        [gr-grid~=row] {
            justify-content: center;
            align-items: center;
        }

        &__picture {
            position: relative;
            align-self: stretch;
            z-index: 0;
        }

        &__picture [gr-lazyloader] {
            @extend %img-cover;
            @include keep-ratio("11/4");
            position: absolute;
            top: 0;
            bottom: 0;
            left: 10px;
            right: 10px;
            width: auto;
        }
    }

    &--product {

        .data-downloadable {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            //width: 100%;

            @include mq(md) {
                position: absolute;
                top: -10px;
                right: 0;
                justify-content: flex-end;
            }
        }

        .section__intro {
            position: relative;

            .data-downloadable {
                @include mq(md) {
                    top: auto;
                    bottom: 1.5rem;
                    width: calc(500% / 12);
                }
            }
        }

        .section--product__detail {
            position: relative;

            @media screen and (max-width: 1200px) {
                .data-downloadable {
                    display: none;
                }
            }
        }

        .data-downloadable > div {
            flex: 0 0 auto;
            margin: (.5 * $grid-gutter-width) (.5 * $grid-gutter-width) 0;
        }

        .gr-select,
        .gr-select select {
            height: em(47px);
        }

        .gr-select__box {
            @extend %btn;
            height: auto;
            margin-bottom: 0;
            border-bottom: 0;

            &::after {
                display: none;
            }

            & + .gr-select__options li {
                padding: $root-padding * .25 $root-padding * .5;
                //text-transform: uppercase;
                font-size: em(14px);
            }
        }

        .gr-select .gr-select__box {
            border-bottom: 0;
        }

        .gr-select .gr-select__box + .gr-select__options {
            height: 2px;
            border: 2px solid black;
            border-top: 0;
            font-weight: 700;
        }

        .field--booklet {
            .gr-select__box {
                @include btn-colors($white, $root-color, $root-color);

                & + .gr-select__options {
                    color: $white;
                    background-color: $root-color;
                }
            }
        }
    }

    &--case-study {}

    &--concept {
        & > .section:first-child [gr-grid="row"] {
            & > div:last-child {
                width: 100%;

                @include mq(sm) {
                    width: calc(700% / 12);
                }
            }
        }
    }
}


.section--before-after {

    @include mq(sm, 1) {
        text-align: center;
        .h-background--xlight {
            background-color: #ededed;
        }
        [gr-grid^="row"] {
            > div {
                &:first-child {
                    order: 2;
                }

                &:last-child {
                    order: 1;
                }
            }
        }
    }

    > div {
        margin: 0;
    }

    .before-after {
        display: flex;
        align-items: center;

        > div {
            width: 100%;
        }
    }

    .before-after__content {

        padding-left: 1rem;
        padding-right: 1rem;

        @include mq(md) {
            padding-right: 1rem;
        }

        @include mq(lg) {
            padding-left: 3.75rem;
            padding-right: 3.75rem;
        }
    }
}

[gr-grid~='row--block'].section--small:not(.section--fabrics) [gr-grid='block'] {
    margin: 0 0 20px !important;
}

.modularWalls-tabs {
    list-style: none;
    margin-bottom: $root-vgrid;

    li {
        display: inline-block;

        &.gr-active .btn--primary {
            @include btn-colors( color(brand, primary), transparent, color(brand, primary));
        }
    }
}
