// ==========================================================================
// Accordion Tabs
// ==========================================================================

@mixin accordion-tabs-member($accordion-tabs-class: '.gr-tabs', $accordion-tabs-breakpoint: sm) {

    // Baseline styles
    // ==========================================================================

    #{$accordion-tabs-class} {

        &[gr-acc-tabs-member~='acc'] {
            .tab {
                list-style: none;
            }

            [gr-acc-tabs-member-trigger] {
                display: block;

                &[gr-acc-tabs-member-trigger~='active'] + [gr-acc-tabs-member-target] {
                    display: block;
                }
            }

            .tab-content {
                display: none;
                width: 100%;
            }
        }

        &[gr-acc-tabs-member~='tab'] {
            overflow: hidden;

            .tab {
                display: inline;
            }
            .tab-link {
                display: inline-block;

                &.is-active + .is-open {
                    display: block;
                }
            }
            .tab-content {
                display: none;
                width: 100%;
                float: left;
            }
        }

        &[gr-acc-tabs-member]:not([gr-acc-tabs-member~='tab']):not([gr-acc-tabs-member~='acc']) {
            @if $accordion-tabs-breakpoint and $accordion-tabs-breakpoint!=0 {
                .tab {
                    list-style: none;
                }

                .tab-link {
                    display: block;

                    &[gr-acc-tabs-member-trigger~='active'] + [gr-acc-tabs-member-target] {
                        display: block;
                    }
                }

                .tab-content {
                    display: none;
                    width: 100%;
                }

                @include mq($accordion-tabs-breakpoint) {
                    overflow: hidden;

                    .tab {
                        display: inline;
                    }
                    .tab-link {
                        display: inline-block;
                    }
                    .tab-content {
                        float: left;
                    }
                }
            }
        }
    }
}
