// Custom Input[type=file]
// --------------------------------------------------
// @example html - Markup
// <div class='form-item | form-item--inline'>
//      <input type='file' name='' id=''>
//      <label for=''> ... </label>
// </div>

@mixin form-file($class: '.gr-form-upload') {

    #{$class}__wrapper {
        cursor: pointer;
        display: inherit;

        input[type='file'] {
            display: block;
            position: absolute;
            opacity: 0;
            z-index: 1;
            width: 100%;
        }

        label {
            @extend %input;
            color: color(greys, light);

            display: flex;
            align-items: center;
            justify-content: space-between;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            > * {
                flex: 1 1 100%;
            }

            &::after {
                display: block;
                content: '';
                background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 536.461 536.46'%3E %3Cpath d='M144.752 263.52c19.603-9.038 38.354-13.56 56.243-13.56h237.548V204.28c0-17.51-6.283-32.555-18.85-45.118-12.565-12.562-27.596-18.842-45.11-18.842H219.266v-9.136c0-17.51-6.28-32.548-18.842-45.107-12.563-12.562-27.6-18.846-45.11-18.846h-91.36c-17.512 0-32.55 6.282-45.112 18.845C6.28 98.635 0 113.672 0 131.183v274.084c0 .764.05 1.955.144 3.576.094 1.615.144 2.807.144 3.566l1.426-1.705L97.93 297.637c11.61-13.706 27.218-25.08 46.822-34.117z'/%3E %3Cpath d='M528.898 290.214c-5.04-2.478-10.797-3.72-17.272-3.72h-310.63c-12.563 0-26.22 3.38-40.97 10.14-14.75 6.766-26.218 14.986-34.4 24.7l-95.93 113.06c-5.902 6.662-8.853 12.945-8.853 18.85 0 5.707 2.523 9.8 7.566 12.27 5.042 2.48 10.8 3.717 17.272 3.717h310.64c12.56 0 26.21-3.38 40.963-10.135 14.75-6.756 26.214-14.99 34.4-24.7l95.93-113.06c5.9-6.663 8.846-12.94 8.846-18.85.005-5.706-2.514-9.796-7.562-12.27z'/%3E %3C/svg%3E ") no-repeat center center;
                background-size: calc(#{$root-line-height} * 1em);
                color: currentColor;
                width: $form-element-height;
                height: $form-element-height;
                flex-basis: $form-element-height;
                text-align: center;
                transition: transform $transition-fast ease-in-out;
                margin-right: -$form-element-padding;

                @if $form-element-border-width {
                    border-left: $form-element-border-width $form-element-border-style $form-element-border-color;
                }
            }

            &.is-ready {
                color: inherit;

                &::after {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 475.082 475.082'%3E %3Cpath d='M456.24 128.475c-12.56-12.562-27.598-18.842-45.11-18.842H219.27v-9.136c0-17.51-6.284-32.548-18.844-45.107-12.562-12.562-27.6-18.846-45.11-18.846H63.952c-17.515 0-32.55 6.283-45.11 18.846C6.28 67.95 0 82.986 0 100.497v274.088c0 17.508 6.28 32.545 18.842 45.104 12.562 12.564 27.6 18.848 45.11 18.848H411.13c17.514 0 32.55-6.283 45.11-18.85 12.566-12.56 18.843-27.596 18.843-45.103V173.59c.002-17.512-6.275-32.548-18.84-45.115z'/%3E %3C/svg%3E ");
                }
            }
        }

        input:active ~ label {
            position: relative;
            border-color: $form-element-focus-color;

            &::after {
                border-color: $form-element-focus-color;
                color: $form-element-focus-color;
            }
        }

        input:focus ~ label {
            border-color: $form-element-focus-color;
        }

        input::-ms-value {
            display: none;
        }

        input::-ms-browse {
            display: none;
        }
    }
}
