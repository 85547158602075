/* header
-------------------------------------------- */
.section--product {

    .banner {
        height: 375px !important;
        min-height: 375px !important;
    }

}

.section-product-intro {
    margin-bottom: 30px;
    text-align: center;

    @media (min-width: 1280px) {
        margin-bottom: 70px;
    }
}

.h-product-title {
    font-size: 25px;
    text-align: center;

    @media (min-width: 1280px) {
        font-size: 50px;
    }
}

.product-body-intro {
    max-width: 540px;
    margin: 30px auto;
}

/* block-login-price
-------------------------------------------- */
.section-block-login-price {
    margin-bottom: 50px;
    margin-top: 50px;

    @media (min-width: 1280px) {
        margin-top: 100px;
        margin-bottom: 100px;
    }

}

.block-login-price {
    padding: 50px 20px;
    background-color: #f0efec;

    @media (min-width: 1280px) {
        padding: 100px 50px;
    }

    &__title {
        font-size: 28px;
        line-height: 1;
        margin-bottom: 40px;
    }

    .artopex-login.artopex-login--page-product {
        margin: 0;

        [gr-grid="sm-1 lg-1"] {
            padding-left: 0;
        }

        .gr-form__field--inline {
            display: block;

            label {
                display: block;
            }

            input {
                width: 100%;
            }
        }

        .gr-form__field--remember {
            display: flex;
        }

    }

}

/* t-hero--product
-------------------------------------------- */
.t-hero.t-hero--product {
    height: 375px !important;
    min-height: 375px !important;
}

/* ressources
-------------------------------------------- */
.h-product-ressource {
    margin-bottom: 30px;
}

.ressources.ressources-product {
    @media (min-width: 1280px) {
        border-left: 1px solid #D9D9D9;
        margin-top: 60px;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 70px;
    }
}

/* studies
-------------------------------------------- */
.studies-card-content {
    padding: 40px;
    background-color: color(greys, xlight);
}

/* banner-hero-product
-------------------------------------------- */
body.template-products .main {
    min-height: 0;
}

.banner-hero-product {
    margin: 0 auto;
}

.product-ressource-form-icon {
  display: inline;
  width: 16px;
  height: auto;
  margin-right: 8px;
}

.product-ressource-form-icon,
.product-ressource-form-icon + a {
  vertical-align: middle;
}

.blog-article-header {
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    justify-items: baseline;

    .social-icons--share {
        margin: 0;
        display: flex;
        flex-direction: row;
    }

    
    @media (min-width: '1400px') {
        position:absolute;
        left: 15px;

        .social-icons--share {
            flex-direction: column;
        }
    }

    @media (min-width: '1600px') {
        .social-icons--share {
            flex-direction: row;
        }
    }
}

.section--blog {
    position: relative;
}
