.alert {
    background-color: $color-primary;
    color: $root-color;
    padding: 1rem 2rem;
    font-size: em(14px);
    //text-transform: uppercase;
    font-weight: 700;
    display: flex;

    i { margin: 0 2rem 0 0; }

    .icon-close {
        cursor: pointer;
        margin: 0 0 0 auto;
    }
}
