.zm-delay-table {
    list-style: none;
    max-width: 875px;
    margin-left: auto;
    margin-right: auto;

    .zm-delay-table__element {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: $grid-gutter-width/2;
        color: color(greys, dark);
        border-bottom: 1px solid color(greys, base);

        &:last-child {
            border: 0;
        }

        @include mq(sm) {
            padding: $grid-gutter-width/1.5 $grid-gutter-width;
        }

        > div:nth-child(1) {
            padding-right: $grid-gutter-width/2;
        }

        > div:nth-child(2) {
            min-width: 100px;
            text-align: center;

            @include mq(sm) {
                min-width: 250px;
                text-align: left;
            }
        }
    }

    .hide-sm {
        @include mq(sm) {
            display: none;
        }
    }

    .show-sm {
        display: none;

        @include mq(sm) {
            display: block;
        }
    }
}

.zm-delay-table__element--featured {
    background-color: transparentize(color(greys, xlight), .5);
}


.zm-delay-table__footer {
    border-top: 1px solid color(greys, base);
    padding-top: $root-vgrid/1.5;
}

.zm-delay-table__footer-page {
    border-top: 1px solid color(greys, base);
    padding-top: $root-vgrid/1.5;
}


.zm-delay-table__footer-label {
    margin-bottom: $root-vgrid/2;

    @include mq(sm) {
        margin-bottom: 0;
    }

    small {
        display: block;
        line-height: 1.8;
    }
}

.zm-delay-table__footer-actions {
    color: color(brand, primary);

    > * + * {
        margin-top: $root-vgrid/3;
    }

    @include mq(xs) {
        display: flex;
        align-items: center;
        justify-content: center;

        > * {
            margin-right: 1rem;
            margin-left: 1rem;
            margin-top: 0;
        }
    }

    @include mq(sm) {
        justify-content: flex-end;
    }

    > * {
        display: block;
    }

    a {
        color: currentColor;
    }
}
