// section
// --------------------------------------------
.section {

  &.h-vspacing-mt-0 {
      margin-top: 0px!important;
  }

  &.section-mt-xl {
      margin-top: 160px;

    @include mq(sm, 1) {
      margin-top: 100px;
    }
  }

  &.section-mt-lg {
      margin-top: 120px;

    @include mq(sm, 1) {
      margin-top: 80px;
    }
  }

  &.section-mt-md {
      margin-top: 80px;

    @include mq(sm, 1) {
      margin-top: 40px;
    }
  }

}

.section--hero-and-image {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;

  &--hero {
      margin-top: -1.875rem;
  }

  [gr-grid~=row] > [gr-grid*='-'], [gr-grid~=row] [gr-grid~=column] {
      padding: 0;
  }

  [gr-grid="row row--reverse"] {
    flex-direction: row-reverse;
  }

  [gr-grid="md-6"] {
    @include mq(md) {
      max-width: 50%;
    }
  }

}

.section-profile {

  .section-profile-image {
      margin: 0 auto;
      margin-bottom: 40px;

      @include mq(md) {
          margin-bottom: 0;
      }

  }

}

// blocks
// --------------------------------------------
.block-text-hero {
  display: flex !important;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: color(member-zone, red);
  padding: 2em !important;

  &--bg-gray  {
      color: black;
      display: flex !important;
      justify-content: center;
      align-items: center;
      background-color: $color-blue1;
  }

  &--bg-red  {
    color: white;
    display: flex !important;
    justify-content: center;
    align-items: center;
    background-color: color(brand, primary);
}

  .typography {
      max-width: 500px;

      & > *:last-child {
          margin-bottom: 0;;
      }
  }

}

.block-bg-image {

  img {
      object-fit: cover;
      height: 100%;
      max-width: 100%;
  }

}

.block-page-banner {
  background-size: cover;
  height: 270px;
  position: relative;

  &__title {
      font-size: 70px;
      color: color(member-zone, red);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;

    @include mq(sm, 1) {
      font-size: 50px;
    }
  }

  &__bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 5;
      background-color: white;
      opacity: 0.5;
  }
}

.table-responsive {
  width: 100%;
  overflow-x:auto;
}

.block-logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    img {
        max-height: 125px;

        @include mq(sm) {
            max-height: 150px;
        }

        @include mq(md) {
            max-height: 175px;
        }

        @include mq(lg) {
            max-height: 200px;
        }
    }
}


