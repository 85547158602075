.zm-banner {
    @include keep-ratio("16/9");
    @include change-ratio("428/80", sm);
    box-shadow: 0 4px 30px rgba(0, 0, 0, .1);
}

.banner--16-9 {
    @include mq(md) {
        display: flex;
    }
}

.banner--16-9__media {
    @include keep-ratio("2/1");

    @include mq(md) {
        min-width: 50%;
    }

    @include mq(lg) {
        min-width: 60%;
    }
}

.banner--16-9__inner {
    display: flex;
    align-items: center;
    padding-top: $root-vgrid;
    padding-bottom: $root-vgrid;
    background-color: color(member-zone, grey-xlight);
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;

    @include mq(md) {
        padding: 2rem 1rem;
    }

    @include mq(lg) {
        padding: 3.5rem;
    }

    .typography *:last-child {
        margin-bottom: 0;
    }

}
