// ==========================================================================
// Banners
// ==========================================================================

// <section class="$banner-class">
//     <div class="$banner-class__cover"><img src="" alt=""></div>
//     <div class="$banner-class__inner"> ... </div>
// </header>

@mixin banner($banner-class: '.banner', $banner-height: 100vh, $banner-padding: 0, $banner-breakpoint: sm) {

    #{$banner-class} {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        //z-index: map-get($z-indexes, banner);

        @include mq($banner-breakpoint) {
            min-height: $banner-height;

            &::after {
                content: '';
                display: inline-block;
                min-height: $banner-height;
                visibility: hidden;
                z-index: -1;
            }
        }
    }

    #{$banner-class}__cover {
        @extend %img-cover;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        z-index: 0;

        img {
            transition: opacity $transition-fast map-get($easing, easeinsine);
            transition-delay: $transition-normal;
            z-index: 1;
        }

        &::before {
            z-index: 9;
        }
    }

    #{$banner-class}__inner {
        padding: $banner-padding $grid-gutter-width;
        position: relative;
        text-align: center;
        z-index: 10;

        @include mq($banner-breakpoint) {
            width: 100%;
            max-width: $grid-container-width;
        }
    }
}
