// ==========================================================================
// Helpers
// ==========================================================================

%clearfix {
    &::after {
        content: '';
        display: block;
        clear: both;
    }
}

%sr-only {
    display: none;
}

%bleed {
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
}

%img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
}


%img-cover {
    display: block;
    width: 100%;

    & > img,
    picture > img {
        font-family: "cover";
        object-fit: cover;
        display: block;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
    }

    // iframe {
    //     z-index: map-get($z-indexes, lazyloader) - 1;
    // }

    // For IE9, IE10 and IE11
    &.objectfit {
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        z-index: 5;

        img {
            opacity: 0;
            display: none;
        }
    }
}

%gr-icon-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &::before {
        //font-family: $gr-icon-font;
        line-height: inherit;
    }
}

%gr-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    visibility: hidden;
    overflow: auto;
    background-color: transparent;
}
