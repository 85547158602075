%h-background--xlight,
.h-background--xlight {
    background-color: color(greys, xlight);
}

.h-border {
    border: 1px solid color(greys, light);
}

.h-half-width {
    width: auto;
    max-height: 200px;

    @include mq(md) {
        width: 100%;
        max-width: 50%;
        max-height: none;
    }
}

.h-background--transparent {
    background-color: transparent !important;
}
