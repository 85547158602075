// Headers
// --------------------------------------------------------------------------

@include banner('.header--home', 820px, 6rem, md);
@include banner('.l-header', 220px, $root-vgrid);
@include banner('.banner--zm', 550px, 6rem, md);
@include banner('.banner--head-office', 780px, 6rem, md);
//@include banner('.l-header', 45vh, 3rem);
.banner--zm {
    @include mq(md, 1) {
        display: block;
    }
}

.banner--zm-large {
    @include mq(md) {
        min-height: 820px;
    }
}

.banner--zm--landing {
    @include mq(md) {
        min-height: 820px;
    }

    .header--home__inner .typography p {
        display: block;
    }

    .header--home__inner {
        padding-bottom: 0;
    }

    .header--home__inner-box {
        padding: 2rem;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
    }
}

.banner--zm .header--home__inner-align-left .header--home__inner-box {
    @include mq(md) {
        margin-left: 0;
    }
}

.banner--zm .header--home__inner-align-right .header--home__inner-box {
    @include mq(md) {
        margin-right: 0;
    }
}


.banner--head-office {
    @include mq(md, 1) {
        display: block;
    }
}

.banner--head-office .header--home__cover {
    display: none;

    @include mq(md) {
        display: block;
    }
}

.banner--head-office .header--home__inner-box {
    padding: 0 $grid-gutter-width / 2;

    @include mq(md) {
        margin-left: 0;
        padding: 3rem;
        max-width: 500px;
    }

    .svg {
        position: relative;
        top: -2.5px;
    }

    .svg path {
        fill: color(brand, primary);
    }

}

.banner--head-office .header--home__inner-box .h1 {
    font-size: 1.5rem;
    margin-bottom: $root-vgrid/2;

    @include mq(md) {
        font-size: 1.7em;
    }

}

.banner--head-office .header--home__inner {
    background-color: transparent;
}


.banner--head-office .header--home__inner .typography p {
    display: block;
}

.icon-user-circle {
    position: relative;
    top: -2px;
}

.header--home,
.header--top,
.l-header {
    color: $black;
}


.header--home .slick-slide {
    height: auto;
}

.l-header {
    align-items: flex-end;
    justify-content: flex-start;

    &__cover img {
        filter: grayscale(100%);
    }

    &__inner {
        text-align: left;
    }

    @include mq(sm) {
        justify-content: center;
    }
}

.header--home {
    @include mq(md) {
        height: 40rem;
    }
}

.header--home-full-width-image {
    @include mq(md, 1) {
        .header--home__cover {
            &::after {
                content: '';
                padding-top: 350px !important; // Sorry
            }
        }
    }
}

.header--home--inverted {
    .header--home__cover::before {
        right: 0;
        left: 50%;
        transform: skew(-15deg);
        transform-origin: bottom left;
    }

    .header--home__inner-box {
        margin-left: auto;
    }
}

.header--home__cover {
    @include mq(md, 1) {
        position: relative;
        height: auto;

        &::after {
            content: '';
            display: block;
            width: 100%;
            padding-top: calc(41 * 100 / 90 * 1%);
        }
    }
}

.header--home__inner {
    text-align: left;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
    word-break: keep-all;
    text-align: center;
    background-color: #ededed;
    @include mq(md) {
        background-color: transparent;
    }

    .typography {
        display: none;

        @include mq(md) {
            display: block;
        }
    }

    @include mq(md) {
        max-width: calc(100% - 8rem);
        text-align: left;
    }

    .typography [class^='btn--'] {
        margin-bottom: 0;
    }

    .btn--secondary:hover {
        @include btn-colors($root-color, transparent, $root-color);
    }
}

.header--home__inner.header--home__inner-column {
    padding-top: 6rem;

    [gr-grid*="row"] {
        flex-direction: column;
    }

    .header--home__inner-box + .header--home__inner-box {
        margin-top: $root-vgrid;
    }
}


.header--home__inner,
.header--top__inner {
    padding-top: 0;

    @include mq(md) {
        padding-top: 2rem;
    }

    // @include mq(md, 1) {
    //     padding-bottom: $root-vgrid / 2;
    // }

    @include mq(md, 1) {
        padding-bottom: 0;
    }
}

.header--home__inner-box {
    width: 100%;
    padding: 1.5rem 1.5rem 0;

    @include mq(sm) {
       padding: 3rem 3rem 1rem;
    }

    .h1 {
        font-size: 1.3em;
    }

    @include mq(sm) {
        padding: 2rem;
        .h1 {
            font-size: 1.5em;
        }
    }

    @include mq(md) {
        max-width: rem(410px);
        margin-left: rem(150px);
        margin-right: rem(150px);
        background-color: $color-blue1;
    }

    &--transparent {
        background-color: transparent;
    }
}

.header--home__inner-align-right {
    @include mq(md) {
        justify-content: flex-end;
    }
}

// Full Custom CSS for custom bloc on landing promo 2018

.banner--zm--fenix {
    background-color: color(brand, landingPageFenix);

    @include mq(lg, 1) {
        min-height: 0;
    }
}

.banner--zm--landing__custom-title {
    position: absolute;
    top: -1px; // Offset by -1px to make sure there is no space on top
    left: -1px;
    display: block;
    width: 100%;
    max-width: rem(200px);

    @include mq(sm) {
        max-width: rem(300px);
    }

    @include mq(md) {
        max-width: rem(500px);
    }
}

.header--home__inner-has-custom-title {
    background-color: transparent;

    @include mq(md, 1) {
        padding-top: rem(120px);
        .header--home__inner-box {
            margin-left: 0;
            text-align: left;
            padding-bottom: $root-vgrid * 2;
            padding-left: $root-vgrid * 2;
        }
    }

    @include mq(sm, 1) {
        padding-top: rem(60px);
        .header--home__inner-box {
            padding-bottom: $root-vgrid;
            padding-left: $root-vgrid;
        }
    }
}
