// ==========================================================================
// Variables
// ==========================================================================


// Layout: Grid breakpoints & parameters
// --------------------------------------------------------------------------

$grid-breakpoints: (
    //root: 0,
    xxs:                           320px,
    xs:                            375px,
    sm:                            768px,
    md:                            1024px,
    lg:                            1280px,
    xl:                            1660px
);

$grid-columns:                     12;
$grid-gutter-width:                30px;
$grid-container-width:             1840px;


// Fonts
// --------------------------------------------------------------------------

$font-path:                        '../fonts/' !default;

$fonts: (
    googlefont: (
        headings: 'Montserrat' //'Playfair Display'
    )
);

$font-primary: 'CircularStd' !default;

$font-primary: $font-primary, 'Arial', sans-serif;
$font-headings: $font-primary, 'Arial', sans-serif;

@include font-face('CircularStd', #{$font-path}CircularStd-Book, normal);
@include font-face('CircularStd', #{$font-path}CircularStd-Bold, bold);


// Colors (variables & $colors map)
// --------------------------------------------------------------------------

$color-globalia:                   #1b9cd7;
$color-blue1 : #CBD8E4;

$colors: (
    root: (
        black:                     #000,
        white:                     #fff
    ),
    brand: (
        primary:                   #9d162e,
        secondary:                 #000,
        primaryLight:              #ecebe7,
        landingPageFenix:          #231f20
    ),
    greys: (
        xdark:                     #191919,
        dark2:                     #4F5457,
        dark:                      #4c4c4c,
        base:                      #8e897b,
        light:                     #d9d9d9,
        xlight:                    #f0efec
    ),
    validation: (
        error:                     #f00,
        warning:                   #feef6d,
        success:                   #40b01a
    ),
    member-zone: (
        grey-xdark:                #4c4c4c,
        grey-dark:                 #7f7f7f,
        grey-base:                 #b2b2b2,
        grey-light:                #e5e5e5,
        grey-xlight:               #f2f2f2,
        red:                       #9d162e
    )
) !default;


// Globals
// --------------------------------------------------------------------------

$root-color:                       color(greys, xdark) !default;
$root-cursor:                      default !default;

$root-font-family:                 $font-primary !default;
$root-font-size:                   16px !default;
$root-line-height:                 1.5 !default;

$root-text-rendering:              optimizeLegibility !default;

$root-margin:                      em(30px) !default;
$root-padding:                     em(30px) !default;

$root-border:                      1px solid currentColor !default;
$root-radius:                      0 !default;

//  Typography
// --------------------------------------------------------------------------

$typo-heading-max:                 em(28px) !default; // h1 font-size as reference to calculate the modular-scale and typographic headings font-size

$typo-headings-fonts: (
    h1 :  $font-headings,
    h2 :  $font-headings,
    h3 :  $font-headings,
    h4 :  $font-headings,
    h5 :  $font-headings
) !default;

$typo-headings-colors: (
    h1 :  $root-color,
    h2 :  $root-color,
    h3 :  $root-color,
    h4 :  $root-color,
    h5 :  $root-color
) !default;

$typo-link-color:                  $root-color;
$typo-link-decoration:             underline;
$typo-link-hover-color:            $typo-link-color !default;
$typo-link-hover-decoration:       $typo-link-decoration !default;

$typo-list-bullet: $icon-li-dot; // could be any variable from your iconfont if defined
$typo-list-bullet-color: color(brand, primary);


//  Form
// --------------------------------------------------------------------------

$form-class:                       '.gr-form' !default;
$form-element-height:              em(46px) !default;
$form-element-padding:             (.5 * $root-padding) !default;
$form-element-margin:              0;
$form-element-radius:              0;
$form-element-border-style:        solid;
$form-element-border-width:        1px;
$form-element-border-color:        color(greys, light);
$form-element-background-color:    white;
$form-element-focus-color:         inherit;


//  Table
// --------------------------------------------------------------------------

$table-header-bg-color:              color(greys, dark) !default;
$table-header-font-weight:           700 !default;
$table-header-font-color:            color(root, white) !default;
$table-header-font-color-mobile:     $root-color !default;
$table-header-font-uppercase:        uppercase !default;

$table-cell-padding:                 $root-padding !default;
$table-condensed-cell-padding:       $table-cell-padding / 3 $table-cell-padding / 2;

$table-bg:                          color(root, white) !default;
$table-bg-accent:                   color(greys, xlight) !default;
$table-bg-hover:                    color(greys, xlight) !default;
$table-bg-active:                   $table-bg-hover !default;
$table-border-color:                color(greys, dark) !default;


//  Misc.
// --------------------------------------------------------------------------

$media-element-vertical-align:     middle !default;


//  Transitions
// --------------------------------------------------------------------------

$transition-fast:                  240ms !default;
$transition-normal:                480ms !default;
$transition-slow:                  720ms !default;


//  Components
// --------------------------------------------------------------------------

//
// z-index have a range of 10 between each other where value is decremented
// the value represent the highest z-index for the component
//
// Usage
// z-index: map-get($z-indexes, overlay);
// z-index: (map-get($z-indexes, overlay) - 1);
//

$z-indexes: (
    overlay:                       50,
    nav:                           60,
    modal:                         70
) !default;


//  Navigation
// --------------------------------------------------------------------------

$nav-options: (
    height:                em(60px),
    total-height:          em(120px),
    mobile-height:         em(52px),
    mobile-width:          70%,
    breakpoint:            md,
    icon-color:            color(root, black),
);


// Lazy-loader
// --------------------------------------------------------------------------

$lazy-loader-background-color:     transparent;
$overlay-bg-color:                 rgba(0, 0, 0, .75) !default;


// Modal
// --------------------------------------------------------------------------

$modal-bg-color:                 color(root, white) !default;
$modal-width:                    em(600px) !default;
