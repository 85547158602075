.timeline {
    [gr-grid~=row--block] {

        [gr-grid~=block] {
            position: relative;
            padding-left: $grid-gutter-width*1.5;

            .title--arrow {
                margin-top: .3rem;
            }

            &::before {
                position: absolute;
                content: '';
                height: 14px;
                width: 14px;
                border: 3px solid $black;
                border-radius: 50%;
                background-color: $white;
                top: 7px;
                left: $grid-gutter-width/2;
            }

            &::after {
                position: absolute;
                content: '';
                width: 3px;
                background-color: $black;
                top: 21px;
                bottom: -7px;
                left: calc(#{$grid-gutter-width/2} + 10px * .5);
            }
        }

        @include mq(sm) {
            flex-direction: row;
            align-items: center;

            [gr-grid~=block] {
                margin-left: calc(50% + #{$root-hgrid});
                margin-right: 0;

                &::before {
                    left: calc(-#{$root-hgrid} - 7px);
                }

                &::after {
                    left: calc(-#{$root-hgrid} - 3px * .5);
                }
            }

            &:nth-child(odd) {
                [gr-grid~=block] {
                    margin-left: 0;
                    margin-right: calc(50% + #{$root-hgrid});
                    text-align: right;


                    &::before {
                        left: auto;
                        right: calc(-#{$root-hgrid} - 7px);
                    }

                    &::after {
                        left: auto;
                        right: calc(-#{$root-hgrid} - 3px * .5);
                    }

                    .timeline__entry {
                        flex-direction: row-reverse;
                    }
                }
            }
        }

        &:last-child {
            [gr-grid~=block]::after {
                display: none;
            }
        }
    }

    &__entry {
        margin-top: $root-vgrid / 2;
    }

    &__picture {
        @include keep-ratio('16/9');
    }
}
