@import 'grid-rythm';
@import 'root-typography';

// Globals
// --------------------------------------------------------------------------

:root {
    @extend %root-typography;
}

// Media
// --------------------------------------------------------------------------

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: if(variable-exists(media-element-vertical-align), $media-element-vertical-align, middle);
}

[gr-handler] .svg {
    visibility: hidden;
    opacity: 0;
    transition: opacity $transition-fast map-get($easing, easeinsine);

    &[gr-svg-inliner~=done] {
        visibility: visible;
        opacity: 1;
    }
}


img {
    display: block;
    position: relative;

    &::before,
    &::after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    &::before {
        content: '';
        //background-color: color(validation, error);
        z-index: 0;
        background-image:
            linear-gradient(#DEDFE3 2%, #AEB3B9 95%),
            radial-gradient(33% 100%, rgba(255,255,255,0.50) 0%, rgba(0,0,0,0.50) 100%);
        background-blend-mode: screen;
    }

    &::after {
        content: '';
        background: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 161.266 161.266"><g fill="#000"><path d="M80.635 161.26C36.172 161.26 0 125.092 0 80.637 0 36.177 36.172.007 80.635.007c44.46 0 80.63 36.17 80.63 80.63 0 44.455-36.17 80.623-80.63 80.623zm0-153.65c-40.27 0-73.03 32.76-73.03 73.027 0 40.264 32.76 73.02 73.03 73.02 40.267 0 73.028-32.757 73.028-73.02 0-40.268-32.76-73.028-73.028-73.028z"/><path d="M59.94 60.78c0 3.18-2.587 5.763-5.77 5.763-3.18 0-5.758-2.584-5.758-5.762 0-3.176 2.577-5.753 5.758-5.753 3.183 0 5.77 2.577 5.77 5.754zM114.357 60.78c0 3.18-2.584 5.763-5.762 5.763-3.178 0-5.76-2.584-5.76-5.762 0-3.176 2.582-5.753 5.76-5.753 3.177 0 5.762 2.577 5.762 5.754zM123.026 129.13c-9.564-11.746-25.33-18.763-42.176-18.763-17.394 0-33.31 6.998-42.57 18.723l-5.97-4.715c10.693-13.533 28.837-21.61 48.54-21.61 19.11 0 37.078 8.063 48.07 21.57l-5.894 4.796z"/></g></svg>') center center no-repeat;
        background-size: 24px;
    }
}

// ==========================================================================
// groots'Core Configuration
// ==========================================================================

// Colors mapping (for display in GUI)
// --------------------------------------------------------------------------

// @each $label-map, $color-map in $colors {
//     @each $label, $color in $color-map {
//         .bg-#{$label-map}-#{$label} {
//             background-color: $color;

//             .gui-main &::after {
//                 content: '#{$color}';
//             }
//         }
//     }
// }

%gr-overlay {
    transition: background-color $transition-fast map-get($easing, easeinsine);
    background-color: $overlay-bg-color;
    -webkit-overflow-scrolling: touch;

    &[gr-overlay*='visible'] {
        visibility: visible;
        z-index: map-get($z-indexes, overlay);
    }
}
