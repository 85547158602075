.zm-card-document {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: $grid-gutter-width/1.5;
    padding-right: $grid-gutter-width*1.5;
    box-shadow: 0 4px 30px rgba(0, 0, 0, .1);
    background-color: white;
    border-radius: 5px;
    color: color(member-zone, grey-dark);

    margin-bottom: $root-vgrid;

    @include mq(sm) {
        border-radius: 0;
        margin-bottom: 0;
        height: 100%;
        min-height: 285px;

        padding: $grid-gutter-width;
        padding-right: $grid-gutter-width*1.5;

    }

    &[data-href]:not(:empty) {
        cursor: pointer;
    }
}

.zm-card-document.is-active {
    .zm-card-document__options-menu {
        display: block;
    }

    .zm-card-document__options-trigger svg path,
    .zm-card-document__options-trigger svg use {
        fill: color(member-zone, red);
    }
}

.zm-card__thumbnail {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    img {
        width: 100%;
        height: auto;
        max-width: 90%;
    }
}

.zm-card-document__options {
    position: absolute;
    z-index: 11;
    top: 0;
    right: 0;
    cursor: pointer;
    color: color(member-zone, grey-dark);

    .zm-card-document__options-trigger {
        padding: $grid-gutter-width/1.5;
    }
}

.zm-card-document__options-menu {
    display: none;
    list-style: none;
    background-color: white;
    position: absolute;
    z-index: 2;
    top: $grid-gutter-width/2;
    right: #{$grid-gutter-width*1.2};
    white-space: nowrap;
    font-size: rem(14px);
    padding: 1rem;
    box-shadow: 0 4px 30px rgba(0, 0, 0, .1);

    a {
        color: currentColor;
    }

    li + li {
        margin-top: $root-vgrid/4;
    }
}

.zm-card-document__icon-container {
    margin-top: 118px;
    display: flex;
    align-items: center;
    justify-content: center;

    .zm-card-document__icon {
        margin-bottom: 0;
    }
}

.zm-card-document__icon-bundle {
    display: flex;
    align-items: center;
    margin-bottom: $root-vgrid;

    @include mq(sm) {
        margin-bottom: 0;
    }
}

.zm-card-document__icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: em(60px);
    height: em(60px);
    color: color(brand, primary);
    border: 1px solid color(brand, primary);
    border-radius: 50%;

    .svg path {
        fill: color(brand, primary);
    }
}

.zm-card-document__numbers {
    padding-left: .5rem;
    font-size: rem(18px);
    color: transparentize(black, .5);
}

.zm-card-document__body {
    position: relative;
}

.zm-card-document__title {
    @include mq(sm) {
        font-size: rem(24px);
        line-height: 1.15;
    }
}

.zm-card-document__file-size {
    color: transparentize(black, .7);
}

.zm-card-document__nb-file {
    color: transparentize(white, .5);
}

.zm-card-document--image,
.zm-card-document--video {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: white;
    min-height: 240px;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, .9) -.01%, rgba(0, 0, 0, 0) 58.01%), transparent;
    }

    .zm-card-document__title {
        @include mq(sm) {
            font-size: rem(16px);
        }
    }

    .zm-card-document__options-trigger svg path,
    .zm-card-document__options-trigger svg use {
        fill: white;
    }
}

.zm-card-document--image {
    .zm-card-document__options-trigger svg path,
    .zm-card-document__options-trigger svg use {
        fill: color(brand, primary);
    }

}

.zm-card-document--image,
.zm-card-document--video {
    cursor: pointer;
}

.zm-card-document--video::after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    width: 65px;
    height: 65px;
    border: 3px solid white;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    background-image: url('/dist/img/svg/play.svg');
    background-position: calc(50% + 3px) 50%;
    background-repeat: no-repeat;
}

.zm-card-document__header {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    min-height: 118px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: $grid-gutter-width;
    color: white;
    background-color: rgba(0, 0, 0, .9);
    z-index: 10;
}

.zm-card-document__background {
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    > div {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
        max-width: 50%;
    }
}

.zm-card-document__image {
    display: flex;

    > div {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
        min-width: 50%;
        @extend %img-cover;
    }
}
