.gr-dropdown {
    position: relative;
    display: inline-block;
    color: inherit;

    &:hover {
        .gr-dropdown__target {
            height: auto;
            z-index: 50;
        }
    }
}

.gr-dropdown__trigger {
    @extend %btn;
    height: auto;
    margin-bottom: 0;
    border-bottom: 0;
    display: inline-block;
    color: inherit;

    .icon-download {
        display: inline-block;
    }

    .icon-download::before {
        color: inherit;
        margin-right: .75em;
    }

    .data-downloadable--booklet & {
        @include btn-colors($white, $root-color, $root-color);
    }
}

.gr-dropdown__target {
    height: 2px;
    border: 2px solid black;
    border-top: 0;
    font-weight: 700;
    will-change: height;
    overflow: hidden;
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: 49;
    margin-top: -1px;
    text-align: left;
    background-color: white;
    border-radius: 0;
    transition: height 240ms ease-in-out, padding 240ms ease-in-out;

    li {
        list-style: none;
        padding: $root-padding * .25 $root-padding * .5;
        //text-transform: uppercase;
        font-size: em(14px);

        a {
            color: inherit;
        }
    }

    .data-downloadable--booklet & {
        color: $white;
        background-color: $root-color;
    }
}
