.table-jobs {
  margin-bottom: 50px;

  @include mq(sm, 1) {
    display: none;
  }

  .gr-select {
    text-align: left;
  }

  th,
  td {
    padding: 20px 40px;
    text-align: left;

    @include mq(md, 1) {
      padding: 15px 20px;
    }
  }

  thead {
    tr {
      background-color: $color-blue1;
    }

    th,
    td {
      font-size: 20px;

      &:last-child {
        text-align: right;
      }
    }
  }

  tbody {
    td {
      font-size: 16px;

      &:last-child {
        text-align: right;
      }
    }
  }
}

.jobs-list-anchor {
  margin-top: -100px;
  margin-bottom: 180px;

  @include mq(sm, 1) {
    margin-top: -60px;
    margin-bottom: 80px;
  }
}

.jobs-list-mobile {
  display: none;
  margin-bottom: 30px;

  @include mq(sm, 1) {
    display: block;
  }

  > ul {
    margin-top: 30px;
    list-style: none;

    li {
      margin-bottom: 18px;
      padding-bottom: 18px;
      border-bottom: 1px solid #b2b2b2;
    }

    p:first-child {
      margin-bottom: 5px;
    }

    a {
      color: #191919;
      text-decoration: none;
    }

    strong {
      color: #9d162e;
      text-decoration: underline;
      text-underline-offset: 5px;
    }

    em {
      font-style: normal;
      color: #808080;
    }
  }
}
