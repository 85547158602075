.zm-nav {
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    width: 145px;
    height: 100%;
    background: color(greys, xlight);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: $root-vgrid;
    transform: translateX(-100%);
    transition: all $transition-fast ease-in-out;

    @include mq(md) {
        transform: none;
        justify-content: space-between;
    }

    @include mq(lg) {
        width: 210px;
    }

    svg {
        max-width: 20px;
    }

    .icon_dashboard {
        width: 23px;
        height: 17px;
    }

    .icon_listePrix {
        width: 17px;
        height: 24px;
    }

    .icon_delais {
        width: 19px;
        height: 24px;
    }

    .icon_memos {
        width: 23px;
        height: 20px;
    }

    .icon_documentation {
        width: 23px;
        height: 22px;
    }

    .icon_marketing {
        width: 22px;
        height: 23px;
    }

    .icon_famille {
        width: 23px;
        height: 19px;
    }
}

.zm-nav__logo {
    display: none;

    @include mq(md) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100px;

        img {
            width: 100%;
            height: 40px;
        }
    }
}

.zm-nav__element {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
    text-align: center;
    font-size: rem(14px);
    color: transparentize(color(greys, dark), .5);
    transition: all $transition-fast ease-in-out;

    .svg + div {
        margin-top: $root-vgrid/6;
    }

    &.is-active {
        position: relative;
        color: color(member-zone, grey-dark);

        &::after {
            content: "";
            width: 2px;
            height: 100%;
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            background-color: color(member-zone, red);
        }

        svg path {
            fill: color(member-zone, red);
        }

        @include mq(md) {
            background-color: rgba(0, 0, 0, .03);
        }
    }

    @include mq(md) {
        &:hover {
            color: color(member-zone, grey-dark);
            @include mq(md) {
                background-color: rgba(0, 0, 0, .03);
            }
        }
    }


    img {
        display: inline-block;
        margin-bottom: $root-vgrid/4;
    }
}

.zm-nav__memo {
    width: 23px;
    position: relative;
    margin-left: auto;
    margin-right: auto;

    img {
        width: 23px;
    }

    &::before {
        content: attr(data-length);
        color: white;
        background-color: color(member-zone, red);
        padding: 0 .5rem;
        border-radius: 50%;
        font-size: rem(12px);
        position: absolute;
        z-index: 1;
        right: 8px;
        top: 0;
        transform: translate(100%, -50%);
    }

    &::before:empty {
        display: none;
    }
}
