// --------------------------------------------------------------------------
// Helpers: Text
// --------------------------------------------------------------------------
%h-text-weight--normal,
.h-text-weight--normal {
    font-weight: 400;
}

%h-text-weight--book,
.h-text-weight--book {
    font-weight: 500;
}

%h-text-weight--bold,
.h-text-weight--bold {
    font-weight: 600;
}

%h-text-weight--heavy,
.h-text-weight--heavy {
    font-weight: 700;
}

%h-text-case--upper,
.h-text-case--upper {
    text-transform: uppercase;
}

%h-text-color--primary,
.h-text-color--primary {
    color: color(brand, primary);
}

%h-text-color--secondary,
.h-text-color--secondary {
    color: color(brand, secondary);
}

%h-text-color--greys-xlight,
.h-text-color--greys-xlight {
    color: color(greys, xlight);
}

.h-text-color--greys-light-zm {
    color: rgba(0, 0, 0, .3);
}


.h-text-color--greys-base {
    color: color(greys, base);
}

.h-text-color--greys-zm {
    color: rgba(0, 0, 0, .3);
}


%h-text-color--white,
.h-text-color--white {
    color: color(root, white);
}

%h-text-color--black,
.h-text-color--black {
    color: color(root, black);
}

%h-text-size--small,
.h-text-size--small {
    font-size: rem(14px);
    // letter-spacing: .5px;
}

%h-text-align--right,
.h-text-align--right {
    text-align: right;
}


%h-text-align--center,
.h-text-align--center {
    text-align: center;
    display: block;
    width: 100%;
}

.h-text-link {
    color: color('greys', 'xdark');
    border-bottom: 1px solid color('greys', 'xdark');
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

.h-display-only-desktop {
    display: none;
    @include mq(sm) {
        display: block;
    }
}
